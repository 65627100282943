import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthContext from '../shared/context/AuthContext';
import LoginPage from '../pages/Login/LoginPage';
import HomePage from '../pages/Home/HomePage';
import MyProfilePage from '../pages/Profile/MyProfile';
import CoursePage from '../pages/Parceiros/CursosImobiliarios/CoursePage';
import FinancialPage from '../pages/Parceiros/FinancialImob/FinancialPage';
import ManageSoftwarePage from '../pages/Parceiros/GestaoImobiliaria/ManageSoftwarePage';
import LeadsPage from '../pages/Parceiros/Leads/LeadsPage';
import LegalSupportPage from '../pages/Parceiros/Juridico/LegalSupportPage';
import MarketingPage from '../pages/Parceiros/Marketing/MarketingPage';
import IMPage from '../pages/Parceiros/IM/IMPage';
import PortalsPage from '../pages/Parceiros/Portais/PortalsPage';
import SignalingPage from '../pages/Parceiros/Sinalizacao/SignalingPage';
import VirtualPage from '../pages/Parceiros/DecoracaoVirtual/VirtualPage';
import ListingsPage from '../pages/Listings/ListingsPage';
import PrelistingsPage from '../pages/PreListings/PrelistingsPage';
import CoursesPage from '../pages/EAD/Course/CoursesPage';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../pages/Dashboard/Dashboard';
import HomeTrainingPage from '../pages/EAD/Home/HomeTrainingPage';
import AgentPage from '../pages/EAD/Agent/AgentePage';
import LeadershipPage from '../pages/EAD/Leadership/LeadershipPage';
import SystemPage from '../pages/EAD/System/SystemPage';
import LessonPage from '../pages/EAD/Lesson/LessonPage';
import CreateLessonPage from '../pages/EAD/Lesson/CreateLessonPage';
import LessonsPage from '../pages/EAD/Lesson/LessonsPage';
import CreateCoursePage from '../pages/EAD/Course/CreateCoursePasge';
import AdminPage from '../pages/Admin/AdminPage';
import EditMCPage from '../pages/Admin/MarketCenters/EditMcPage';
import MarketCenterPage from '../pages/Admin/MarketCenters/MarketCenterPage';
import TeamsPage from '../pages/Admin/Teams/TeamsPage';
import UserManagementPage from '../pages/Admin/Users/UserManagementPage';
import LessonPageTemp from '../pages/EAD/Lesson/LessonPageTemp';
import CollectionsPage from '../pages/EAD/Collections/CollectionsPage';
import { CreateNewCourse } from '../pages/EAD/Course/components/CreateNewCourse';
import TransmittalPage from '../pages/Transmittal/TransmittalPage';
import NewTransmittalPage from '../pages/Transmittal/NewTransmittalPage'
import EditTransmittalPage from '../pages/Transmittal/EditTransmittalPage';
import IntegracaoPortaisPage from '../pages/Navent/IntegracaoPortaisPage';


const AppRoutes = () => {
  const { user,
    jwtUser,
    jwtToken,
    authLoading,
    authError,
    setPrevUser,
    setPrevJwtUser,
    setPrevJwtToken,
    signIn,
    signOut, } = useContext(AuthContext)



  if (!user &&
    !jwtUser &&
    !jwtToken &&
    !authLoading &&
    !authError &&
    localStorage.length > 0
  ) {
    console.log("Executes")
    const expirationDate = new Date(parseInt(localStorage.getItem("expiration") || '0'));
    if (expirationDate.getTime() > Date.now()) {
      if (localStorage.getItem("user") !== null)
        setPrevUser(JSON.parse(localStorage.getItem("user") || ''));
      if (localStorage.getItem("jwtUser") !== null)
        setPrevJwtUser(JSON.parse(localStorage.getItem("jwtUser") || ''));
      if (localStorage.getItem("token") !== null)
        setPrevJwtToken(localStorage.getItem("token") || '');
    }

  }

  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route path='*' element={<LoginPage />} />
      {
        jwtToken &&
        <>
          <Route element={<PrivateRoute />}>
          </Route>
          <Route path='*' element={<HomePage />} />
          <Route path='/' element={<Navigate to={'/soukw/home/training'} />} />
          {/* ===== SOUKW ROUTERS ===== */}

          <Route path='/soukw' element={<><HomePage /></>} />
          <Route path='/soukw/home' element={<><HomePage /></>} />
          <Route path='/soukw/portals' element={<><HomePage /></>} />
          <Route path='/soukw/admin' element={<><AdminPage /></>} />

          <Route path='/soukw/admin/market-centers' element={<MarketCenterPage />} />
          <Route path='/soukw/admin/teams' element={<TeamsPage />} />
          <Route path='/soukw/admin/users' element={<UserManagementPage />} />

          <Route path='/soukw/admin/edit-mc' element={<><EditMCPage /></>} />
          <Route path='/soukw/myprofile' element={<><MyProfilePage /></>} />
          <Route path='/soukw/listings' element={<><ListingsPage /></>} />
          <Route path='/soukw/prelistings' element={<><PrelistingsPage /></>} />

          <Route path='/soukw/transmittal' element={<><TransmittalPage /></>} />
          <Route path='/soukw/create-transmittal' element={<><NewTransmittalPage /></>} />
          <Route path='/soukw/edit-transmittal/:id?' element={<><EditTransmittalPage /></>} />
          {/* ===== NAVENT XML ROUTERS ===== */}
          <Route path='/soukw/navent' element={<IntegracaoPortaisPage />} />

          {/* ===== TRAINING ROUTERS ===== */}
          <Route path='/soukw/home/training' element={<><HomeTrainingPage /></>} />
          {/* <Route path='/soukw/training/system' element={<SystemPage />} /> */}
          {/* <Route path='/soukw/training/agent' element={<AgentPage />} /> */}
          {/* <Route path='/soukw/training/leadership' element={<LeadershipPage />} /> */}
          <Route path='/soukw/training/collections' element={<><CollectionsPage /></>} />
          <Route path='/soukw/training/classes' element={<LessonPage />} />

          {/* Trilha/:id */}
          <Route path='/soukw/training/create-training/:id?' element={<CreateLessonPage />} />
          <Route path='/soukw/training/create-rail/:id?' element={<CreateLessonPage />} />
          <Route path='/soukw/training/create-course/:id?' element={<CreateLessonPage />} />
          <Route path='/soukw/training/edit-courses' element={<LessonsPage />} />
          <Route path='/soukw/training/edit-trainings' element={<LessonsPage />} />
          <Route path='/soukw/training/edit-rails' element={<LessonsPage />} />
          <Route path='/soukw/training/system/:id?' element={<LessonsPage />} />
          <Route path='/soukw/training/agent/:id?' element={<LessonsPage />} />
          <Route path='/soukw/training/leadership/:id?' element={<LessonsPage />} />
          <Route path='/soukw/training/classes/:id' element={<LessonPage />} />
          <Route path='/soukw/training/classes-temp/:id' element={<LessonPageTemp />} />
          <Route path='/soukw/training/courses/:id' element={<CoursesPage />} />
          <Route path='/soukw/training/lesson/:id' element={<LessonPage />} />

          {/* Dashboards routes */}
          <Route path='/soukw/dashboards' element={<><Dashboard /></>} />

          <Route path='/command' element={<><HomePage /></>} />
          <Route path='/pipeimob' element={<><HomePage /></>} />

          {/* ===== PARTNER ROUTES ===== */}
          <Route path='/parcerias' element={<><HomePage /></>} />
          <Route path='/parcerias/course' element={<><CoursePage /></>} />
          <Route path='/parcerias/financial' element={<><FinancialPage /></>} />
          <Route path='/parcerias/managesoftware' element={<><ManageSoftwarePage /></>} />
          <Route path='/parcerias/leadsintegration' element={<><LeadsPage /></>} />
          <Route path='/parcerias/legalsupport' element={<><LegalSupportPage /></>} />
          <Route path='/parcerias/marketing' element={<><MarketingPage /></>} />
          <Route path='/parcerias/im' element={<><IMPage /></>} />
          <Route path='/parcerias/portals' element={<><PortalsPage /></>} />
          <Route path='/parcerias/signaling' element={<><SignalingPage /></>} />
          <Route path='/parcerias/virtualdecorator' element={<><VirtualPage /></>} />
        </>}
    </Routes>
  )
}

export default AppRoutes;