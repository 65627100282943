import { Col } from "antd";
import { ButtonDash, ContainerDash, ContentDash, Logo, TitleDash } from "./style";
import React from "react";

interface DashCardsProps {
    title: string;
    count: string;
    button: string;
}
const DashCards: React.FC<DashCardsProps> = (props) => {
    return (
        <>
            <ContainerDash>
                <ContentDash>
                    <TitleDash>
                        {props.title}
                    </TitleDash>

                    <TitleDash>
                        {props.count}
                    </TitleDash>

                    <ButtonDash>
                        {props.button}
                    </ButtonDash>
                </ContentDash>
            </ContainerDash>

        </>
    )
}

export default DashCards;