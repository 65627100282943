import Gridv2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { CardProfile, Column, ContentProfile, EditProfile, HeaderProfile, ImageProfile, ParagraphProfile, PersonalData, Row } from './style';
import { Button, Stack } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

interface ProfileProps {
    photo: string;
    fullName: string;
    email: string;
    permissionLevel: string;
    mc: string;
    kwuid: string;
    permissionLevelTreinamentos: string;
    name: string;
    team: string;
}

const GridProfile: React.FC<ProfileProps> = (props) => {
    return (
        <>
            <Gridv2
                container
                xs={10}
                md={10}
                lg={10}
                xl={10}
                style={{ height: '100%', textAlign: 'center' }}
                justifyContent='center'
            >
                <CardProfile>
                    <Stack spacing={{ md: -8, lg: -8 }}>
                        <div>
                            <img style={{ width: '100%', borderRadius: '6px 6px 0px 0px' }} src="https://storage.googleapis.com/kwconnect-core-prod-b6a0/themes/kwc/images/profile-banner.jpg" />
                        </div>
                        <HeaderProfile>
                            <ImageProfile src={props.photo?.length > 0 ? props.photo : 'https://images-front-soukw.s3.amazonaws.com/placeholder.png'} />
                            <div style={{ width: 40 }} />
                            <h2 style={{ marginTop: 75 }}>{props.fullName}</h2>
                            <EditProfile>
                                Editar
                                <div style={{ width: 8 }} />
                                <ModeEditIcon />
                            </EditProfile>
                        </HeaderProfile>
                    </Stack>
                    <ContentProfile>
                        <Row>
                            <Column>
                                <ParagraphProfile>Email: </ParagraphProfile>
                                <PersonalData>{props.email}</PersonalData>

                                <ParagraphProfile>Função:</ParagraphProfile>
                                <PersonalData>{props.permissionLevel}</PersonalData>

                                <ParagraphProfile>Market Center:</ParagraphProfile>
                                <PersonalData>{props.mc}</PersonalData>
                            </Column>
                            <div style={{ width: 250 }} />
                            <Column>
                                <ParagraphProfile>KWUID: </ParagraphProfile>
                                <PersonalData>{props.kwuid}</PersonalData>

                                <ParagraphProfile>Permissão de Treinamento:</ParagraphProfile>
                                <PersonalData>{props.permissionLevelTreinamentos}</PersonalData>

                                <ParagraphProfile>Time:</ParagraphProfile>
                                <PersonalData>{props.team}</PersonalData>
                            </Column>

                        </Row>
                    </ContentProfile>
                </CardProfile>
            </Gridv2>
        </>
    )
}

export default GridProfile;