import axios from "axios";
import { LISTINGS_ENDPOINT, LISTINGS_LEGADOS_ENDPOINT } from "./Constants";
import { axiosConfig } from "./helper";
import {Listing} from '../../../pages/Navent/models/ListingsInterfaces';

const getListingEndPoint = `${LISTINGS_ENDPOINT}/getMyListings`;
const xmlSizeEndPoint = `${LISTINGS_ENDPOINT}/getXMLSize`;
export default class ListingsService {

    static async getListings() {
        const response = await axios.get(getListingEndPoint, axiosConfig( ));
        return response.data;
    }

    static async getListing(id: number) {
        const response = await axios.get(`${LISTINGS_ENDPOINT}/${id}`);
        return response.data;
    }
    
    static async createListing(listing: any) {
        const response = await axios.post(LISTINGS_ENDPOINT, listing);
        return response.data;
    }
    
    static async updateListing(id: number, listing: any) {
        const response = await axios.patch(`${LISTINGS_ENDPOINT}/${id}`, listing, );
        return response.data;
    }

    static async deleteListing(id: number) {
        const response = await axios.delete(`${LISTINGS_ENDPOINT}/${id}`);
        return response.data;
    }

    static async getTotalListings() {
        const response = await axios.get(`${getListingEndPoint}/?page=1`, axiosConfig( ));
        return response.data.totalItems
    }

    static async getTotalXML() {
        const response = await axios.get(`${xmlSizeEndPoint}`, axiosConfig( ));
        return response.data
    }

    static async getListingLegados( ) {
        const response = await axios.get(`${LISTINGS_LEGADOS_ENDPOINT}?page=1`, axiosConfig( ));
        return response.data;
    }
}
