import axios from "axios";
import { TEAMS_ENDPOINT } from "./Constants";
import { axiosConfig } from "./helper";
export default class TeamsService {

    static async getTeams() {
        const response = await axios.get(TEAMS_ENDPOINT);
        return response.data;
    }

    static async getTeam(id: number) {
        const response = await axios.get(`${TEAMS_ENDPOINT}/${id}`);
        return response.data;
    }

    static async createTeam(team: any) {
        const response = await axios.post(TEAMS_ENDPOINT, team);
        return response.data;
    }

    static async updateTeam(id: number, team: any) {
        const response = await axios.patch(`${TEAMS_ENDPOINT}/${id}`, team);
        return response.data;
    }

    static async deleteTeam(id: number) {
        const response = await axios.delete(`${TEAMS_ENDPOINT}/${id}`);
        return response.data;
    }

    static async getTotalTeams() {
        const response = await axios.get(TEAMS_ENDPOINT);
        console.log(response.data.length);
        return response.data.length;
    }
}
