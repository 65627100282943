import Dexie, { Table, Collection } from 'dexie';
import { Listing, IIntegracaoPortaisDocument } from './ListingsInterfaces';

class ListingsDB extends Dexie {
    listings!: Table<Listing>; // Table type with Listing interface

    constructor() {
        super('ListingsDB');
        this.version(1).stores({
            listings: '++id, _id, mls_number, price, location, bedrooms, bathrooms, status, selected, integracaoNaventAgente, integracaoNaventMC, integracaoNaventTime' // Define indexed fields
        });
    }

    
    async selectList( id: number ) {
        await this.listings.update( id, {inXML: true})
    }

    async unselectList( id: number ) {
        await this.listings.update( id, {inXML: false})
    }

    async selectFilter( query: Collection ) {
        await query.modify( { inXML: true })
    }

    async unselectFilter( query: Collection ) {
        await query.modify( { inXML: false })
    }

    async editAdd( planData: IIntegracaoPortaisDocument, query: Collection) {
        await query.filter( li => li.inXML === true ).modify( {integracaoNaventAgente: planData} )
    }

    async sendSelectedData( ) {
        const listingsInAdd = await db.listings.where( { inXML: true} ).toArray( );
    }

}

// Instantiate the database
const db = new ListingsDB();
export default db;