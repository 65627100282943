import { Divider, Grid } from "@mui/material";
import GridTeams from "../../shared/components/Grids/GridTeams";
import { ButtonMC, ButtonTeam, ButtonUser, Column, Row, Title } from "./style";
import GridMC from "../../shared/components/Grids/GridMC";
import GridUsers from "../../shared/components/Grids/GridUsers";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { Navbar, Sidebar } from "../../shared/components";
import { token } from "../../shared/services/api";
import UsersService from "../../shared/services/api/usersService";
import McsService from "../../shared/services/api/mcsService";
import TeamsService from "../../shared/services/api/teamsService";
import { any } from "zod";
import NewCreateMCModal from "../../shared/components/Modals/NewMC";
import NewTreinamentoCollection from "../../shared/components/Modals/NewCollectionstraining";
import NewTeamModal from "../../shared/components/Modals/NewTeam";
import MenuAdmin from "../../shared/components/Menu/MenuAdmin";


interface Region {
    _id: string;
    name: string;
}

interface Team {
    name: string;
    marketCenterId: string;
    updatedAt: string;

}

interface User {
    fullName: string;
    status: string;
    kwuid: number;
    marketCenterId: string;
    permissionLevel: string;
    teamId: string;
    updatedAt: string;
}

interface MC {
    _id: string;
    name: string;
    kwId: string;
    regionId: string;
    updatedAt: string;
}

const AdminPage: React.FC = () => {
    const [regions, setRegions] = useState<Region[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [mcs, setMcs] = useState<MC[]>([]);

    const [editMc, setEditMc] = useState<null | any>(null);
    const [newMcModalOpen, setNewMcModalOpen] = useState(false);
    const openNewMcModal = () => {
        setEditMc(null);
        setNewMcModalOpen(true);
    }

    const closeModal = () => {
        setNewMcModalOpen(false);
    };

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                }
                let resUsers = await UsersService.getUsers()
                let resMcs = await McsService.getMCs()
                let resTeams = await TeamsService.getTeams()
                setUsers(resUsers)
                setMcs(resMcs)
                setTeams(resTeams)

            } catch (e) {
                console.error(e);
                alert('Erro de Conexão.');
            }

        }
        getData();
    }, []);

    return (
        <>
            <Navbar />
            <MenuAdmin />
            <Sidebar Children={
                <Column style={{ marginTop: '36px' }}>
                    <Row>
                        <Title>Market Centers...</Title>
                        <ButtonMC onClick={openNewMcModal}>Cadastrar MC+</ButtonMC>
                    </Row>
                    <GridMC children={
                        mcs?.map(mc => {
                            return (
                                <Grid item xs={12}>
                                    <Divider style={{ width: '100%' }} />
                                    <div style={{ width: '100%', padding: '5px' }}>
                                        <Link to={'/soukw/admin/edit-mc'}
                                            style={{ textDecoration: 'none', color: '#565e70' }}
                                        >
                                            <Grid container
                                                justifyItems='flex-start'
                                                textAlign={'start'}
                                                style={{
                                                    cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px'
                                                }}
                                            >
                                                <Grid item lg={3} xs={2}>
                                                    {mc.name}
                                                </Grid>
                                                <Grid item lg={2.01} xs={3.2}>
                                                    {mc.kwId}
                                                </Grid>
                                                <Grid item lg={2} xs={2.6}>
                                                    {regions?.find(r => r._id === mc.regionId)?.name}
                                                </Grid>
                                                <Grid item lg={3.1} xs={2.6}>
                                                    {new Date(mc.updatedAt).toLocaleDateString('pt-br')}                                        </Grid>
                                            </Grid>
                                        </Link>
                                    </div>
                                    <Divider style={{ width: '100%' }} />
                                </Grid >)
                        })
                    } />
                    <div>
                        <br />
                        <Divider />
                        <br />
                    </div>
                    <Row>
                        <Title>Times...</Title>
                        <ButtonTeam>Cadastrar Time+</ButtonTeam>
                    </Row>
                    <GridTeams children={
                        teams?.map(team => {
                            return (
                                <Grid item xs={12}>
                                    <Divider style={{ width: '100%' }} />
                                    <div style={{ width: '100%', padding: '5px' }}>
                                        <Link to={'/admin/edit-mc/'} style={{ textDecoration: 'none', color: '#565e70' }}>
                                            <Grid container
                                                justifyItems='flex-start'
                                                textAlign={'start'}
                                                style={{
                                                    cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px'
                                                }}
                                            >
                                                <Grid item lg={2.5} xs={2.5}>
                                                    {team.name}
                                                </Grid>
                                                <Grid item lg={2.5} xs={2.6}>
                                                    {mcs?.find(mc => mc._id === team.marketCenterId)?.name}
                                                </Grid>
                                                <Grid item lg={2.5} xs={2.6}>
                                                    {new Date(team.updatedAt).toLocaleDateString('pt-br')}
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </div>
                                    <Divider style={{ width: '100%' }} />
                                </Grid >)
                        })
                    } />
                    <div>
                        <br />
                        <Divider />
                        <br />
                    </div>
                    <Row>
                        <Title>Usuários...</Title>
                        <ButtonUser>Cadastrar Usuário+</ButtonUser>
                    </Row>
                    <GridUsers children={
                        users.map(user => {
                            return (
                                <Grid item xs={12}>
                                    <Divider style={{ width: '100%' }} />
                                    <div style={{ width: '100%', padding: '5px' }}>
                                        <Link to={'/listings/edit-mc/'} style={{ textDecoration: 'none', color: '#565e70' }}>
                                            <Grid container
                                                justifyItems='flex-start'
                                                textAlign={'start'}
                                                style={{
                                                    cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px'
                                                }}
                                            >
                                                <Grid item lg={2.5} xs={2.5}>
                                                    {user.fullName}
                                                </Grid>
                                                <Grid item lg={1.5} xs={1.5}>
                                                    {user.status}
                                                </Grid>
                                                <Grid item lg={1.6} xs={1.6}>
                                                    {user.kwuid}
                                                </Grid>
                                                <Grid item lg={1.5} xs={1.5}>
                                                    {user.permissionLevel}
                                                </Grid>
                                                <Grid item lg={1.4} xs={1.4}>
                                                    {mcs?.find(mc => mc._id === user.marketCenterId)?.name}
                                                </Grid>
                                                <Grid item lg={1.4} xs={1.4}>
                                                    {mcs?.find(mc => mc._id === user.teamId)?.name}
                                                </Grid>
                                                <Grid item lg={2} xs={2}>
                                                    {new Date(user.updatedAt).toLocaleDateString('pt-br')}
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </div>
                                    <Divider style={{ width: '100%' }} />
                                </Grid >)
                        })
                    } />
                </Column>
            } />
            <NewCreateMCModal
                open={newMcModalOpen || Boolean(editMc)}
                close={closeModal}
                mc={editMc}
            />
        </>

    );
}

export default AdminPage;
