import Gridv2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { CardNews, ContentNews, Header } from './style';
import axios from 'axios';
import { token } from '../../services/api';
import UpdatesService from '../../services/api/updateService';
import NewUpdates from '../Modals/NewUpdates';

interface Update {
    createdAt: string; // Change the type based on your actual data structure
    title: string;
    text: string;
}


const GridNews: React.FC = () => {
    const [updates, setUpdates] = useState<Update[]>([]);
    const [newUpdateModalOpen, setNewUpdateModalOpen] = useState(false);
    const [editUpdate, setEditUpdates] = useState<null | any>(null);

    const openNewUpdateModal = () => {
        setEditUpdates(null);
        setNewUpdateModalOpen(true);
    }
    const closeModal = () => {
        setNewUpdateModalOpen(false);
    };

    useEffect(() => {
        async function getData() {

            try {
                let response = await UpdatesService.getUpdates();
                setUpdates(response)

                // Scroll to the last element in the updates array
                const lastUpdate = response[response.length - 1];
                if (lastUpdate) {
                    const lastItem = document.getElementById(lastUpdate.title); // Assuming title is unique
                    if (lastItem) {
                        lastItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
                    }
                }
            }
            catch (e) {
                console.error(e);
                alert('Erro de Conexão.');
            }
        }
        getData()
    }, [])


    return (
        <>
            <Gridv2
                container
                xs={10}
                md={8}
                lg={11}
                xl={14}
                style={{ height: '100%', textAlign: 'center' }}
                justifyContent='center'>
                <CardNews>
                    <Header>
                        <img
                            src="https://images-front-soukw.s3.amazonaws.com/iconnewsSoukw.svg"
                            alt="listings"
                            style={{ marginLeft: '12px', marginRight: '12px' }}
                        />
                        <p>Novidades</p>
                        <div style={{ width: "76%" }} />
                        <button onClick={openNewUpdateModal}
                            style={{
                                height: 26,
                                width: 100,
                                marginTop: 14,
                                borderRadius: 16,
                                border: 'solid 0.1px',
                                alignContent: 'flex-end',
                                alignItems: 'flex-end',
                                justifyContent: 'end',
                                cursor: 'pointer'
                            }}
                        >
                            + Novidades
                        </button>
                    </Header>
                    <ContentNews>
                        {updates?.map((update, i) => (
                            <Grid item style={{ textAlign: 'left' }} key={i}>
                                <Grid item
                                    lg={12}
                                    md={12}
                                    xs={6}>
                                    <h1 style={{
                                        marginLeft: '10px',
                                        fontSize: '1.2rem'
                                    }}>
                                        {String(update.title).toUpperCase()}
                                    </h1>
                                    <h1 style={{ marginLeft: '10px', color: '#1c5ecc', fontSize: '10px' }}>
                                        {new Date(update.createdAt).toLocaleDateString('pt-br')}
                                    </h1>
                                </Grid>
                                <Grid item xs={10}>
                                    <p style={{ marginLeft: '10px', fontSize: '0.9rem' }}>{update.text}</p>
                                </Grid>
                                <Grid container xs={12}>
                                    <br />
                                    {i < updates.length - 1 && <Divider />}
                                </Grid>
                            </Grid>
                        ))}
                    </ContentNews>
                </CardNews>
            </Gridv2>
            <NewUpdates
                open={newUpdateModalOpen || Boolean(editUpdate)}
                close={closeModal}
                update={editUpdate}
            />
        </>
    );
};

export { GridNews };
