import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const ManageSoftwarePage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}
                            >
                            <Card
                                image="https://soukw.com.br/static/media/pipeImobLogo.1f3541b0e04e0fb24436.png"
                                title="PipeImob"
                                text="O PipeImob é uma plataforma TRM (Transaction Relationship Management) focada na transação. Ela tem como objetivo trazer segurança, rapidez e transparência para todos os participantes da transação de compra e venda imobiliária no pós-venda."
                                acess={'https://app.soukw.com.br/login'}
                                term={'https://drive.google.com/file/d/1SdiFfbWiTwoC9_d9h9p7d4mrqi0Rk7Ep/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: 0,
                                    marginLeft: '10px'
                                }}

                            />
                        </Grid>
                    </Grid>
                </Box>
            } />
        </>

    )
}

export default ManageSoftwarePage;