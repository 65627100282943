import axios from 'axios';
import { BASE_URL, AUTH_ENDPOINT } from './Constants';
import { JwtUserI, UserI, token, } from '../../context/authInterfaces';

const loginUrl = `${AUTH_ENDPOINT}/login`;

const AuthService = async ( email: string, password:string ) => {
    return new Promise<{ user: UserI, jwtUser: JwtUserI, token: token }>((resolve, reject) => {
        axios.post(loginUrl, {email, password}).then(response => {
            console.log("Auth Response: ", response.data)
            let date = new Date( Date.now() + 1000 * 60 * 60 * 8 )
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify( response.data.user ) );
            localStorage.setItem('expiration',`${( Date.now() + (1000 * 60 * 60 * 24))}`);
            localStorage.setItem('jwtUser', JSON.stringify( response.data.jwtUser ) );
            resolve(response.data);
        }).catch(err => {
            let authError: Error;
            if( axios.isAxiosError(err) ) {
               authError = new Error( err.response?.data.message );
               authError.name = 'AuthError';

            } else {
                authError = new Error( err.message );
                authError.name = 'AuthError';
            }
            reject(authError);
        });
    });
}

export default AuthService;
