import { Grid } from "@mui/material";
import { CardListings, ContentListings, Header } from "./style"
import Divider from '@mui/material/Divider';
import React from "react";


interface listingsProps {
    children: React.ReactNode;
}

const GridListings: React.FC<listingsProps> = (props) => {

    return (
        <>
            <CardListings>
                <Header>
                    <Grid container justifyItems='flex-start' alignItems={'center'}
                        style={{
                            width: '100%',
                            height: '60px',
                            cursor: 'pointer',
                            padding: '5px',
                            borderTop: '2px  solid #d9d9d9',
                            borderRight: '2px  solid #d9d9d9',
                            borderLeft: '2px  solid #d9d9d9',
                            backgroundColor: '#f8f7f7',
                            color: 'black'
                        }}>
                        <Grid item lg={3.02} xs={1.8} style={{ paddingLeft: '12px' }}>
                            Foto e Endereço
                        </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Grid item lg={1.2} xs={2.2} style={{ paddingLeft: '12px' }}>
                            Preço
                        </Grid>
                        <Grid item lg={1.5} xs={1.5}>
                            Agente do Listing
                        </Grid>
                        <Grid item lg={1.5} xs={1}>
                            Status do LIsting
                        </Grid>
                        <Grid item lg={1.5} xs={1}>
                            Market Center
                        </Grid>
                        <Grid item lg={1.2} xs={1.2}>
                            Time
                        </Grid>
                        <Grid item xs={1}>
                            Criação
                        </Grid>
                        <Grid item xs={1}>
                            Atualização
                        </Grid>
                    </Grid>
                </Header>
                <ContentListings>
                    {props.children}
                </ContentListings>
            </CardListings>
        </>
    )
}

export default GridListings;