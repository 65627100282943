// UserManagementPage.tsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Grid,
    TextField,
    CircularProgress,
    IconButton,
    Avatar } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import db from './models/db';
import { User } from './models/types';

const UsersPage: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    // Load users from Dexie on component mount
    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await db.users.toArray();
            setUsers(data);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    // Handle search input change
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    // Filter users based on the search term
    const filteredUsers = users.filter(user => 
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Handle adding a new user (for simplicity, using a mock user)
    const handleAddUser = async () => {
        const newUser: User = {
            _id: Date.now(), // Use timestamp as a unique ID for demo purposes
            status: 'INATIVO',
            fullName: 'New User',
            email: 'newuser@example.com',
            password: 'password123',
            permissionLevel: 'AGENT',
            kwuid: '123456',
            marketCenterId: 1
        };

        await db.users.add(newUser);
        fetchUsers(); // Refresh the list
    };

    // Handle deleting a user
    const handleDeleteUser = async (id: number) => {
        await db.users.delete(id);
        fetchUsers(); // Refresh the list
    };

    // Render loading indicator or user cards
    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                User Management
            </Typography>

            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                    label="Search Users"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddUser}>
                    Add User
                </Button>
            </Box>

            <Grid container spacing={2}>
                {filteredUsers.map(user => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={user._id}>
                        <Card>
                        <CardHeader
                            avatar={
                            <Avatar 
                                aria-label={user.fullName} 
                                src={user.photo} alt={`${user.fullName.split(' ')[0].charAt(0)} ${user.fullName.split('')[1].charAt(0)}`}/>
                            }
                            title={user.fullName}
                           
                        />
                            <CardContent>
                                
                                <Typography variant="body2">MarketCenter: {user.permissionLevel}</Typography>
                                <Typography variant="body2">Time: {user.permissionLevel}</Typography>
                                <Typography variant="body2">Email: {user.email}</Typography>
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                                    <IconButton color="primary">
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDeleteUser(user._id)}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default UsersPage;
