import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const LeadsPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid
                            container
                            xs={10}
                            md={12}
                            lg={8}
                            xl={6}
                            paddingTop={2}
                            paddingLeft={2}
                        >
                            <Card
                                image="https://soukw.com.br/static/media/zapierLogo.a0927dd2d9bff90ab94a.png"
                                title="Zapier"
                                text="O Zapier é uma plataforma de automação que permite que aplicativos diferentes se comuniquem entre si e executem tarefas automatizadas. Ele atua como um intermediário entre diversos aplicativos da web, permitindo que você crie integrações personalizadas e automatizações sem a necessidade de escrever código."
                                acess={'https://zapier.com/app/login'}
                                term={'https://drive.google.com/file/d/1W-XgvCrTvcOha48kWAN6b-cV1DWdh7of/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '10px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default LeadsPage;