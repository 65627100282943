import React from 'react';
import { Grid } from '@mui/material';
import ListingCard from './ListingCard';
import {Listing} from './models/ListingsInterfaces'

interface ListingsGridProps {
  listings: Listing[] | undefined;
  fileName: string
}


const ListingsGrid: React.FC<ListingsGridProps> = ({ listings, fileName }: ListingsGridProps) => {
  return (
    <Grid container spacing={3}>
      {listings && listings.map((listing) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={listing._id}>
          <ListingCard listing={listing} fileName={fileName} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListingsGrid;
