import { Dialog, DialogContent, Divider, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { CancelButton, CreateNewCollectionButton, Footer, Header } from './style';
import { useForm } from 'react-hook-form';
import UpdatesService from '../../services/api/updateService';

interface NewUpdatesProps {
    open: boolean;
    close: () => void;
    update?: {
        title: string;
        text: string;
    };
}

export default function NewUpdates({
    open,
    close,
    update,
}: NewUpdatesProps) {
    const [title, setTitle] = useState<string>('');
    const [text, setText] = useState<string>('');

    useEffect(() => {
        setTitle(update ? update.title : '');
        setText(update ? update.text : '')
    }, [update]);

    const { handleSubmit, reset } = useForm()

    async function handleCreateNewTraining() {
        await UpdatesService.createUpdate({
            title,
            text,
            createAt: new Date()
        })

        reset()
    };

    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth="xs">
                <form onSubmit={handleSubmit(handleCreateNewTraining)}>
                    <Header>
                        <div style={{ width: '500px', paddingLeft: '12px' }}>
                            <h2>{update ? 'Editar' : 'Cadastrar'} Novidade Soukw</h2>
                        </div>
                    </Header>
                    <Divider style={{ width: '100%' }} />
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            label="Título *"
                            color="secondary"
                            required
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                        <TextField
                            variant="outlined"
                            label="Descrição *"
                            color="secondary"
                            multiline
                            minRows={3}
                            maxRows={5}
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setText(e.target.value)}
                            value={text}
                        />
                    </DialogContent>
                    <Divider style={{ width: '100%' }} />
                    <Footer>
                        <CancelButton type='button' onClick={close}>
                            Cancelar
                        </CancelButton>
                        <CreateNewCollectionButton type='submit'>
                            Postar Novidades
                        </CreateNewCollectionButton>
                    </Footer>
                </form>

            </Dialog>
        </div>
    );
}
