import {
    Dialog,
    DialogContent,
    Divider,
    MenuItem,
    TextField,
} from '@mui/material';
import axios from 'axios';
import * as z from 'zod'
import React, { useContext, useEffect, useState } from 'react';
import { CancelButton, CreateNewCollectionButton, Footer, Header } from './style';
import { useForm } from 'react-hook-form';
import TrainingService from '../../services/api/trainingService';
import { useParams } from 'react-router-dom';

const newTreniamentosFormSchema = z.object({
    titulo: z.string(),
    subtitle: z.string(),
    description: z.string(),

})

interface NewTrainingContentProps {
    open: boolean;
    close: () => void;
    collection?: {
        _id: string;
        titulo: string;
        subtitulo: string;
        order: number;
        desc: string;
        leadership: string;
    };
}

export default function NewSubTrilhaTraining({
    open,
    close,
    collection,
}: NewTrainingContentProps) {
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [leadership, setLeadershipOnly] = useState<string | undefined>('');
    const [type, setType] = useState<string | undefined>('');

    const { id } = useParams();
    console.log(id)

    useEffect(() => {
        setTitle(collection ? collection.titulo : '');
        setSubtitle(collection ? collection.subtitulo : '');
        setDesc(collection ? collection.desc : '');
        setLeadershipOnly(collection ? collection.leadership : '');
        setType(collection ? collection.leadership : '');
    }, [collection]);

    const { handleSubmit, reset } = useForm();

    function handleChangeType(e: { target: { value: React.SetStateAction<string | undefined>; }; }) {
        setType(e.target.value)
    }

    function handleChangeRoleUser(e: { target: { value: React.SetStateAction<string | undefined>; }; }) {
        setLeadershipOnly(e.target.value)
    }

    async function handleCreateNewTraining() {
        try {
            const response = await TrainingService.createCollection({
                title,
                subtitle,
                desc,
                role: leadership,
                type,
            });
            console.log(id)
            console.log(response)

            let idsubTrilha = response._id

            const updateRail = await TrainingService.createCollectionTrilha(
                `${id}`,
                {
                    idsubTrilha,
                    title,
                    subtitle,
                    desc,
                    role: leadership,
                    type,
                }
            );
            alert(`Coleção criaca com sucesso, ${response._id}`)
            close();

            // if (typeof id === 'string' && id.trim() !== '') {
            //     const idNumber = parseInt(id); 
            //     console.log(idNumber)
            //     if (!isNaN(idNumber)) {
            //         const updateRail = await TrainingService.createCollectionTrilha(
            //             idNumber,
            //             {
            //                 idsubTrilha,
            //                 title,
            //                 subtitle,
            //                 desc,
            //                 role: leadership,
            //                 type,
            //             }
            //         );
            //         console.log(updateRail);
            //         alert(`Coleção criaca com sucesso, ${response._id}`)
            //     } else {
            //         console.error('Invalid id:', id);
            //     }
            // } else {
            //     console.error('Invalid id:', id);
            // }
        } catch (error) {
            console.log(error)
            reset()
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth="xs">
                <form onSubmit={handleSubmit(handleCreateNewTraining)}>
                    <Header>
                        <div style={{ width: '500px', paddingLeft: '12px' }}>
                            <h1>{collection ? 'Editar' : 'Cadastrar Nova'} Coleção</h1>
                        </div>
                    </Header>
                    <Divider style={{ width: '100%' }} />
                    <DialogContent>
                        <br />
                        <TextField
                            variant="outlined"
                            label="Titulo *"
                            color="secondary"
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />

                        <TextField
                            variant="outlined"
                            label="Subtitulo *"
                            color="secondary"
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setSubtitle(e.target.value)}
                            value={subtitle}
                        />

                        <TextField
                            variant="outlined"
                            label="Descrição *"
                            color="secondary"
                            multiline
                            minRows={3}
                            maxRows={5}
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setDesc(e.target.value)}
                            value={desc}
                        />

                        <TextField
                            variant='outlined'
                            select
                            label='Permissionamento *'
                            color='secondary'
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={handleChangeRoleUser}
                            value={leadership}
                        >
                            <MenuItem value={"ALL"} >Todos</MenuItem>
                            <MenuItem value={"AGENT"}>Agente</MenuItem>
                            <MenuItem value={"LEADERSHIP"}>Liderança</MenuItem>
                        </TextField>

                        <TextField
                            variant='outlined'
                            select
                            label='Tipo *'
                            color='secondary'
                            fullWidth
                            required
                            style={{ marginTop: '8px', marginBottom: '5px' }}
                            onChange={handleChangeType}
                            value={type}
                        >
                            <MenuItem value={"SYSTEM"} >Sistema</MenuItem>
                            <MenuItem value={"SOUKW"}>Soukw</MenuItem>
                            <MenuItem value={"KWW"}>KWW</MenuItem>
                        </TextField>
                    </DialogContent>
                    <Divider style={{ width: '100%' }} />
                    <Footer>
                        <CancelButton onClick={close}>
                            Cancelar
                        </CancelButton>
                        <CreateNewCollectionButton type='submit'>
                            {collection ? 'Editar' : 'Cadastrar'}
                        </CreateNewCollectionButton>
                    </Footer>
                </form>
            </Dialog>
        </div>
    );
}
