// DataContext.tsx
import React, { createContext, useContext, useEffect, useCallback } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import db from '../models/db'; // Your Dexie instance
import {User} from '../models/types';
import UsersService from '../../../../shared/services/api/usersService';


interface DataContextType<T> {
    data: User[] | undefined; // Listings are undefined while loading
    loading: boolean;
    reloadData: () => Promise<void>; // Function to manually refresh data if needed
}

//const UserContext = createContext<UserContextType | undefined>(undefined);
const UserContext = createContext<DataContextType<User> | undefined>(undefined);

export const UserDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const users = useLiveQuery(() => db.users.toArray(), [], []);
    const loading = users === undefined;

    // Function to reload data from the backend
    const reloadData = useCallback(async () => {
        try {
            const response = await UsersService.getUsers( ); 
            const data: User[] = response;
            console.log( data )


            //TODO: Alterar para buscar apenas registros que tenham mudado com o decorrer do tempo (quae nada) 
            await db.transaction('rw', db.users, async () => {
                await db.users.clear();
                await db.users.bulkPut(data);
            });

            console.log('Data reloaded from server and stored in Dexie');
        } catch (error) {
            console.error('Failed to load data:', error);
        }
    }, []);

    // Use `setInterval` to automatically sync data every 5 minutes
    useEffect(() => {
        // Initial data load when component mounts
        reloadData();

        // Set up interval for periodic sync
        const interval = setInterval(() => {
            console.log('Syncing data...');
            reloadData();
        }, 120 * 60 * 1000); // Sync every 5 minutes

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, [reloadData]);

    return (
        <UserContext.Provider value={{ loading, reloadData, data: users }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the DataContext
export const useData = (): DataContextType<User> => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
