export function isTokenExpired( ): boolean {
    const expiration = localStorage.getItem('expiration');
    if ( expiration && Date.now() < parseInt(expiration) ) {
        return false;
    }
    return true
}

export function hasToken( ): boolean {
    const token = localStorage.getItem('token');
    if ( token && token.length > 0 ) {
        return true;
    }
    return false
}

export function getToken( ): string {
    if( hasToken() && !isTokenExpired() ) {
        return localStorage.getItem('token') || '';
    } else {
        alert("A autenticação da sua sessão expirou. Faça o login novamente.");
        return '';
    }
}

export function axiosConfig() {
    const token = getToken();

    if( token.length > 0 ) {
        return {
            headers: { Authorization: `Bearer ${token}` }
        };
    } else {
        console.log("Token não encontrado");
        throw new Error("Token não encontrado");
    }
  };