import { Navbar, Sidebar } from "../../../shared/components";

const EditMCPage = () => {
    return (
        <>
            <Navbar />
            <Sidebar Children />
        </>
    )
}

export default EditMCPage;