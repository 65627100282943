import React, { createContext, useState, ReactNode } from 'react';
import {AuthContextProps, JwtUserI, UserI, token} from './authInterfaces';

// interface AuthContextProps {
//   token: string;
//   user: any | null; // Replace 'any' with the actual type of 'user' if possible
//   mc: any | null; // Replace 'any' with the actual type of 'mc' if possible
//   region: any | null; // Replace 'any' with the actual type of 'region' if possible
//   jwtUser: any | null; // Replace 'any' with the actual type of 'jwtUser' if possible
//   loading: boolean;
//   setToken: React.Dispatch<React.SetStateAction<string>>;
//   setUser: React.Dispatch<React.SetStateAction<any | null>>;
//   setMc: React.Dispatch<React.SetStateAction<any | null>>;
//   setRegion: React.Dispatch<React.SetStateAction<any | null>>;
//   setJWTUser: React.Dispatch<React.SetStateAction<any | null>>;
//   setLoading: React.Dispatch<React.SetStateAction<boolean>>;
//   logout: () => void;
// }

const expirationDate = localStorage.getItem('expirationDate');
const isExpired = expirationDate ? new Date(expirationDate).getTime() < Date.now() : false;

//Obtem usuario e token do local storage
const defaultValue: AuthContextProps = {
    jwtToken: localStorage.getItem('user'),
    user: localStorage.getItem('user'),
    jwtUser: localStorage.getItem('jwtUser'),
    authLoading: false,
    authError: [],
    setPrevJwtUser: (prevJwtUser: JwtUserI | null) => {},
    setPrevUser: (prevUser: UserI | null) => {},
    setPrevJwtToken: (prevJwtToken: token) => {},
    signIn: (email: string, password: string) => Promise<any>,
    signOut: () =>  Promise<void>,
  } as AuthContextProps;

const AuthContext = createContext<AuthContextProps>(defaultValue);

export default AuthContext;

// interface AuthProviderProps {
//   children: ReactNode;
// }

// export default function AuthProvider({ children }: AuthProviderProps): JSX.Element {
//   const navigate = useNavigate();
//   const [jwtToken, setJwtToken] = useState<token>('');
//   const [user, setUser] = useState<UserI | null>(null); // Replace 'any' with the actual type if possible
//   const [jwtUser, setJWTUser] = useState<JwtUserI | null>(null); // Replace 'any' with the actual type if possible
//   const [authLoading, setAuthLoading] = useState(false);
//   const [authError, setAuthError] = useState<Error[]>([]);

//   const logout = () => {
//     localStorage.clear();
//     setJwtToken('');
//     setUser(null);
//     setJWTUser(null);
//     setAuthLoading(false);
//     navigate('/');
//   };

//   return (
//     <AuthContext.Provider
//       value={{
//         jwtToken,
//         user,
//         jwtUser,
//         authLoading,
//         authError,
//         signIn,
//         signOut,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// }
