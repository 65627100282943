import { Box, Grid, Tooltip } from "@mui/material";
import { Navbar, Sidebar } from "../../../shared/components";
import { Container } from "../style";
import { Carousel } from "antd";
import CardsCollections from "../../../shared/components/Cards/CardsCollections";
import { useContext, useEffect, useState } from "react";
import NewTreinamentoCollection from "../../../shared/components/Modals/NewCollectionstraining";
import MenuTraining from "../../../shared/components/Menu/MenuTraining";
import TrainingService from "../../../shared/services/api/trainingService";
import AuthContext from "../../../shared/context/AuthContext";
import { on } from "events";

const contentStyle: React.CSSProperties = {
    height: 'auto',
    width: 'calc(100% - 10px)',
    color: '#fff',
    marginTop: '12px',
    textAlign: 'center',
    lineHeight: '180px',
    borderRadius: '6px'
};

interface OnboardingProps {
    id: string;
    title: string;
    subtitle: string;
    desc: string;
    leadership: string;
    isOnboarding: boolean;
}

interface UserProps {
    permissionLevelTreinamentos: string;
}

const HomeTrainingPage = () => {
    const [onboarding, setOnboarding] = useState<OnboardingProps[]>([]);
    const [render, setRender] = useState(true);
    const { jwtToken, jwtUser } = useContext(AuthContext);

    useEffect(() => {
        async function getData() {
            try {
                const onboardingCourses = await TrainingService.getLearnEntity();
                if (Array.isArray(onboardingCourses)) {
                    setOnboarding(onboardingCourses.filter((c) => c.showInHome));
                } else {
                    setOnboarding( [onboardingCourses])
                }
            } catch (error) {
                console.error('Erro ao obter dados de treinamento:', error);
            }
        }
        getData()
    }, [])

    return (
        <>
            <Navbar />
            <MenuTraining />
            <Sidebar Children={
                <Container>
                    <Carousel>
                        <div>
                            <img
                                style={contentStyle}
                                src="https://images-front-soukw.s3.amazonaws.com/universidade+soukw.png"
                                alt="carousel"
                            />
                        </div>
                    </Carousel>

                    {/* Continuar Aprendendo
                    <div id="row">
                        <h1> Continuar Aprendendo</h1>

                    </div>
                    <Grid marginLeft={2}>
                        <Box sx={{ width: "100%" }}>
                            <Grid
                                container
                                rowSpacing={2}
                                columnSpacing={{ xs: 1, sm: 2, md: 24 }}
                            >
                                <Grid item xs={8} sm={6} md={5} lg={3.6} xl={2.8}>
                                    <CardsCollections
                                        title="Vem do Banco"
                                        subtitle="Vem do banco"
                                        description="Vem do banco"
                                        navigate=""
                                    />
                                </Grid>
                                <Grid item xs={8} sm={6} md={5} lg={3.6} xl={2.8}>
                                    <CardsCollections navigate="" title="" description="" subtitle="" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid> */}

                    {/* Cursos Soukw */}
                    <div id="row">
                        <h1> Cursos Soukw</h1>
                    </div>
                    <Grid marginLeft={2}>
                        <Box sx={{ width: "100%" }}>
                            <Grid
                                container
                                rowSpacing={{ xs: 1, sm: 2, md: 4 }}
                            >
                                {onboarding.map((o) => (
                                    <Grid item key={o.id} xs={12} sm={9} md={6} lg={4} xl={3}>
                                        <CardsCollections
                                            title={o.title}
                                            subtitle={o.subtitle}
                                            description={o.desc}
                                            navigate={`/soukw/training/courses/${o.id}`} // Usar template string para construir o URL
                                        />
                                    </Grid>
                                ))}

                            </Grid>
                        </Box>
                    </Grid>

                    {/* Cursos Favoritos 
                    <div id="row">
                        <h1> Cursos Favoritos</h1>

                    </div>
                    <Grid marginLeft={2}>
                        <Box sx={{ width: "100%" }}>
                            <Grid
                                container
                                rowSpacing={2}
                                columnSpacing={{ xs: 1, sm: 2, md: 24 }}
                            >
                                <Grid item xs={8} sm={6} md={5} lg={3.6} xl={2.8}>
                                    <CardsCollections
                                        title="Vem do Banco"
                                        subtitle="Vem do banco"
                                        description="Vem do banco"
                                        navigate=""
                                    />
                                </Grid>
                                <Grid item xs={8} sm={6} md={5} lg={3.6} xl={2.8}>
                                    <CardsCollections navigate="" title="" description="" subtitle="" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    */}
                </Container>
            } />
        </>

    )
}

export default HomeTrainingPage;