import axios, { AxiosResponse } from "axios";
import { TRANSMITTAL_ENDPOINT } from "./Constants";
import { axiosConfig } from "./helper";


export default class TransmittalService {

    private static async requestBuilder( 
        endPoint: string,
        method: 'get' | 'post' | 'patch' | 'put' | 'delete',
        id='',
        body = {}) {
        let response: AxiosResponse;
        if( id.length > 0)
            if( Object.keys(body).length > 0 )
                response = await axios[method](`${endPoint}/${id}`, body, axiosConfig( ));
            else
                response = await axios[method](`${endPoint}/${id}`, axiosConfig( ));
        else
            if( Object.keys(body).length > 0 )
                response = await axios[method](endPoint,  body, axiosConfig( ));
            else
                response = await axios[method](endPoint,  axiosConfig( ));
        return response.data;
    }

    static async getTransmittals(  ) {
        return await TransmittalService.requestBuilder(TRANSMITTAL_ENDPOINT, 'get');
    }

    static async getTransmittal(id: string) {
        return await TransmittalService.requestBuilder(TRANSMITTAL_ENDPOINT, 'get', id);
    }

    static async createTransmittal(transmittal: any) {
        const response = await axios.post(TRANSMITTAL_ENDPOINT, transmittal, axiosConfig());
        return response.data;
    }

    static async updateTransmittal(id: string, transmittal: any) {
        return await TransmittalService.requestBuilder(TRANSMITTAL_ENDPOINT, 'put', id, transmittal)
    }

    static async deleteTransmittal(id: string) {
        const response = await axios.delete(`${TRANSMITTAL_ENDPOINT}/${id}`, axiosConfig());
        return response.data;
    }

    static async getTotalTransmittal() {
        const response = await axios.get(TRANSMITTAL_ENDPOINT, axiosConfig());
        console.log(response.data.length);
        return response.data.length;
    }
}
