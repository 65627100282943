import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { Backdrop } from '@mui/material';

/**
 * different types of alerts.
 */
type alertTypes ="warning" | "error" | "info" | "success";

/**
 * Interface for the properties of the AutoDisappearAlert component.
 * @param timeout - The duration in seconds after which the alert will automatically disappear.
 * @param message - The message to be displayed in the alert.
 * @param alertType - The type of the alert. Defaults to 'success'.
 * @param errorId - A unique identifier for the error, if applicable.
 * @param onClose - A callback function to be called when the alert is closed. Takes an optional parameter id for the errorId.
 */

interface AutoDisappearAlertProps {
  timeout: number;
  message: string;
  alertType?: alertTypes;
  errorId?: number;
  onClose?: (id?: number) => void;
}
/**
 * A component that displays a Material-UI Alert with a backdrop.
 * The Alert will automatically disappear after a specified timeout.
 *
 * @param props - The props for the component.
 * @returns - A React component.
 */
const BackdropTimedAlert = (props: AutoDisappearAlertProps ) => {
    /**
     * The props for the component.
     */
    const { 
        timeout,
        message,
        alertType, 
        onClose, 
        errorId 
    } = props;

    /**
     * State variable to control the visibility of the Alert.
     */
    const [open, setOpen] = useState(true);

    /**
     * Function to handle the close event of the Alert.
     * It sets the open state to false and calls the onClose prop if provided.
     */
    const handleClose = ( ) => {
        setOpen(false);
        if( onClose ) 
            onClose( errorId );
    }

    /**
     * Effect hook to set up a timeout that will close the Alert after the specified timeout.
     * It also clears the timeout when the component unmounts.
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
            if (onClose) 
                onClose( errorId );
        }, 1000 * timeout); // Alert will disappear after 3000 ms

        return () => {
            clearTimeout(timer); // Clear timeout if component unmounts
        };
    }, []);

    /**
     * The render function for the component.
     * It returns a Backdrop with an Alert inside.
     */
    return (
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
                <Alert severity={alertType ? alertType : 'success'} onClose={() => handleClose()}>
                    {message}
                </Alert>
      </Backdrop>
    );
}

export default BackdropTimedAlert;
