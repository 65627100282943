import { Tooltip } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuText, Row } from "./style";
import { MenuHeader } from "../../../pages/EAD/style";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

interface soukwProps {
    pageTitle: string;
    route: string;
    children: ReactNode;
    selected?: string;
}

const MenuAdmin = () => {
    const Location: React.FC<soukwProps> = (props) => {
        return (
            <Tooltip title={props.pageTitle} placement="top">
                <Link to={props.route}>
                    <div className={props.selected} style={{ display: 'flex', flexDirection: 'row' }}>
                        {props.children}
                    </div>
                </Link>
            </Tooltip>
        )
    };

    return (
        <Menu>
            <Row>
                <MenuHeader>
                    <Location pageTitle="Sistemas" route="/soukw/admin/market-centers" selected=".menu-trainig" children={
                        <>
                            <BusinessOutlinedIcon
                                color={'action'}
                                sx={{ width: '28px', height: '28px' }}
                                style={{ marginTop: '1.2rem' }}
                            />
                            <MenuText>Market Centers</MenuText>
                        </>
                    } />

                    <Location pageTitle="Sistemas" route="/soukw/admin/teams" selected=".menu-trainig" children={
                        <>
                            <GroupsOutlinedIcon
                                color={'action'}
                                sx={{ width: '28px', height: '28px' }}
                                style={{ marginTop: '1.2rem' }}
                            />
                            <MenuText>Times</MenuText>
                        </>
                    } />

                    <Location pageTitle="Sistemas" route="/soukw/admin/users" selected=".menu-trainig" children={
                        <>
                            <PeopleAltOutlinedIcon
                                color={'action'}
                                sx={{ width: '28px', height: '28px' }}
                                style={{ marginTop: '1.2rem' }}
                            />
                            <MenuText>Usuários</MenuText>
                        </>
                    } />

                </MenuHeader>
            </Row>
        </Menu>
    );
};

export default MenuAdmin;