import { useContext } from "react";
import {
    BrowserRouter as Router,
    Navigate,
    Outlet
} from "react-router-dom";
import AuthContext from "../shared/context/AuthContext";

const PrivateRoute = () => {
    const { authLoading, jwtToken } = useContext(AuthContext);
    return jwtToken ? <Outlet /> : <Navigate to={'/login'} />
}

export default PrivateRoute;