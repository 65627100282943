import { Box, Grid, Divider, Chip } from "@mui/material";
import { BackButton, CreateCourseButton } from "../style";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CardsCourses from "../../../shared/components/Cards/CardsCourse";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Navbar, Sidebar } from "../../../shared/components";
import { useContext, useEffect, useState } from "react";
import TrainingService from "../../../shared/services/api/trainingService";
import axios from "axios";
import AuthContext from "../../../shared/context/AuthContext";
import { LearnEntityType, LessonDetailsFormI } from "./lessonInterfaces";


interface LessonsProps {
    _id: string;
    title: string;
    subtitle: string;
    cover: string;
    entityType?: string;
    type?: "SYSTEM" | "SOUKW" | "KWW" | "CULTURE" | "MODELS";
    role?: "AGENT" | "LEADERSHIP" | "ALL";
    published?: boolean;
    hasFather?: boolean;
    showUnbound?: boolean;
}

const LessonsPage = () => {

    const CreationalButton = ( {title, route} : {route: string, title: string} ) => {
        return(
            <CreateCourseButton onClick={() => navigate(route)}>
            <AddCircleOutlineIcon sx={{ margin: '0px 6px -6px 14px' }} />
                {title}
            </CreateCourseButton>
        )
    }

const GridCard = ( {elementType, learningEntity, mode}:{elementType: string, learningEntity: LessonsProps, mode: "EDITOR"|"STUDENT" } ) => {
    return (
            <Grid
            item
            key={learningEntity._id}
            xs={8}
            sm={8.5}
            md={6.2}
            lg={4.2}
            xl={3.2}
        >
            <CardsCourses
                navigate={mode==='EDITOR' ? 
                `/soukw/training/create-${elementType}/${learningEntity._id}` :
                `/soukw/training/lesson/${learningEntity._id}`
                }
                subtitle={learningEntity.subtitle}
                title={learningEntity.title}
                tumbnail={learningEntity.cover?.length > 0 ? learningEntity.cover : "https://images-front-soukw.s3.amazonaws.com/coursedefalt.png"}
            />
        </Grid>
        )
    }
    
    const [courses, setCourses] = useState<LessonsProps[]>([])
    const [count, setCount] = useState()
    const [collectionEdit, setCollectionEdit] = useState<null | any>(null); // Replace 'any' with the type of your collection data
    const [editMode, setEditMode] = useState(false);
    const { jwtUser, user } = useContext(AuthContext)
    const { id } = useParams();


    const navigate = useNavigate();
    const currentLocation = useLocation().pathname;

    let elementType: LearnEntityType | '' = '';


    if( currentLocation.match(/\-course/ ) )
         elementType = 'course';
    if( currentLocation.match(/\-training/ ) )
        elementType = 'training';
    if( currentLocation.match(/\-rail/ ) )
        elementType = 'rail';

    useEffect(() => {
        async function getData() {
            try {
                let trainingServiceResponse;

                switch (elementType) {
                    case 'course':
                        trainingServiceResponse = await TrainingService.getLesson();
                        break;
                    case 'training':
                        trainingServiceResponse = await TrainingService.getTreinamentos();
                        break;
                    case 'rail':
                        trainingServiceResponse = await TrainingService.getCollections();
                        break;
                    default:
                        trainingServiceResponse = await TrainingService.getLearnEntity( );
                        break;
                }
                console.log("Cursos: ", trainingServiceResponse)
                setCourses(
                    trainingServiceResponse.length > 0 ? 
                    trainingServiceResponse.filter(( c: LessonsProps ) => {
                        if( currentLocation.match('system') )
                         return (c.type === 'SYSTEM' || c.type === 'SOUKW');
                        if( currentLocation.match('agent') )
                          return c.role!.match(/AGENT|ALL/i)
                        if( currentLocation.match('leadership') )
                         return c.role!.match(/LEADERSHIP|ALL/i)
                        return true;
                    }) : [] )
                
            } catch (err) {
                console.log(err)
            }
        }
        getData();
    }, [])

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Grid>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <BackButton onClick={() => navigate(-1)}>
                            <ArrowBackIosIcon sx={{ margin: '0px 0px -6px 6px', }} />
                            Voltar
                        </BackButton>
                        {user?.permissionLevelTreinamentos === 'EDITOR' && (
                            <>
                                { elementType === 'course' ?
                                        <CreationalButton route={`/soukw/training/create-${elementType}/`} title='Novo Curso' /> :
                                    elementType === 'training' ?
                                    <CreationalButton 
                                        route='/soukw/training/create-training/'
                                        title='Novo Treinamento' /> :
                                    <CreationalButton 
                                        route='/soukw/training/create-rail/'
                                        title='Nova Trilha' />
                                }
                            </>
                        )}
                    </div>
                    <Box sx={{ width: "100%" }}>
                        {courses.length > 0 ? (
                            <Grid
                                container
                                rowSpacing={2}
                                paddingTop={2}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                {courses
                                    .filter( el => el.entityType === 'RAIL')
                                    .filter( el => {
                                        if( currentLocation.match(/edit|create/) ) {
                                            return true;
                                        }
                                        return el.published;
                                    })
                                    .map((c) => {
                                    return (
                                        <GridCard 
                                            elementType={elementType} 
                                            learningEntity={c} 
                                            mode={currentLocation.match(/edit|create/) ? "EDITOR" : "STUDENT"}/>
                                    )})
                                }
                                {courses
                                    .filter( el => el.entityType === 'TRAINING')
                                    .filter( el => {
                                        if( currentLocation.match(/edit|create/) ) {
                                            return true;
                                        }
                                        if( currentLocation.match(/\/soukw\/training\/system|\/soukw\/training\/agent|\/soukw\/training\/leadership/) &&
                                            (el.hasFather === false) )
                                                return el.published;
                                        if( currentLocation.match(/\/soukw\/training\/system|\/soukw\/training\/agent|\/soukw\/training\/leadership/) &&
                                            (el.hasFather === true && el.showUnbound === true) )
                                                return el.published;
                                        return false;
                                    })
                                    .map((c) => {
                                        return (
                                        <GridCard elementType={elementType} learningEntity={c} mode={currentLocation.match(/edit|create/) ? "EDITOR" : "STUDENT"}/>
                                    )})
                                }
                                {courses
                                    .filter( el => el.entityType === 'COURSE')
                                    .filter( el => {
                                        if( currentLocation.match(/edit|create/) ) {
                                            return true;
                                        }
                                        if( currentLocation.match(/\/soukw\/training\/system|\/soukw\/training\/agent|\/soukw\/training\/leadership/) &&
                                            (el.hasFather === false) )
                                                return el.published;
                                        if( currentLocation.match(/\/soukw\/training\/system|\/soukw\/training\/agent|\/soukw\/training\/leadership/) &&
                                            (el.hasFather === true && el.showUnbound === true) )
                                                return el.published;
                                        return false;
                                    })
                                    .map((c) => {
                                    return (
                                        <GridCard elementType={elementType} learningEntity={c} mode={currentLocation.match(/edit|create/) ? "EDITOR" : "STUDENT"}/>
                                    )})
                                }
                            </Grid>
                        ) : (
                            <p>Nenhum item encontrado.</p>
                        )}
                    </Box>
                </Grid >
            } />
        </>
    )

}

export default LessonsPage;