import styled from "@emotion/styled";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Container = styled.div`
    margin-top: 36px;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  top: 64px;
  background: #FFF;
  width: 100%;
  height: 3rem;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const MenuText = styled.p`
    font-weight: normal;
    font-size: 16px;
    color: black;
    margin-left: 0.85rem;
    margin-right: 2.5rem;
    margin-top: 1.5rem;
`;

export const MenuHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 14.5rem;
`;

export const EditButton = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 18rem;
    color: #1C5BBD;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin-left: calc(50% - -30px);
    margin-bottom: 12px;
`;

// Button Mark Done classesPage
export const EditButton2 = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 18rem;
    color: #1C5BBD;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin-left: calc(100% - -106px);
    margin-bottom: 12px;
`;

export const CreateButton = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 18rem;
    color: #ffff;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin-left: calc(6% - 6px);
    text-align: center;
`;

// Button Create and edit courses ////

export const BtnCreate = styled.button`
    font-size: 14px;
    width: 10rem;
    height: 2.4rem;
    color: #ffff;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin: 0.5em;
    text-align: center;
`;

export const BtnEdit = styled.button`
    font-size: 14px;
    width: 10rem;
    height: 2.4rem;
    color: #1C5BBD;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin: 0.5em;
    text-align: center;
`;

// Button Create and edit courses ////

export const CreateCourseButton = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 18rem;
    color: #ffff;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin-left: calc(60% - 6px);
    text-align: center;
`;

export const CreateLessonButton = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 18rem;
    color: #ffff;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin-left: calc(50% - 6px);
    text-align: center;
`;

export const BackButton = styled.button`
    font-size: 16px;
    height: 2.4rem;
    width: 7rem;
    color: #1C5BBD;
    border: 0.1px solid transparent;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
`;