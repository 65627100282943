import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Typography, Divider } from '@mui/material';
import { FiltersInterface } from './interfaces';

interface SearchFormProps {
  onSearch: (filters: any) => void;
  onClose: () => void;
  prevFilters: FiltersInterface | undefined;

}



const SearchForm: React.FC<SearchFormProps> = ({ onSearch, onClose, prevFilters }) => {

  const [filters, setFilters] = useState<FiltersInterface>(
    prevFilters ? prevFilters : {
      mls_number: '',
      minPrice: 0,
      maxPrice: 100000000,
      minArea: 0,
      maxArea: 100000000,
      bathrooms: 1,
      parking: 0,
      inXml: false
    } )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
      setFilters({
        ...filters,
        [name]: name === 'inXml' ? Boolean( value ) : Number( value )
      });

  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(filters);
    onClose();
  };

  return (
    <Box p={2}>
      <Typography variant="h6">Search Listings</Typography>
      <Divider sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField
              name="mls_number"
              label="Número MLS"
              value={filters.mls_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="bathrooms"
              label="Bathrooms"
              value={filters.bathrooms}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="parking"
              label="Vagas de Garagem"
              value={filters.parking}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="minPrice"
              label="Valor Min"
              type="number"
              value={filters.minPrice}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="maxPrice"
              label="Valor Max."
              type="number"
              value={filters.maxPrice}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="minArea"
              label="Área Min"
              type="number"
              value={filters.minArea}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="maxArea"
              label="Área Max"
              type="number"
              value={filters.maxArea}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="inXml"
              label="No XML"
              value={filters.inXml}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SearchForm;
