import axios from "axios";
import { MCS_ENDPOINT } from "./Constants";
import { axiosConfig } from "./helper";

export default class McsService {

    static async getMCs() {
        const response = await axios.get(MCS_ENDPOINT, axiosConfig());
        console.log(response.data)
        return response.data;
    }

    static async getMC(id: number) {
        const response = await axios.get(`${MCS_ENDPOINT}/${id}`, axiosConfig());
        return response.data;
    }

    static async createMC(mc: any) {
        const response = await axios.post(MCS_ENDPOINT, mc, axiosConfig());
        return response.data;
    }

    static async updateMC(id: number, mc: any) {
        const response = await axios.patch(`${MCS_ENDPOINT}/${id}`, mc, axiosConfig());
        return response.data;
    }

    static async deleteMC(id: number) {
        const response = await axios.delete(`${MCS_ENDPOINT}/${id}`, axiosConfig());
        return response.data;
    }

    static async getTotalMCs() {
        const response = await axios.get(MCS_ENDPOINT, axiosConfig());
        console.log(response.data.length);
        return response.data.length;
    }
}
