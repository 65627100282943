import styled from "@emotion/styled";

export const ResponsiveScreen = styled.div`
  /* DeskTop */
  @media screen and (min-width: 1210px) {
  margin-left: 14%;  
  };
  @media screen and (min-width: 1120px) and (max-width: 1210px) {
  margin-left: 14%;  
  };

  /* LapTop */
  @media screen and (min-width: 1000px) and (max-width: 1119px) {
   margin-left: 14%;
  }

  /* tablet */
  @media screen and (min-width: 640px) and (max-width: 999px) {
    margin-left: 14%;
  };
  @media screen and (min-width: 640px) and (max-width: 940px) {
    margin-left: 16%;
  };
  @media screen and (min-width: 640px) and (max-width: 899px) {
    margin-left: 18%;
  };
  @media screen and (min-width: 640px) and (max-width: 840px) {
    margin-left: 20%;
  };
  @media screen and (min-width: 640px) and (max-width: 799px) {
    margin-left: 22%;
  };
  @media screen and (min-width: 640px) and (max-width: 740px) {
    margin-left: 24%;
  };
  @media screen and (min-width: 640px) and (max-width: 699px) {
    margin-left: 26%;
  };

`;

export const CardHome = styled.div`
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 5px 0 #d7d9dc;
    width: 100%;
    height: 22%;
`;

export const Header = styled.div`
    text-align: start;
    font-size: medium;
    display: flex;
    flex-direction: row;
    background-color: #f7f5f5;
    border-radius: 6px 6px 0px 0px;
    box-shadow: 0 1px 5px 0 #d7d9dc;
    width: 100%;
`;

export const ContentHome = styled.div`
    display: flex;
    flex-direction: row;
`

export const ContentNews = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 640px;
`

export const Card = styled.div`
  background: transparent;
  width: 48vh;
  text-align: center;
`
export const Paragraph = styled.p`
  font-size: 16px;
`

export const Title = styled.h1`
  font-size: 20px;
`

export const Button = styled.button`
  font-size: 18px;
  color: #1C5BBD;
  border: 1px solid #1C5BBD;
  border-radius: 25px;
  height: 38px;
  width: 14rem;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 6px;
`

export const Divider = styled.div`
  margin-top: 0.76rem;
  width: 1px;
  height: 98px;
  background: #7E7E7E;
`

export const CardNews = styled.div`
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 5px 0 #d7d9dc;
    width: 100%;
    height: 22%;
`;

export const CardTeams = styled.div`
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 5px 0 #d7d9dc;
    width: 100%;
    height: 22%;
`;

export const ContentTeams = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 70vh;
`;

export const ContentMC = styled.div`
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow: scroll;
`;

export const ContentUsers = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 70vh;
`;

export const CardProfile = styled.div`
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 1px 5px 0 #d7d9dc;
    width: 100%;
    height: 22%;
`

export const ContentProfile = styled.div`
    display: flex;
    flex-direction: column;
    height: 200px;
`;

export const HeaderProfile = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImageProfile = styled.img`
    margin-left: 16px;
    margin-bottom: 16px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: solid 3px #FFFF;
`;

export const ParagraphProfile = styled.p`
  text-align: left;
  margin: 0;
  padding-left: 20px;
  font-size: 14px;
  color: #757D9C;
`;

export const PersonalData = styled.p`
  text-align: left;
  margin: 0;
  padding-top: 6px;
  padding-left: 20px;
  padding-bottom: 12px;
  font-size: 18px;
`;


export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardListings = styled.div`
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 #d7d9dc;
    width: 100%;
    height: 22%;
`;

export const ContentListings = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PhotoListings = styled.div`
    display: flex;
    flex-direction: row;
`;

export const EditProfile = styled.button`
    flex-direction: row;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    top: 110px;
    left: 46px;
    font-size: 14px;
    height: 2rem;
    width: 7rem;
    color: #1C5BBD;
    border: 1px solid #1C5BBD;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 12px;
`;