import styled from "@emotion/styled";

export const Container = styled.div``

export const ButtonFilter = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 10rem;
    color: #1C5BBD;
    border: 1px solid #1C5BBD;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 12px;
`;