import { DashboardCustomize } from "@mui/icons-material";
import { Navbar, Sidebar } from "../../shared/components";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { Cards } from "./style";
import DashCards from "../../shared/components/Cards/DashCards";

const Dashboard = () => {
    const top100films = [
        { label: 'The Shawshank Redemption' },
        { label: 'The Godfather' },
        { label: 'The Godfather: Part II' },
        { label: 'The Dark Knight' },
        { label: '12 Angry Men' },
        { label: "Schindler's List" },
        { label: 'Pulp Fiction' },
    ]
    return (
        <>
            <Navbar />
            <Sidebar Children={
                <>
                    <div id="Header" style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <h1 style={{ color: '#1C5BBD' }}>Selecionar:</h1>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={top100films}
                            style={{
                                marginLeft: '12px',
                                marginTop: '6px'
                            }}
                            sx={{ width: 300 }}
                            renderInput={
                                (params) =>
                                    <TextField {...params} label="Market Center" />
                            }
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={top100films}
                            style={{
                                marginLeft: '12px',
                                marginTop: '6px'
                            }}
                            sx={{ width: 300 }}
                            renderInput={
                                (params) =>
                                    <TextField {...params} label="Time" />
                            }
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={top100films}
                            style={{
                                marginLeft: '12px',
                                marginTop: '6px'
                            }}
                            sx={{ width: 300 }}
                            renderInput={
                                (params) =>
                                    <TextField {...params} label="Agente" />
                            }
                        />
                    </div >
                    <div id="Content" >
                        <Box sx={{ width: '100%' }}>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid container
                                    xs={10}
                                    md={10}
                                    lg={5}
                                    xl={2.5}
                                    paddingLeft={3}
                                    paddingTop={3}>
                                    <DashCards
                                        title="Agentes Ativos"
                                        count="123"
                                        button="ver todos"
                                    />
                                </Grid>

                                <Grid container
                                    xs={10}
                                    md={10}
                                    lg={5}
                                    xl={2.5}
                                    paddingLeft={3}
                                    paddingTop={3}>
                                    <DashCards
                                        title="Agentes em Treinamento"
                                        count="123"
                                        button="ver todos"
                                    />
                                </Grid>

                                <Grid container
                                    xs={10}
                                    md={10}
                                    lg={5}
                                    xl={2.5}
                                    paddingLeft={3}
                                    paddingTop={3}>
                                    <DashCards
                                        title="Perguntas"
                                        count="123"
                                        button="ver todos"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </>
            }
            />
        </>
    )
}

export default Dashboard;