import { useState } from 'react';
import { autoBatchEnhancer } from '@reduxjs/toolkit';
import YouTube from 'react-youtube';

interface YouTubeProps {
    url: string;
    width?: string;
    height?: string;
}
function YouTubePlayer(props: YouTubeProps) {
    const {url, width, height} = {...props}
    const videoId = url?.split('=')[1] ?? '';
    let opts = {
      height: height || '200',
      width: width || '400'};

    return (
      <div className="player-wrapper">
        <YouTube
          videoId={videoId}
          opts={opts}
          style={{
             top: 0,
             margin: 'auto',
             padding: '1% 1% 1% 8%',
          //   width: '100%',
          //   height: '100%',
             aspectRatio: '16/9',
             borderRadius: '8px'
          }}
        />
      </div>
    );
  }
  
  export default YouTubePlayer;