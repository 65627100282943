import axios, {AxiosResponse, AxiosError} from 'axios';
import { axiosConfig } from './helper';

export default class Requester {
    static async requestBuilder( 
        endPoint: string,
        method: 'get' | 'post' | 'patch' | 'put' | 'delete',
        id='',
        body = {}) {
        let response: AxiosResponse;
        console.log( "Request Data is: ", id, body, method, endPoint  );
        try{
            if( id.length > 0){
                if( Object.keys(body).length > 0 )
                    response = await axios[method](`${endPoint}/${id}`, body, axiosConfig( ));
                else
                    response = await axios[method](`${endPoint}/${id}`, axiosConfig( ));}
            else
                if( Object.keys(body).length > 0 )
                    response = await axios[method](endPoint,  body, axiosConfig( ));
                else
                    response = await axios[method](endPoint,  axiosConfig( ));
            return response.data;
        } catch ( e ) {
            console.log( e );
        }
    }
}
