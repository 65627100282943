import { Link, useLocation } from "react-router-dom";
import { Container, Content } from "./style";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Grid } from "@mui/material";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
export function Navbar() {
    const { user, jwtUser, signOut } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    const handleSignOut = () => {
        signOut();
        navigate('/login');
    }

    return (
        <Grid item xs={12} className="row">
            <Container>
                <Content>
                    <img src='https://images-front-soukw.s3.amazonaws.com/KWwhite.svg'
                        className='navbar-logo'
                    />
                </Content>
                <Link to='https://soukw.com.br/command'
                    className='navbar-item'
                    style={{
                        color: location.pathname.includes('/command')
                            ? 'white'
                            : 'gray'
                    }}>
                    Command
                </Link>
                <Link to='https://soukw.com.br/soukw'
                    className='navbar-item'
                    style={{
                        color: location.pathname.includes('/soukw')
                            ? 'white'
                            : 'gray'
                    }}>
                    SouKW
                </Link>

                <Link to='https://soukw.com.br/pipeimob'
                    className='navbar-item'
                    style={{
                        color: location.pathname.includes('/pipeimob')
                            ? 'white'
                            : 'gray'
                    }}>
                    PipeImob
                </Link>
                <Link to='https://soukw.com.br/parcerias'
                    className='navbar-item'
                    style={{
                        color: location.pathname.includes('/parcerias')
                            ? 'white'
                            : 'gray'
                    }}>
                    Parcerias
                </Link>
                <div className='navbar-item'>
                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                        <img
                            src={user?.photo}
                            width='40px'
                            height='40px'
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '290px',
                                objectFit: 'cover',
                                borderRadius: 200 / 2
                            }}
                        />
                        <span
                            className='navbar-item'
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '2px',
                                color: 'lightgray'
                            }}
                        >
                            {user?.fullName}
                            <ExitToAppIcon onClick={handleSignOut} sx={{ margin: '0px 0px -6px 12px' }}/>
                        </span>

{/* {                        <a
                            href='https://app.soukw.com.br/negocios/kwsp?perfil=abrir'
                            target='_self'
                            className='navbar-item'
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '2px',
                                color: 'lightgray'
                            }}
                        >
                            {user?.fullName}
                            <ExitToAppIcon onClick={handleSignOut} sx={{ margin: '0px 0px -6px 12px' }}/>
                        </a> */
                        }

                    </div>
                </div>
            </Container>
        </Grid>
    )
}