import axios from "axios";
import GridListings from "../../shared/components/Grids/GridListings";
import { ButtonFilter } from "./style";
import TuneIcon from '@mui/icons-material/Tune';
import { useEffect, useState } from "react";
import Loading from "../../shared/components/Loading";
import { Divider, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { PhotoListings } from "../../shared/components/Grids/style";
import maskCurrency from "../../shared/enums/MaskCurrency";
import { Navbar, Sidebar } from "../../shared/components";
import { token } from "../../shared/services/api";

interface PreListing {
    _id: string;
    list_address: {
        street_name: string;
        street_number: string;
        city: string;
    };
    current_list_price: string;
    list_kw_uid: string;
    list_status: string;
    market_center: string;
    teamId: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    newListingAgentId: number;
}

interface User {
    kwuid: string;
    fullName: string;
    teamId: string;
    name: string;
}

interface Team {
    _id: string;
    name: string;
}

interface MC {
    kwId: string;
    name: string
}

const PrelistingsPage = () => {
    const [newListingCaptacaoOpen, setNewListingCaptacaoOpen] = useState(false);
    const [newListingModalOpen, setNewListingModalOpen] = useState(false);
    const [preListings, setPreListings] = useState<PreListing[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [mcs, setMcs] = useState<MC[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [filteringMode, setFilteringMode] = useState(false);
    const [enderecoFilter, setEnderecoFilter] = useState('');
    const [bairroFilter, setBairroFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [condominioFilter, setCondominioFilter] = useState('');
    const [incorporadoraFilter, setIncorporadoraFilter] = useState('');
    const [metragemFilter, setMetragemFilter] = useState([1, 1000]);
    const [valorFilter, setValorFilter] = useState([1000, 50000000]);
    const [quartosFilter, setQuartosFilter] = useState(1);
    const [banheirosFilter, setBanheirosFilter] = useState(1);
    const [vagasFilter, setVagasFilter] = useState(0);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("page: ", page)
        console.log('filters: ', filters)
        async function getData() {
            setLoading(true);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                //changed here
                let url = `https://soukw.com.br:3000/preListings/my?page=${page}`
                // if (filters.city)
                //     url = url + '&city=' + filters.city
                // if (filters.bairro)
                //     url = url + '&neighbourhood=' + filters.bairro
                // if (filters.address)
                //     url = url + '&address=' + filters.address
                // if (filters.condominio)
                //     url = url + '&condominio=' + filters.condominio
                // if (filters.construtora)
                //     url = url + '&construtora=' + filters.construtora
                // if (filters.valorMin && filters.valorMin !== 1000)
                //     url = url + '&valorMin=' + filters.valorMin
                // if (filters.valorMax && filters.valorMax !== 50000000)
                //     url = url + '&valorMax=' + filters.valorMax
                // if (filters.metragemMin && filters.metragemMin !== 1)
                //     url = url + '&metragemMin=' + filters.metragemMin
                // if (filters.metragemMax && filters.metragemMax !== 1000)
                //     url = url + '&metragemMax=' + filters.metragemMax
                // if (filters.quartos && parseInt(filters.quartos) !== 1)
                //     url = url + '&quartos=' + filters.quartos
                // if (filters.banheiros && parseInt(filters.banheiros) !== 1)
                //     url = url + '&banheiros=' + filters.banheiros
                // if (filters.vagas && parseInt(filters.vagas) !== 0)
                //     url = url + '&vagas=' + filters.vagas

                console.log(url)
                let results = await axios.get(
                    url,
                    config
                );
                console.log(url)
                setPreListings(results.data.data.reverse());
                console.log(results.data.data.reverse());
                console.log(results.data);
                setTotal(results.data?.totalItems);
                console.log(results.data.totalItems);
                setTotalPages(results.data?.totalPages)
                console.log(results.data.totalPages);
                setPage(results.data?.page)
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                alert('Erro de Conexão.')

            }
        }
        getData();
        console.log(page)
    }, [filters, page]);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let users = await axios.get('https://soukw.com.br:3000/users', config)
                let teams = await axios.get('https://soukw.com.br:3000/teams', config)
                let mcs = await axios.get('https://soukw.com.br:3000/mcs', config)
                setUsers(users.data)
                console.log(users.data[0].teamId)
                setTeams(teams.data)
                setMcs(mcs.data)

            } catch (e) {
                console.error(e);
                alert('Erro de Conexão.');
            }
        }
        getData()
    }, [])

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <>
                    <h1>Listings Pré Cadastrados</h1>
                    <ButtonFilter>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <TuneIcon /> Filtro
                        </div>
                    </ButtonFilter>
                    <GridListings children={
                        loading ? <Loading /> :
                            preListings?.map((preListing, i) => {
                                console.log(preListing._id)
                                return (
                                    <Grid
                                        key={preListing._id}
                                        item xs={12}
                                    >
                                        <Divider style={{ width: '100%' }} />
                                        <div style={{
                                            width: '100%',
                                            backgroundColor: '#ffff'
                                        }}
                                        >
                                            <Link
                                                to={'/soukw/listings/editlistings/'}
                                                style={{ textDecoration: 'none', color: '#373f51' }}
                                            >
                                                <Grid container
                                                    justifyItems='flex-start'
                                                    alignItems={'center'}
                                                    style={{ cursor: 'pointer', paddingTop: '6px', paddingBottom: '6px' }}
                                                >
                                                    <Grid item lg={3.06} xs={1.8}>
                                                        <PhotoListings>
                                                            <img
                                                                style={{
                                                                    width: '120px',
                                                                    height: '70px',
                                                                    borderRadius: '6px',
                                                                    marginLeft: '6px'
                                                                }}
                                                                src="https://soukw.com.br/images/listings/16612279113861/main_16612578344618.jpg"
                                                            />
                                                            <span style={{
                                                                marginTop: '12px',
                                                                marginLeft: '12px'
                                                            }}>
                                                                {preListing.list_address.street_name},
                                                                {preListing.list_address.street_number}
                                                                - {preListing.list_address.city}
                                                            </span>
                                                        </PhotoListings>

                                                    </Grid>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Grid item lg={1.2} xs={1.8} style={{ paddingLeft: '12px' }}>
                                                        {maskCurrency(preListing.current_list_price)}
                                                    </Grid>
                                                    <Grid item lg={1.5} xs={2.2}>
                                                        {preListing.newListingAgentId !== -1
                                                            ? users?.find(u => String(u.kwuid) === String(preListing.newListingAgentId))?.fullName
                                                            : users?.find(u => String(u.kwuid) === String(preListing.list_kw_uid))?.fullName
                                                        }
                                                    </Grid>
                                                    <Grid item lg={1.5} xs={1.5}>
                                                        {preListing.status}
                                                    </Grid>
                                                    <Grid item lg={1.4} xs={1}>
                                                        {mcs?.find(mc => String(mc.kwId) ===
                                                            String(preListing.market_center))?.name
                                                        }
                                                    </Grid>
                                                    <Grid item lg={1.2} xs={1}>
                                                        {teams?.find(team => String(team._id) ===
                                                            String(preListing.teamId))?.name
                                                        }
                                                    </Grid>
                                                    <Grid item lg={1} xs={1.2}>
                                                        {
                                                            new Date(preListing.createdAt)
                                                                .toLocaleDateString('pt-br')
                                                        }
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {
                                                            new Date(preListing.updatedAt)
                                                                .toLocaleDateString('pt-br')
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        </div>
                                        <Divider style={{ width: '100%' }} />
                                    </Grid >
                                );
                            })
                    } />
                </>
            } />
        </>
    );
}

export default PrelistingsPage;