import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material';
import db from './models/ListingsDB';
import IntegracaoPortaisService from '../../shared/services/api/integracaoPortais';

interface SaveXMLModalProps {
  open: boolean;
  fileName: string;
  onClose: () => void;
  // xmlFiles: any[];
}



const SaveXMLModal: React.FC<SaveXMLModalProps> = ({
  open,
  fileName,
  onClose}) => {
  const [newFileName, setNewFileName] = useState('');

  const handleSaveXML = async ( ) => {
    const listings = await db.listings.filter( el => el.inXML ?? false ).toArray( )
    const listingIds = listings.map( el => el._id )
    const anuncios = listings.map( el => el.integracaoNaventAgente.filter( i => i.fileName === fileName )[0] )
    IntegracaoPortaisService.saveNaventXML( listingIds, anuncios)
    console.log( "Selected Listings are: ", listings )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Gerenciar arquivos XML</Typography>
        <Divider sx={{ my: 2 }} />
        
        <Box mb={4}>
          <Button variant="contained" color="success" onClick={handleSaveXML}>Gerar/Atualizar XML</Button>
          <Button color="secondary" onClick={onClose}>Cancelar</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveXMLModal;
