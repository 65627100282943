import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import React, { useContext } from 'react';
import AppRoutes from './routes/index';
import AuthContext from './shared/context/AuthContext';
import './App.css';



const theme = createTheme({
  palette: {
    primary: {
      main: "#373f51",
    },
    secondary: { main: "#0099a7" },
  },
});

function App() {

  const {
    user,
    jwtUser,
    jwtToken,
    authLoading,
    authError,
    setPrevUser,
    setPrevJwtUser,
    setPrevJwtToken,
    signIn,
    signOut, } = useContext(AuthContext)

  return (
    <div className='app'>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
    </div>
  );
}

export default App;
