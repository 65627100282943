import { Divider, Grid } from "@mui/material";
import { Navbar, Sidebar } from "../../../shared/components";
import GridTeams from "../../../shared/components/Grids/GridTeams";
import MenuAdmin from "../../../shared/components/Menu/MenuAdmin";
import { ButtonTeam, Column, Row, Title } from "../style";
import { Link } from "react-router-dom";
import { useState } from "react";

interface Team {
    name: string;
    marketCenterId: string;
    updatedAt: string;

}

interface MC {
    _id: string;
    name: string;
    kwId: string;
    regionId: string;
    updatedAt: string;
}

const TeamsPage = () => {
    const [teams, setTeams] = useState<Team[]>([]);
    const [mcs, setMcs] = useState<MC[]>([]);

    return (
        <>
            <Navbar />
            <MenuAdmin />
            <Sidebar Children={
                <Column style={{ marginTop: '36px' }}>
                    <Row>
                        <Title>Times...</Title>
                        <ButtonTeam>Cadastrar Time+</ButtonTeam>
                    </Row>
                    <GridTeams children={
                        teams?.map(team => {
                            return (
                                <Grid item xs={12}>
                                    <Divider style={{ width: '100%' }} />
                                    <div style={{ width: '100%', padding: '5px' }}>
                                        <Link to={'/admin/edit-mc/'} style={{ textDecoration: 'none', color: '#565e70' }}>
                                            <Grid container
                                                justifyItems='flex-start'
                                                textAlign={'start'}
                                                style={{
                                                    cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px'
                                                }}
                                            >
                                                <Grid item lg={2.5} xs={2.5}>
                                                    {team.name}
                                                </Grid>
                                                <Grid item lg={2.5} xs={2.6}>
                                                    {mcs?.find(mc => mc._id === team.marketCenterId)?.name}
                                                </Grid>
                                                <Grid item lg={2.5} xs={2.6}>
                                                    {new Date(team.updatedAt).toLocaleDateString('pt-br')}
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </div>
                                    <Divider style={{ width: '100%' }} />
                                </Grid >)
                        })
                    } />
                </Column>
            } />
        </>
    )
}

export default TeamsPage;