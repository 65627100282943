import { Grid } from "@mui/material";
import { GridHome } from "../../shared/components/Grids/GridHome";
import { GridNews } from "../../shared/components/Grids/GridNews";
import { useLocation } from "react-router-dom";
import { ResponsiveScreen } from "../../shared/components/Grids/style";
import { Navbar, Sidebar } from "../../shared/components";
import AuthContext from '../../shared/context/AuthContext';
import { useContext, useState } from'react';
import { JwtUserI, UserI, token } from '../../shared/context/authInterfaces';


export default function HomePage() {
    const location = useLocation();

    const {         
        user,
        jwtUser,
        jwtToken,
        signIn,
        signOut,
        authLoading,
        authError,
    } = useContext(AuthContext)


    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Grid container style={{ height: '100%', width: '100vh' }} justifyContent="center">
                    { user &&
                    <Grid style={{ padding: 0, margin: 0 }} >
                        <ResponsiveScreen>
                            <h1>Bem Vindo, {user.fullName}!</h1>
                        </ResponsiveScreen>
                        <ResponsiveScreen>
                            <GridHome />
                            <div><br /></div>
                            <GridNews />
                        </ResponsiveScreen>
                    </Grid>}
                </Grid>
            } />
        </>


    )
}