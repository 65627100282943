import styled from "@emotion/styled";

const DarkButtonStyle = styled.button`
font-size: 18px;
color: #FFFFFF;
border: transparent;
border-radius: 25px;
background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1)) ;
cursor: pointer;
`

const LightButtonStyle = styled.button`
font-size: 18px;
color: #1C5BBD;
border: 1px solid rgba(24,133,157,0.9);
border-radius: 25px;
background-color: transparent;
cursor: pointer;
`

export {
    DarkButtonStyle,
    LightButtonStyle
}