import { Link } from "react-router-dom";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { Menu, MenuHeader, MenuText } from "./style";
import { Tooltip } from "antd";
import { ReactNode, useContext } from "react";
import AuthContext from "../../context/AuthContext";


interface soukwProps {
    pageTitle: string;
    route: string;
    children: ReactNode;
    selected?: string;
}

interface MenuButtonI {
    page: string;
    title: string;
    route: string;
    icon: string;
}

const MenuTraining = () => {
    const { jwtUser, user } = useContext(AuthContext)

    const Location: React.FC<soukwProps> = (props) => {
        return (
            <Tooltip title={props.pageTitle} placement="top">
                <Link to={props.route}>
                    <div className={props.selected} style={{ display: 'flex', flexDirection: 'row' }}>
                        {props.children}
                    </div>
                </Link>
            </Tooltip>
        )
    };

    return (
        <Menu>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MenuHeader>
                    <Location pageTitle="Tecnologia" route="/soukw/training/system" selected=".menu-trainig" children={
                        <>
                            <SchoolOutlinedIcon
                                color={'action'}
                                sx={{ width: '28px', height: '28px' }}
                                style={{ marginTop: '1.2rem' }}
                            />
                            <MenuText>Tecnologia</MenuText>
                        </>
                    } />

                    <Location pageTitle="Agente" route="/soukw/training/agent" selected="" children={
                        <>
                            <PersonOutlineOutlinedIcon
                                color={'action'}
                                sx={{ width: '28px', height: '28px' }}
                                style={{ marginTop: '1.2rem' }}
                            />
                            <MenuText> Agente </MenuText>
                        </>
                    } />
                    {   (user?.permissionLevelTreinamentos === "LEADERSHIP" || 
                        user?.permissionLevelTreinamentos === "EDITOR") && 
                        <Location pageTitle="Liderança" route="/soukw/training/leadership" selected="" children={
                            <>
                                <PersonPinOutlinedIcon
                                    color={'action'}
                                    sx={{ width: '28px', height: '28px' }}

                                    style={{ marginTop: '1.2rem' }}
                                />
                                <MenuText> Liderança </MenuText>
                            </>
                        } />
                    }
                    {   user?.permissionLevelTreinamentos === "EDITOR" && 
                        <Location pageTitle="Editar Cursos" route="/soukw/training/edit-courses" selected="" children={
                            <>
                                <PersonPinOutlinedIcon
                                    color={'action'}
                                    sx={{ width: '28px', height: '28px' }}

                                    style={{ marginTop: '1.2rem' }}
                                />
                                <MenuText>Editar Cursos</MenuText>
                            </>
                        } /> }

                    {   user?.permissionLevelTreinamentos === "EDITOR" && 
                        <Location pageTitle="Editar Treinamentos" route="/soukw/training/edit-trainings" selected="" children={
                            <>
                                <PersonPinOutlinedIcon
                                    color={'action'}
                                    sx={{ width: '28px', height: '28px' }}

                                    style={{ marginTop: '1.2rem' }}
                                />
                                <MenuText>Editar Treinamentos</MenuText>
                            </>
                        } /> }
                        {   user?.permissionLevelTreinamentos === "EDITOR" && 
                        <Location pageTitle="Editar Treinamentos" route="/soukw/training/edit-rails" selected="" children={
                            <>
                                <PersonPinOutlinedIcon
                                    color={'action'}
                                    sx={{ width: '28px', height: '28px' }}

                                    style={{ marginTop: '1.2rem' }}
                                />
                                <MenuText>Editar Trilhas</MenuText>
                            </>
                        } /> }


                </MenuHeader>
            </div>
        </Menu>
    )

}

export default MenuTraining;