import { Box, Grid } from "@mui/material";
import { CardTraining, Content, Header } from "./style";
import { Link } from "react-router-dom";
import React from "react";

interface CollectionProps {
    title: string;
    subtitle: string;
    description: string;
    navigate: string;
    
}

const CardsCollections: React.FC<CollectionProps> = (props) => {
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid
                    container
                    paddingLeft={1}
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Link to={props.navigate}>
                        <CardTraining>
                            <Header>
                                <img style={{ height: '86px', width: '86px' }}
                                    src="https://images-front-soukw.s3.amazonaws.com/logoTraining.png"
                                />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h1 style={{ fontSize: '20px', color: '#000', textTransform: 'capitalize', margin: 0 }}>
                                        {/* Sistemas Integrados */}
                                        {props.title}
                                    </h1>
                                    <p style={{ fontSize: '14px', color: '#000', textTransform: 'capitalize', margin: 0 }}>
                                        {/* command, soukw, pipeimob */}
                                        {props.subtitle}
                                    </p>
                                </div>
                            </Header>
                            <Content>
                                <div>
                                    <p style={{ fontSize: '12px', color: '#000', textTransform: 'capitalize', margin: 0 }}>
                                        {/* command, soukw, pipeimob */}
                                        {props.description}
                                    </p>
                                </div>
                            </Content>
                        </CardTraining>
                    </Link>
                </Grid>
            </Box>


        </>
    )

}

export default CardsCollections;