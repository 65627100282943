import { useEffect, useState } from 'react';
import { Grid, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from "react-router-dom";
import { Navbar, Sidebar } from "../../../shared/components";
import { BackButton, Row } from "../style";
import CardClasses from "../../../shared/components/Cards/CardClasses";
import MoreClasses from "../../../shared/components/Cards/MoreClasses";
import TrainingService from '../../../shared/services/api/trainingService';
import YouTubePlayer from "./YouTubePlayer";
const LessonPageTemp = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [course, setCourse] = useState({
        _id: '',
        title: '',
        subtitle: '',
        cover: '',
        chapters: [],
        description: '',
        url: '',
        extras: [{
            type: '',
            url: ''
        }],
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            
            try {
                setLoading(true);
                const response = await TrainingService.getTreinamentos(id)
                console.log(response)
                setCourse(response);
            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    return (
        <>
            <Navbar />

            <Sidebar Children={
                <Grid>
                    <div>
                        <BackButton style={{ margin: '0px 0px 6px -18px' }} onClick={() => navigate(-1)}>
                            <ArrowBackIosIcon sx={{ margin: '0px -6px -6px 0px', }} />
                            Voltar
                        </BackButton>
                    </div>
                    {
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {loading && <CircularProgress />}
                        </div>
                    }
                    {!loading &&
                        <Row>

                            <CardClasses
                                data=""
                                description={ course.description ?? '' }
                                subtitle={course.subtitle ?? ''}
                                title={course.title ?? ''}
                                url={course.url ?? ''}
                                urlPdf={course.extras.length > 0 ? course.extras[0].url : ''}
                            />
                            <div style={{ width: '1rem' }} />
                            <div style={{width: '30%'}}></div>
                            {/* <MoreClasses
                                title={`${course.title}, aulas a seguir`}
                                navigate=''
                                nextClasse=''
                            /> */}
                        </Row>
                        
                    }


                </Grid>

            } />
        </>
    )

}

export default LessonPageTemp;