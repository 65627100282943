import axios from "axios";
import { USERS_ENDPOINT } from "./Constants";
import { axiosConfig } from "./helper";
export default class UsersService {

    static async getUsers() {
        const response = await axios.get(USERS_ENDPOINT, axiosConfig() );
        return response.data;
    }

    static async getUser(id: number) {
        const response = await axios.get(`${USERS_ENDPOINT}/${id}`, axiosConfig());
        return response.data;
    }

    static async createUser(user: any) {
        const response = await axios.post(USERS_ENDPOINT, user, axiosConfig());
        return response.data;
    }

    static async updateUser(id: number, user: any) {
        const response = await axios.patch(`${USERS_ENDPOINT}/${id}`, user, axiosConfig());
        return response.data;
    }

    static async deleteUser(id: number) {
        const response = await axios.delete(`${USERS_ENDPOINT}/${id}`, axiosConfig());
        return response.data;
    }

    static async getTotalUsers() {
        const response = await axios.get(USERS_ENDPOINT, axiosConfig());
        console.log(response.data.length);
        return response.data.length;
    }
}
