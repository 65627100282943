import axios from "axios";
import { UPDATE_ENDPOINT } from "./Constants";
import { axiosConfig } from "./helper";
export default class UpdatesService {

    static async getUpdates() {
        const response = await axios.get(UPDATE_ENDPOINT, axiosConfig( ));
        return response.data;
    }

    static async getUpdate(id: number) {
        const response = await axios.get(`${UPDATE_ENDPOINT}/${id}`);
        return response.data;
    }

    static async createUpdate(update: any) {
        const response = await axios.post(UPDATE_ENDPOINT, update);
        return response.data;
    }

    static async updateUpdate(id: number, update: any) {
        const response = await axios.patch(`${UPDATE_ENDPOINT}/${id}`, update);
        return response.data;
    }

    static async deleteUpdate(id: number) {
        const response = await axios.delete(`${UPDATE_ENDPOINT}/${id}`);
        return response.data;
    }

    static async getTotalUpdates() {
        const response = await axios.get(UPDATE_ENDPOINT);
        console.log(response.data.length);
        return response.data.length;
    }
}
