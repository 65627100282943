import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Grid,
    Stack,
    Container,
    Box,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    } from '@mui/material';
import styled from "@emotion/styled";


import TrainingService from "../../../shared/services/api/trainingService";
import BackdropTimedAlert from "./BackdropTimedAlert";

const LearnEntityCard = ( props: LearnEntityI & {index: number} ) => {
    const {title, subtitle, description, role, cover='', type, published, showInHome, _id='', index} = props;
    return (
        <Draggable draggableId={_id} index={index}>
            {(provided) => (
                <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                >
                <Card sx={{maxWidth: '200px'}}>
                { cover.length > 0 &&
                <CardMedia
                    sx={{ height: 140 }}
                    image={cover}
                />}
                <CardHeader
                    title={title}
                    subheader={subtitle}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>
                </div>
            )}

        </Draggable>
    )

}
const LearnEntityList = ( {learnItems} : {learnItems: LearnEntityI[]} ) => {
    return <Stack spacing={2}>
        {
            learnItems.map((item, index) => {
                return <LearnEntityCard key={item._id} {...item} index={index}/>
            })
        }
    </Stack>
}

const ModalColumn = ( {columnId, title, items}: {columnId: string, title: string, items: LearnEntityI[]} ) => {
    return <Grid item minHeight={'200px'} xs={3} sm={3} md={3} lg={3} xl={3} sx={{'overflow-y': 'auto', 'maxHeight': '500px', 'minHeight': '200px'}}>
        <h2>{title}</h2>
        <Droppable droppableId={columnId}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                        background: snapshot.isDraggingOver? 'lightblue' : 'white',
                        
                    }}
                >
                { items && items.length > 0 ?  items.map((item, index) => {
                    console.log("Each item is: ", item);
                    if (item)
                        return <LearnEntityCard {...item} index={index} key={item._id} />
                }) : <div>Sem items</div>	
                }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </Grid>
}

const ModalContainer = ( {children}: {children: React.ReactNode} ) => {
    return <Grid container spacing={2}>
        {children}
    </Grid>
}


interface ModalLessonsPropsI {
    isOpen: boolean;
    fClose: ( ) => void;
    lessonsEntityType?: string;
    lessonId: string;
    modalSelectionConfType?: 'course' | 'training' | 'rails';
}

interface LearnEntityI {
    _id?: string;
    title: string;
    subtitle: string;
    description: string;
    role: string;
    type: string;
    published: boolean;
    showInHome: boolean;
    cover: string;
    entityType: string;
}

const ModalSetLessons = ( props: ModalLessonsPropsI ) => {

    const {isOpen, fClose, lessonsEntityType = 'course', lessonId} = {... props };
    const [open, setOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [actualLearningEntity, setActualLearningEntity] = useState<LearnEntityI>(
        {
        _id: '',
        title: '',
        subtitle: '',
        description: '',
        role: '',
        type: '',
        published: false,
        showInHome: false,
        cover: '',
        entityType: ''
    });
    const [availableTrainings, setAvailableTrainings] = useState<LearnEntityI[]>([]);
    const [availableCourses, setAvailableCourses] = useState<LearnEntityI[]>([]);
    const [availableRails, setAvailableRails] = useState<LearnEntityI[]>([]);
    const [myTrainings, setMyTrainings] = useState<LearnEntityI[]>([]);
    const [myCourses, setMyCourses] = useState<LearnEntityI[]>([]);
    const [myRails, setMyRails] = useState<LearnEntityI[]>([]);
    const [myRelations, setMyRelations] = useState<any[]>([]);

    const onDragEnd = (result: DropResult ) => {
        console.log("Dragging ended: ", result);

        //Se ação é cancelada, não faz nada
        const destination = result.destination;
        const source = result.source;

        if (!destination) {
            return;
        }


        if( destination.droppableId !== 'learnEntityElements' && source.droppableId === 'learnEntityElements' ) {
            const newMyRelations = [...myRelations];
            const selectedRelation = myRelations[source.index];
            newMyRelations.splice( source.index, 1 );
            setMyRelations( newMyRelations );
            switch( destination.droppableId ) {
                case 'availableTrainings':
                    const newAvailableTrainings = [...availableTrainings];
                    newAvailableTrainings.splice( destination.index, 0, selectedRelation );
                    setAvailableTrainings( newAvailableTrainings );
                    break;
                case 'availableCourses':
                    const newAvailableCourses = [...availableCourses];
                    newAvailableCourses.splice( destination.index, 0, selectedRelation );
                    setAvailableCourses( newAvailableCourses );
                    break;
                case 'availableRails':
                    const newAvailableRails = [...availableRails];
                    newAvailableRails.splice( destination.index, 0, selectedRelation );
                    setAvailableRails( newAvailableRails );
                    break;
            }
            return;
        }

        //Se o destino é invalido, não faz nada
        if( destination.droppableId !== 'learnEntityElements' ) {
            return;
        }

        //Se o destino é o mesmo, não faz nada
        if( destination.droppableId === source.droppableId && destination.index === source.index ) {
            return;
        }


        const newMyRelations = [...myRelations];
        switch( source.droppableId ) {
            case 'availableTrainings':
                const newAvailableTrainings = [...availableTrainings];
                const selectedTraining = availableTrainings[source.index];
                newMyRelations.splice( destination.index, 0, selectedTraining );
                setMyRelations( newMyRelations );
                newAvailableTrainings.splice( source.index, 1 );
                setAvailableTrainings( newAvailableTrainings );
                break;
            case 'availableCourses':
                const newAvailableCourses = [...availableCourses];
                const selectedCourses = availableCourses[source.index];
                newMyRelations.splice( destination.index, 0, selectedCourses );
                setMyRelations( newMyRelations );
                newAvailableCourses.splice( source.index, 1 );
                //newAvailableCourses.splice( destination.index, 0, availableCourses[source.index] );
                setAvailableCourses( newAvailableCourses );
                break;
            case 'availableRails':
                const newAvailableRails = [...availableRails];
                const selectedRails = availableRails[source.index];
                newMyRelations.splice( destination.index, 0, selectedRails );
                setMyRelations( newMyRelations );
                newAvailableRails.splice( source.index, 1 );
                //newAvailableRails.splice( destination.index, 0, availableRails[source.index] );
                setAvailableRails( newAvailableRails );
                break;
            case 'learnEntityElements':
                const selectedEntity = myRelations[source.index];
                newMyRelations.splice( source.index, 1 );
                newMyRelations.splice( destination.index, 0, selectedEntity );
                setMyRelations( newMyRelations );
                break;
        }



    }

    const save = async ( ) => {

        try {

            const learningEntity = await TrainingService.updateLesson(
                {...actualLearningEntity, relations: myRelations.map( (item: any) => {
                    return {
                        id: item._id,
                    }
                })},
            );
            setActualLearningEntity( learningEntity );

            
        } catch (error)  {
            console.log(error);
            alert("Ocorreu um erro ao salvar os dados");
        } finally {
            setLoadingData( false );
            fClose();
        }
    }

    

    useEffect(() => {
        async function loadData() {
            setLoadingData( true );
            console.log("Loading data");
            try {
                const me = await TrainingService.getLearnEntity( lessonId );
                me.relations = me.relations.map( (el:any) => {
                    return {...el.id}});
                console.log( me.relations )
                setActualLearningEntity( me );
                const allTrainings = await TrainingService.getTreinamentos();
                const allCourses = await TrainingService.getLesson();
                const allRails = await TrainingService.getCollections();
                console.log("All courses: ", allCourses);
                setMyRelations( me.relations );
                setMyCourses( me.relations.filter( (item: any) => item.type === 'course' ) );
                setMyRails( me.relations.filter( (item: any) => item.type === 'rail' ) );
                setMyTrainings( me.relations.filter( (item: any) => item.type === 'training' ) );
                setAvailableTrainings( allTrainings.filter( (el: any) => {
                    return !me.relations.find( 
                        (item: any) => item._id === el._id );
                }) );
                setAvailableCourses( allCourses.filter( (el: any) => {
                    return !me.relations.find( (item: any) => {
                        return item._id === el._id
                    } );
                }) );
                setAvailableRails( allRails.filter( (el: any) => {
                    return !me.relations.find( (item: any) => item._id === el._id );
                }) );
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingData( false );
            }

        }
        loadData();
    }, [])

    return(
        <Dialog open={isOpen} onClose={() => setOpen(false)} fullScreen>
            <DialogTitle >
                Configure as Aulas e Relações
            </DialogTitle>
            <DialogContent>
                
                <DialogContentText>
                    Configure as Aulas e Relações
                    {
                        loadingData ?
                        <div>
                            <h3>Carregando Lista de Cursos</h3>
                            <CircularProgress />
                        </div> :
                        <DragDropContext onDragEnd={onDragEnd}>
                            <ModalContainer>

                                <ModalColumn 
                                    columnId="learnEntityElements" 
                                    title="Elementos Selecionados"
                                    items={myRelations} />

                                {actualLearningEntity.entityType === 'RAILS' && <ModalColumn
                                    title="Trilhas disponíveis"
                                    columnId="availableRails"
                                    items={availableRails}/>}

                                <ModalColumn 
                                    title="Treinamentos Disponiveis"
                                    columnId="availableTrainings"
                                    items={availableTrainings} />
                     
                                <ModalColumn 
                                    title="Cursos Disponíveis"
                                    columnId="availableCourses"
                                    items={availableCourses} />
         
                            </ModalContainer>
                        </DragDropContext>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={() => fClose()}>Cancelar</button>
                <button onClick={() => save()}>Salvar</button>
            </DialogActions>

        </Dialog>
    )
}

export default ModalSetLessons;