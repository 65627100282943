import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IIntegracaoPortaisDocument, Listing } from './models/ListingsInterfaces';
import db from './models/ListingsDB';
import { useLiveQuery } from "dexie-react-hooks";

interface ManageXMLFilesModalProps {
  open: boolean;
  onClose: () => void;
  // xmlFiles: any[];
  onCreate: (fileName: string) => void;
  onDelete: (fileName: string) => void;
  onSelect: (fileName: string) => void;
}



const ManageXMLFilesModal: React.FC<ManageXMLFilesModalProps> = ({
  open,
  onClose,
  // xmlFiles,
  onCreate,
  onDelete,
  onSelect
}) => {
  const [newFileName, setNewFileName] = useState('');

  const files = useLiveQuery( async ( ) => {
    let filesQuerie = await db.listings
      .filter( el => el.integracaoNaventMC.length > 0);
  
    const listingsArray = await filesQuerie.toArray( )
    let fileList = listingsArray.map( 
      el => el.integracaoNaventMC.map( item => item.fileName )
    ).flat()
  
    //we need a list of unique files
    const uniqueFileList = [];
    while( fileList.length > 0) {
      const file = fileList.pop( );
      if( file && ! (uniqueFileList.indexOf( file ) > -1) )
        uniqueFileList.push( file.toLocaleLowerCase() );
    }
    return uniqueFileList.sort( );
      
  })

  const handleCreate = () => {
    if (newFileName.trim().toLocaleLowerCase() !== '') {
      onCreate(newFileName);
      setNewFileName('');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Gerenciar arquivos XML</Typography>
        <Divider sx={{ my: 2 }} />
        
        <Box mb={2}>
          <TextField
            label="New XML File Name"
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleCreate} fullWidth sx={{ mt: 2 }}>
            Create XML File
          </Button>
        </Box>

        <Typography variant="body1">Arquivos Existentes</Typography>
        <List>
          {files && files.map((file, index) => (
            <ListItem key={index} onClick={() => onSelect(file)}>
              <ListItemText primary={file} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => onDelete(file)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default ManageXMLFilesModal;
