import styled from "@emotion/styled";

export const Container = styled.div`
background-color: #282b33;
height: 64px;
width: 100%;
`;

export const Content = styled.div`
width: 75px;
margin-right: 1.25rem;
height: 100%;
background-color: #ce011f;
text-align: center;
vertical-align: middle;
display: flex;
align-items: center;
display: inline-block;
cursor: pointer;
`;

export const TextButton = styled.button`
font-size: medium;
margin-right: 12px;
color: #FFFFFF;
border: 'none';
background-color: transparent;
padding-top: 18px;
border: transparent;
cursor: pointer;
`;

export const Profile = styled.div`

`;