import styled from "@emotion/styled";

export const Title = styled.p`
font-size: large;
font-weight: bold;
margin:12px 0px 0px 12px; 
`

export const Text = styled.p`
font-size: medium;
font-weight: lighter;
margin:12px 0px 12px 12px; 
`;

export const TextField = styled.input`
font-size: medium;
font-weight: lighter;
height: 2.5rem;
border-radius: 6px;
border: 1px solid rgba(111, 117, 133, 0.3); 
margin: 2rem 12px 1.5rem 12px;
padding: 0px 0px 0px 6px;
:hover {
    border: 1px solid rgba(111, 117, 133, 3); 
}
`;

export const SelectField = styled.select`
font-size: medium;
font-weight: lighter;
height: 2.5rem;
border-radius: 6px;
border: 1px solid rgba(111, 117, 133, 0.3); 
background: transparent;
margin: 0px 12px 1.5rem 12px;
padding: 0px 0px 0px 6px;
:hover {
    border: 1px solid rgba(111, 117, 133, 3); 
}
`;

export const CreateButton = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 10rem;
    color: #ffff;
    border: 0.1px solid transparent;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin-top: 22px;
    margin-left: calc(50% - 24px);
`;

export const Column = styled.div`
display: flex;
flex-direction: column;
`;

export const Row = styled.div`
display: flex;
flex-direction: row;
`;

export const Card = styled.div`
box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
background: #ffffff;
border-radius: 6px;
width: 32rem;
margin: 6px 0px 6px 0px; 
`;

export const DragCard = styled.div`
box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
background: #ffffff;
border-radius: 6px;
width: 30rem;
height: 2rem;
margin-left: 12px; 
`