import styled from "@emotion/styled";

export const Container = styled.div`
margin: 0;
padding: 0;
width: 100%;
background-image: url('https://images-front-soukw.s3.amazonaws.com/bgSouKW.jpg');
`;

export const Card = styled.div`
width: 450px;
height: 550px;
display: 'flex';
background: rgba(193, 192, 192, 0.1);
flex-direction: 'row';
align-items: center;
border-radius: 15px;
`;

export const Content = styled.form`
margin-left: 35px;
margin-right: 35px;
`;

export const Title = styled.h1`
font-size: 25px;
color: #FFFFFF;
opacity: 1;
`;

export const Text = styled.p`
font-size: 14px;
color: #FFFFFF;
opacity: 1;
padding-bottom: 12px;
`;

export const InputText = styled.input`
width: 370px;
height: 35px;
border-radius: 4px;
color: #FFFFFF;
padding-left: 6px;
font-size: 14px;
border: transparent;
background-color: rgba(255, 255, 255, 0.3);
outline: 0;
::placeholder{
    color: #FFFFFF;
    opacity: 0.3;
}
`;

export const ShowPassword = styled.button`
background-color: #72747A;
border-radius: 4px;
@media screen {
    max-width: 425px;
    top: 56%;
    left: 57.4%;
}
color: #FFFFFF;
opacity: 0.8;
border: 'none';
border: transparent;
cursor: pointer;
`;

export const LabelText = styled.label`
color: #FFFFFF;
font-size: 14px;
`;

export const Button = styled.button`
font-size: 18px;
color: #FFFFFF;
border: transparent;
border-radius: 25px;
margin-top: 12px;
height: 48px;
width: 380px;
text-align: center;
background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
cursor: pointer;
:hover{
    background-image: linear-gradient(to left, rgba(24,133,157,0.9), rgba(24,107,156,1));
}
`;

export const TextButton = styled.button`
color: #FFFFFF;
border: 'none';
background-color: transparent;
padding-top: 18px;
border: transparent;
cursor: pointer;
`;