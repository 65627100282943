import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ListingsStatus: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Status dos seus Listings</Typography>
        <Typography variant="body1">Ativos: 70</Typography>
        <Typography variant="body1">Pendentes: 20</Typography>
        <Typography variant="body1">Vendidos: 10</Typography>
      </CardContent>
    </Card>
  );
};

export default ListingsStatus;
