import { S3 } from 'aws-sdk';
import axios from 'axios';
import {S3_ENDPOINT} from "./Constants";
import { axiosConfig } from "./helper";

// const s3 = new S3({
//     region: "sa-east-1",
//     credentials: {
//       accessKeyId: "AKIAXXZA6KSH7GLZNQ4Z",
//       secretAccessKey: "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",
//     },
//   });

  export const getUploadUrl = async ( dir: string, fileName: string, fileType: string  ) => {
    //alert(`${dir}/${fileName}`)
    console.log(        { 
        key: `${fileName}`,
        bucket: 'eadsoukw',
        contentType: `${fileType}`,
        expires: 60 * 60
    })
    try {
     //   alert('Will Post')
        let response = await axios.post(`${S3_ENDPOINT}/createUploadSignedURL`, 
        { 
            key: `${dir}/${fileName}`,
            bucket: 'eadsoukw',
            contentType: `${fileType}`,
            expires: 60 * 60
        },
        axiosConfig( ));
        console.log("Url to upload: ", response)
        return response.data;
    } catch( err: any ) {
        console.log( err )
    } finally {
     //   alert('Ended')
    }
  }

  export const getMultipartUploadId = async (dir: string, fileName: string) => {
        //alert(`${dir}/${fileName}`)
        console.log(        { 
          key: `${fileName}`,
          bucket: 'eadsoukw',
      })
      try {
       //   alert('Will Post')
          let response = await axios.post(`${S3_ENDPOINT}/createMultipartUpload`, 
          { 
              key: `${dir}/${fileName}`,
              bucket: 'eadsoukw',
          },
          axiosConfig( ));
          console.log("Multpart Upload ID: ", response)
          return response.data;
      } catch( err: any ) {
          console.log( err )
      } finally {
       //   alert('Ended')
      }
  }

  export const getMultipartUploadURL = async ( dir: string, fileName: string, fileType: string,  ) => {
    //alert(`${dir}/${fileName}`)
    console.log(        { 
        key: `${fileName}`,
        bucket: 'eadsoukw',
        contentType: `${fileType}`,
        expires: 60 * 60
    })
    try {
     //   alert('Will Post')
        let response = await axios.post(`${S3_ENDPOINT}/createMultipartSignedUrl`, 
        { 
            key: `${dir}/${fileName}`,
            bucket: 'eadsoukw',
            contentType: `${fileType}`,
            expires: 60 * 60
        },
        axiosConfig( ));
        console.log("Url to upload: ", response)
        return response.data;
    } catch( err: any ) {
        console.log( err )
    } finally {
     //   alert('Ended')
    }
  }

  export const uploadPart = async ( ) => {

  }

  export const finishMultipartUpload = async ( ) => {

  }
  export const objectUrl = async ( bucket: string, key: string ): Promise<any> => {
    const urlResponse = await axios.post( `${S3_ENDPOINT}/getObjectUrl`, 
        { bucket, key },
        axiosConfig( ) );
    return urlResponse.data;
  }

  export const handleUpload = async ( file: File, signedUrl: string, getProgress: (progress: number)=>void) => {
    if (!file) return;

    try {
      // Get the pre-signed URL from the backend
      const options = {
        headers: {
          'Content-Type': file.type, // Set to 'video/mp4' if you are sure about the content type
        },
      };
      // Upload the file using the pre-signed URL
      //alert(`Uploading file...${file.type}`);
      console.log("Will upload to :", signedUrl)
      const result = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded) / (progressEvent.total || 100) * 100);
            console.log("Uploading: " + percentCompleted + "%");
            getProgress(percentCompleted);
          }
      });
      //alert('Upload successful!');
    } catch (error) {
      console.log( error );
    }
  };