import styled from "@emotion/styled";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  top: 64px;
  background: #FFF;
  width: 100%;
  height: 3rem;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const MenuText = styled.p`
    font-weight: normal;
    font-size: 16px;
    color: black;
    margin-left: 0.85rem;
    margin-right: 2.5rem;
    margin-top: 1.5rem;
`;

export const MenuHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 14.5rem;
`;