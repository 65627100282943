import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Grid, MenuItem } from '@mui/material';
import { IIntegracaoPortaisDocument, Listing } from './models/ListingsInterfaces';
import db from './models/ListingsDB';
import { LinkOffSharp } from '@mui/icons-material';

interface EditListingModalProps {
  open: boolean;
  onClose: () => void;
  listing: any;
  fileName: string;
}

/**Tipos de anúncio
 * “HOME” = Super destaque
“DESTACADO” = Destaque
“SIMPLE” = Simples
“GRATIS” = Promo - Subsimple
“ALQUILER_SIMPLE” = Alugar
 */

const addTypes: Record<string, string> = {
  HOME: 'Super Destaque',
  DESTACADO: "Destaque",
  SIMPLE: "Simples",
  GRATIS: "Promo - Subsimples",
  ALQUILER_SIMPLE: "Alugar"
}

const EditListingModal = ({ open, onClose, listing, fileName }: EditListingModalProps) => {
  const [formData, setFormData] = useState({
    neighborhood: listing?.neighborhood ?? '',
    planType: listing?.planType ?? '',
    title: listing?.title ?? '',
    description: listing?.description ?? '',
    fileName: fileName ?? '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log("Selected Value is: ", value)
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    /*
    * Se existir um listing específico o Anúncio será gerado apenas para esse listing.
    *   Se já houver um arquivo com o nome selecionado, atualiza o arquivo.
    *   Se não houver, cria o arquivo
    */
    if( listing ) {
      console.log( listing._id )
      const listingData = await db.listings.where( '_id' ).equals(listing._id).toArray( );
      console.log( listingData )
      if( listingData[0].integracaoNaventAgente.length > 0 ) {
        let fileAlreadyExists = listingData[0].integracaoNaventAgente.findIndex( el => el.fileName === fileName )
        if( fileAlreadyExists > -1 ) {
          listingData[0].integracaoNaventAgente[ fileAlreadyExists ] = {... formData, fileName };
        } else {
          listingData[0].integracaoNaventAgente.push( {...formData, fileName })
        }
      } else {
        listingData[0].integracaoNaventAgente.push({...formData, fileName})
      }
      await db.listings.where( '_id' ).equals( listing._id ).modify( {...listingData[0]} )
    } else {
      /**
       * Se não houver um listing específico, atualiza todos os lintings selecionados.
       * Se já houver um arquivo com o nome selecionado, atualiza o arquivo.
       *    Se não houver, cria o arquivo
       */
        const listingsToUpdate = await db.listings.filter( l => l.inXML === true ).toArray();
        for( let lst of listingsToUpdate ) {
          let existingFile;
          if( lst.integracaoNaventAgente ) {
            lst.integracaoNaventAgente = lst.integracaoNaventAgente.map( add => {
              if( add.fileName === fileName ) {
                return { ...formData, fileName };
              }
              return add;
            })
          }
          if( !lst.integracaoNaventAgente.some( l => l.fileName === fileName ) ) {
            lst.integracaoNaventAgente.push( {...formData, fileName})
          }
          await db.listings.where( '_id' ).equals( lst._id ).modify( { ...lst } );
        }
    }
    onClose();
    }


  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="neighborhood"
                label="Bairro comercial"
                value={formData.neighborhood}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="planType"
                label="Escolha o plano"
                select
                value={formData.planType}
                onChange={handleChange}
                fullWidth
              >
                {Object.keys(addTypes).map( (type, index) => {
                  console.log( type )
                  return <MenuItem key={index} value={type}>{`${addTypes[type]}`}</MenuItem>})}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Título do anúncio"
                value={formData.title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Descrição do anúncio"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default EditListingModal;
