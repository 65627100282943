import styled from "@emotion/styled";

export const Title = styled.p`
    font-size: 1.5rem;
    color: #000000;
`;

export const Container = styled.div``

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ButtonMC = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 10rem;
    color: #ffff;
    border: 0.1px solid #1C5BBD;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin-left: calc(56% - 26px);
`;

export const ButtonTeam = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 10rem;
    color: #ffff;
    border: 1px solid #1C5BBD;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin-left: calc(62% - 10px);
`;

export const ButtonUser = styled.button`
    font-size: 14px;
    height: 2.4rem;
    width: 10rem;
    color: #ffff;
    border: 1px solid #1C5BBD;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1));
    cursor: pointer;
    margin-left: calc(60% - 10px);
`;