import styled from "@emotion/styled";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  margin-top: 6px;
  margin-bottom: 26px;
  margin-right: 6px;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Card = styled.div`
  border-radius: 6px;
  background: #FFF;
  padding-top: 0;
  padding-bottom: 15px;
  width: 26rem;
  height: auto;
  margin-top: 6px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const Card2 = styled.div`
  border-radius: 6px;
  background: #FFF;
  padding-top: 0;
  padding-bottom: 15px;
  width: 68rem;
  height: auto;
  margin-top: 6px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const FilterButton = styled.button`
  font-size: 14px;
  height: 3.5rem;
  width: 10rem;
  margin-left: 6px;
  margin-top: 6px;
  color: #1C5BBD;
  border: 1px solid #1C5BBD;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
`;

export const BackButton = styled.button`
  font-size: 14px;
  height: 2rem;
  width: 10rem;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #1C5BBD;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
`;

export const AddButton = styled.button`
font-size: 14px;
  height: 3.5rem;
  width: 10rem;
  margin-left: 12px;
  margin-top: 6px;
  color: #FFF;
  border: 1px solid #1C5BBD;
  border-radius: 6px;
  background-color: #1C5BBD;
  cursor: pointer;
`;

export const AddButton2 = styled.button`
font-size: 14px;
  height: 3.5rem;
  width: 10rem;
  margin-left: calc(46% - 86px);
  margin-top: 16px;
  color: #FFF;
  border: 1px solid #1C5BBD;
  border-radius: 6px;
  background-color: #1C5BBD;
  cursor: pointer;
`;

export const RegisterButton = styled.button`
font-size: 14px;
  height: 3rem;
  width: 10rem;
  margin-top: 10px;
  color: #FFF;
  border: 1px solid #1C5BBD;
  border-radius: 6px;
  background-color: #1C5BBD;
  cursor: pointer;
`;