import axios from 'axios';

const api = axios.create({
    baseURL: 'http://localhost:3001/auth/login'
});

export const useApi = () => ({

    signin: async (email: string, password: string) => {
        const response = await api.post('/auth/login', {
            email, password
        });
        console.log(response.data)
        return response.data;

    },

    logout: async () => {
        const response = await api.post('/logout');
        return response.data
    }
});

export {
    api
}