import { Box, CircularProgress, Grid, MenuItem, TextField } from "@mui/material";
import { Navbar, Sidebar } from "../../shared/components";
import { AddButton, FilterButton, Container, Row, Column } from "./style";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import TableTransmittal from "../../shared/components/Table/TableTransmittal";
import AuthContext from "../../shared/context/AuthContext";
import TransmittalService from "../../shared/services/api/transmittalService";
import McsService from "../../shared/services/api/mcsService";
import maskCurrency from '../../shared/enums/MaskCurrency';

interface MarketCenter {
    _id: string;
    name: string;
}

interface TransmittalProps {
    _id: string;
    marketCenter: string;
    updatedAt: string;
    createdAt: string;
    editor: string;
    month?: any;
    year?: string;
    totalMc: string;
    mktShare: any;
    totalAppointments: string;
    totalAppointmentsR2: string;
    listingsAtivosUnids: string;
    listingsAtivosVgv: string;
    grossAgentsBruto: string;
    grossAgentsProducao: string;
    totalDespesasMc: string;
    totalCompanyCurrency: string;
    otherMcIncome: string;
    totalMcRevenue: string;
}

const TransmittalPage = () => {
    const { user } = useContext(AuthContext);
    const [render, setRender] = useState(false);
    const [transmittal, setTransmittal] = useState<TransmittalProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [mcs, setMcs] = useState<MarketCenter[]>([]);
    const [role, setRole] = useState(false);

    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [reload, setReload] = useState(false)
    const [enable, setEnable] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        async function getData() {
            setLoading(true);
            try {
                const response = await TransmittalService.getTransmittals();
                const resMc = await McsService.getMCs();
                setMcs(resMc.data);
                setTransmittal(response.data || []);
                console.log(response.data)

                if (user?.permissionLevel === "ADMIN") {
                    setRole(true)
                }

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [reload]);

    function handleFilterMonth(e: any) {
        setMonth(e.target.value)
        console.log("filtro month", e.target.value)
    }

    function handleFilterYear(e: any) {
        setYear(e.target.value)
        console.log("filtro year", e.target.value)
    }

    function handleSearchTransmittal() {
        try {
            const searchTransmittal = transmittal.filter((t) => {
                if (t.month === month && t.year === year)
                    return true;
                return false;
            })
            setTransmittal(searchTransmittal)
            setRender(true)
            setEnable(true)
        } catch (e) {
            console.error(e)
        }
    }

    const convertToNumber = (value: string | number): number => {
        const numberValue = typeof value === 'string' ? parseFloat(value) : value;
        return isNaN(numberValue) ? 0 : numberValue;
    };

    return (
        <>
            <Navbar />
            <Sidebar
                Children={
                    <>
                        <Container>
                            <Row>
                                <Column>
                                    <TextField
                                        disabled={enable}
                                        variant='outlined'
                                        select
                                        label='Mês'
                                        color='secondary'
                                        required
                                        style={{
                                            width: '10rem',
                                            marginTop: '6px',
                                            marginLeft: '6px',
                                            marginRight: '6px',
                                            marginBottom: '6px',
                                        }}
                                        onChange={handleFilterMonth}
                                        value={month}
                                    >
                                        <MenuItem value={1}>Janeiro</MenuItem>
                                        <MenuItem value={2}>Fevereiro</MenuItem>
                                        <MenuItem value={3}>Março</MenuItem>
                                        <MenuItem value={4}>Abril</MenuItem>
                                        <MenuItem value={5}>Maio</MenuItem>
                                        <MenuItem value={6}>Junho</MenuItem>
                                        <MenuItem value={7}>Julho</MenuItem>
                                        <MenuItem value={8}>Agosto</MenuItem>
                                        <MenuItem value={9}>Setembro</MenuItem>
                                        <MenuItem value={10}>Outubro</MenuItem>
                                        <MenuItem value={11}>Novembro</MenuItem>
                                        <MenuItem value={12}>Dezembro</MenuItem>
                                    </TextField>
                                </Column>
                                <Column>
                                    <TextField
                                        disabled={enable}
                                        variant='outlined'
                                        select
                                        label='Ano'
                                        color='secondary'
                                        required
                                        style={{
                                            width: '10rem',
                                            marginTop: '6px',
                                            marginLeft: '6px',
                                            marginRight: '6px',
                                            marginBottom: '6px',
                                        }}
                                        onChange={handleFilterYear}
                                        value={year}
                                    >
                                        <MenuItem value={24}>2024</MenuItem>
                                        <MenuItem value={23}>2023</MenuItem>
                                        <MenuItem value={22}>2022</MenuItem>
                                    </TextField>
                                </Column>
                                <FilterButton onClick={handleSearchTransmittal}>
                                    <SearchIcon sx={{ margin: '-6px 12px -6px 6px' }} />
                                    Buscar
                                </FilterButton>
                                <FilterButton onClick={() => {
                                    setMonth('')
                                    setYear('')
                                    setReload((prev) => {
                                        return !prev
                                    })
                                    setEnable(false)
                                }}>
                                    <TuneIcon sx={{ margin: '-6px 12px -6px 6px' }} />
                                    Limpar Filtros
                                </FilterButton>
                                <AddButton onClick={() => navigate('/soukw/create-transmittal')}>
                                    Novo Transmittal
                                </AddButton>
                            </Row>
                        </Container>

                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <CircularProgress />
                            </Box>
                        ) : render ? (
                            <Box sx={{ width: '100%' }}>
                                <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={2}>
                                    {transmittal.map((t) => {
                                        const totalAppointmentsNumber = convertToNumber(t.totalAppointments);
                                        const totalAppointmentsR2Number = convertToNumber(t.totalAppointmentsR2);
                                        const mktShare = totalAppointmentsR2Number !== 0 ? totalAppointmentsNumber / totalAppointmentsR2Number : 0;

                                        return (
                                            role ? user?.marketCenterId === Number(t.marketCenter) && user?.permissionLevel === "ADMIN" && (
                                                <Grid item xl={4} lg={6} md={9} xs={10} paddingTop={1} key={t._id}>
                                                    <TableTransmittal
                                                        month={t.month}
                                                        year={t.year}
                                                        updated={new Date(t.updatedAt).toLocaleDateString('pt-br')}
                                                        created={new Date(t.createdAt).toLocaleDateString('pt-br')}
                                                        editor={t.editor}
                                                        link={`/soukw/edit-transmittal/${t._id}`}
                                                        mcName={mcs.find((mc) => String(mc._id) === String(t.marketCenter))?.name || ''}
                                                        totalAppointments={t.totalAppointments}
                                                        totalAppointmentsR2={t.totalAppointmentsR2}
                                                        mktShare={`${((totalAppointmentsR2Number / totalAppointmentsNumber) * 100).toFixed(1)} %`}
                                                        activeListingsUnited={t.listingsAtivosUnids}
                                                        activeListingsVgv={maskCurrency(t.listingsAtivosVgv)}
                                                        grossAgentsTotal={t.grossAgentsBruto}
                                                        grossAgentsProdcution={t.grossAgentsProducao}
                                                        totalExpansesMarketCenter={maskCurrency(t.totalDespesasMc)}
                                                        totalCompanyCurrency={maskCurrency(t.totalCompanyCurrency)}
                                                        otherMarketCenterIncome={maskCurrency(t.otherMcIncome)}
                                                        totalMarketCenterRevenue={maskCurrency(t.totalMcRevenue)}
                                                        totalMc={t.totalMc}
                                                    />
                                                </Grid>
                                            ) : (
                                                user?.permissionLevel === "SUPER" && (
                                                    <Grid item xl={4} lg={6} md={9} xs={10} paddingTop={1} key={t._id}>
                                                        <TableTransmittal
                                                            month={t.month}
                                                            year={t.year}
                                                            updated={new Date(t.updatedAt).toLocaleDateString('pt-br')}
                                                            created={new Date(t.createdAt).toLocaleDateString('pt-br')}
                                                            editor={t.editor}
                                                            link={`/soukw/edit-transmittal/${t._id}`}
                                                            mcName={mcs.find((mc) => String(mc._id) === String(t.marketCenter))?.name || ''}
                                                            totalAppointments={t.totalAppointments}
                                                            totalAppointmentsR2={t.totalAppointmentsR2}
                                                            mktShare={`${((totalAppointmentsR2Number / totalAppointmentsNumber) * 100).toFixed(1)} %`}
                                                            activeListingsUnited={t.listingsAtivosUnids}
                                                            activeListingsVgv={maskCurrency(t.listingsAtivosVgv)}
                                                            grossAgentsTotal={t.grossAgentsBruto}
                                                            grossAgentsProdcution={t.grossAgentsProducao}
                                                            totalCompanyCurrency={maskCurrency(t.totalCompanyCurrency)}
                                                            totalExpansesMarketCenter={maskCurrency(t.totalDespesasMc)}
                                                            otherMarketCenterIncome={maskCurrency(t.otherMcIncome)}
                                                            totalMarketCenterRevenue={maskCurrency(t.totalMcRevenue)}
                                                            totalMc={t.totalMc}
                                                        />
                                                    </Grid>
                                                )
                                            )
                                        );
                                    })}
                                </Grid>
                            </Box>
                        ) : (
                            <p>Nenhuma data selecionada</p>
                        )}
                    </>
                }
            />
            {/* <FilterTransmittal open={filterTransmital || Boolean(transmittalEdit)} close={closeModal} setFilters={setFilters} /> */}
        </>
    );
};

export default TransmittalPage;
