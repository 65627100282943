const maskCurrency = (num: string | number = '.'): string => {
    let initial = String(num).replace(/[^0-9,.]/g, ''); // Mantém apenas números, vírgula e ponto
    let formattedNum = initial;

    if (formattedNum.includes(',')) {
        formattedNum = formattedNum.replace(/,/g, '.'); // Substitui vírgula por ponto, se necessário
    }

    let parts = formattedNum.split('.'); // Separa parte inteira da decimal
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Adiciona as vírgulas como separador de milhar

    return 'R$ ' + parts.join('.');
}

export default maskCurrency;