import { Divider, Grid } from "@mui/material"
import GridMC from "../../../shared/components/Grids/GridMC"
import { ButtonMC, Column, Row, Title } from "../style"
import { Link } from "react-router-dom"
import { useState } from "react"
import NewCreateMCModal from "../../../shared/components/Modals/NewMC"
import { Navbar, Sidebar } from "../../../shared/components"
import MenuAdmin from "../../../shared/components/Menu/MenuAdmin"

interface Region {
    _id: string;
    name: string;
}

interface MC {
    _id: string;
    name: string;
    kwId: string;
    regionId: string;
    updatedAt: string;
}

const MarketCenterPage = () => {
    const [regions, setRegions] = useState<Region[]>([]);
    const [mcs, setMcs] = useState<MC[]>([]);
    const [editMc, setEditMc] = useState<null | any>(null);
    const [newMcModalOpen, setNewMcModalOpen] = useState(false);
    const openNewMcModal = () => {
        setEditMc(null);
        setNewMcModalOpen(true);
    }

    const closeModal = () => {
        setNewMcModalOpen(false);
    };


    return (
        <>
            <Navbar />
            <MenuAdmin />
            <Sidebar Children={
                <Column style={{ marginTop: '36px' }}>
                    <Row>
                        <Title>Market Centers...</Title>
                        <ButtonMC onClick={openNewMcModal}>Cadastrar MC+</ButtonMC>
                    </Row>
                    <GridMC children={
                        mcs?.map(mc => {
                            return (
                                <Grid item xs={12}>
                                    <Divider style={{ width: '100%' }} />
                                    <div style={{ width: '100%', padding: '5px' }}>
                                        <Link to={'/soukw/admin/edit-mc'}
                                            style={{ textDecoration: 'none', color: '#565e70' }}
                                        >
                                            <Grid container
                                                justifyItems='flex-start'
                                                textAlign={'start'}
                                                style={{
                                                    cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px'
                                                }}
                                            >
                                                <Grid item lg={3} xs={2}>
                                                    {mc.name}
                                                </Grid>
                                                <Grid item lg={2.01} xs={3.2}>
                                                    {mc.kwId}
                                                </Grid>
                                                <Grid item lg={2} xs={2.6}>
                                                    {regions?.find(r => r._id === mc.regionId)?.name}
                                                </Grid>
                                                <Grid item lg={3.1} xs={2.6}>
                                                    {new Date(mc.updatedAt).toLocaleDateString('pt-br')}
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </div>
                                    <Divider style={{ width: '100%' }} />
                                </Grid >)
                        })
                    } />
                </Column >
            } />
            <NewCreateMCModal
                open={newMcModalOpen || Boolean(editMc)}
                close={closeModal}
                mc={editMc}
            />
        </>

    )

}

export default MarketCenterPage