import React, { useState, useEffect, useContext } from 'react';
import ListingsGrid from './ListingsGrid';
//import RecentXMLFiles from './RecentXMLFiles';
import BulkActions from './BulkActions';
import SideDrawer from './SideDrawer';
import { Container, Box, IconButton, Switch, Pagination, CircularProgress, TextField, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { DataProvider } from './context/DataContext';
import listingsCrud from './models/ListingsCrud';
import {useData } from './context/DataContext';
import db from './models/ListingsDB';
import {Listing} from './models/ListingsInterfaces';
import { FiltersInterface } from './interfaces';
import { Collection } from 'dexie';

const DashboardContainer = () => {
  const initialQuery = db.listings.toCollection();
  const [fileName, setFileName] = useState<string>('');
  const [filters, setFilters] = useState<FiltersInterface>( );
  const [filterQuery, setFilterQuery] = useState<Collection>(  initialQuery );
  const [filterPaginatedQuery, setFilterPaginatedQuery] = useState<Collection>( initialQuery );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filteredListings, setFilteredListings] = useState<Listing[]>([]);
  const [refreshDB, setRefreshDB] = useState( false )
  const [totalListings, setTotalListings]  = useState( 0 );
  const [totalFilteredListings, setTotalFilteredListings]  = useState( 0 );
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8); // Number of items per page
  //const { listings, loading } = useData();
  //const [listings, setListings] = useState<Array<any>>([]);
  //const [loaded, setLoaded] = useState<boolean>( false )

  const handleSearch = (filters: FiltersInterface) => {
    setFilters(filters);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Mock data for listings
  // const mockListings = [
  //   {
  //     id: 1,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 1',
  //     address: '123 Main St',
  //     value: '$300,000',
  //     agent: 'John Doe',
  //     inXml: true,
  //     neighborhood: 'Downtown',
  //     planType: 'Apartment',
  //     description: 'Spacious apartment in the heart of the city.'
  //   },
  //   {
  //     id: 2,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 2',
  //     address: '456 Elm St',
  //     value: '$450,000',
  //     agent: 'Jane Smith',
  //     inXml: false,
  //     neighborhood: 'Suburbia',
  //     planType: 'House',
  //     description: 'Beautiful house in a quiet neighborhood.'
  //   },
  //   {
  //     id: 1,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 1',
  //     address: '123 Main St',
  //     value: '$300,000',
  //     agent: 'John Doe',
  //     inXml: true,
  //     neighborhood: 'Downtown',
  //     planType: 'Apartment',
  //     description: 'Spacious apartment in the heart of the city.'
  //   },
  //   {
  //     id: 2,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 2',
  //     address: '456 Elm St',
  //     value: '$450,000',
  //     agent: 'Jane Smith',
  //     inXml: false,
  //     neighborhood: 'Suburbia',
  //     planType: 'House',
  //     description: 'Beautiful house in a quiet neighborhood.'
  //   },
  //   {
  //     id: 1,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 1',
  //     address: '123 Main St',
  //     value: '$300,000',
  //     agent: 'John Doe',
  //     inXml: true,
  //     neighborhood: 'Downtown',
  //     planType: 'Apartment',
  //     description: 'Spacious apartment in the heart of the city.'
  //   },
  //   {
  //     id: 2,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 2',
  //     address: '456 Elm St',
  //     value: '$450,000',
  //     agent: 'Jane Smith',
  //     inXml: false,
  //     neighborhood: 'Suburbia',
  //     planType: 'House',
  //     description: 'Beautiful house in a quiet neighborhood.'
  //   },
  //   {
  //     id: 1,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 1',
  //     address: '123 Main St',
  //     value: '$300,000',
  //     agent: 'John Doe',
  //     inXml: true,
  //     neighborhood: 'Downtown',
  //     planType: 'Apartment',
  //     description: 'Spacious apartment in the heart of the city.'
  //   },
  //   {
  //     id: 2,
  //     image: 'https://via.placeholder.com/150',
  //     title: 'Listing 2',
  //     address: '456 Elm St',
  //     value: '$450,000',
  //     agent: 'Jane Smith',
  //     inXml: false,
  //     neighborhood: 'Suburbia',
  //     planType: 'House',
  //     description: 'Beautiful house in a quiet neighborhood.'
  //   },
  //   // Add more mock listings as needed
  // ];

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    return value
  };

  const fetchListings = async () => {
    setLoading(true);

    try {
        // Build the Dexie query with filters
        let query = db.listings.toCollection(); // Start with all listings

        if( filters ){
          if( filters?.mls_number )
            query = query.filter( listing => {
              const testRegex = new RegExp( filters.mls_number, 'gi' );
              return testRegex.test( listing.mls_number )
            })
          if( filters?.parking ) //banheiros
            query = query.filter(
            listing => listing.parking_total >= filters.parking );

          if( filters?.bathrooms ) //banheiros
            query = query.filter(
              listing => listing.total_bath >= filters.bathrooms );
          
          if( filters?.minPrice ) //current_price_range
            query = query.filter(
              listing => listing.current_list_price >= filters.minPrice);
          if( filters?.maxPrice ) //current_price_range
            query = query.filter(
              listing => listing.current_list_price <= filters.maxPrice);
          if( filters?.minArea ) //current_price_range
            query = query.filter(
              listing => listing.living_area >= filters.minArea);
          if( filters?.maxArea ) //current_price_range
            query = query.filter(
              listing => listing.living_area <= filters.maxArea);
        }
        // Get total count of filtered records (for pagination)
        setFilterQuery( query );
        const totalCount = await query.count();
        setTotalFilteredListings( totalCount );

        // Apply pagination using offset and limit
        query = query
            .offset((page - 1) * pageSize)
            .limit(pageSize);
         setFilterPaginatedQuery( query );   
        const data = await query.toArray()

            console.log(data)
            setFilteredListings(data);
    } catch (error) {
        console.error('Failed to fetch listings:', error);
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
  fetchListings();
}, [page, pageSize, filters, refreshDB]);

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" gap={2}>
          <BulkActions 
            selectionQuery={filterQuery}
            paginatedQuery={filterPaginatedQuery}
            refresher={setRefreshDB}
            page={page}
            setFile={setFileName}
          />
        </Box>
        <IconButton onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Grid container spacing={1}>
      <Grid item xs={4}>
      <Pagination
          count={Math.ceil(totalFilteredListings / pageSize)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}
      />
      </Grid>
      <Grid item xs={1}>
      <TextField
        name="pageSize"
        label="Items por Página"
        type='number'
        value={pageSize}
        onChange={(e: any) => { setPageSize(parseInt(e.target.value)) } }
        fullWidth
      />
      </Grid>
      </Grid>
      { !loading &&
        <ListingsGrid listings={filteredListings} fileName={fileName}/>
      }
      
      <SideDrawer open={isDrawerOpen} onClose={toggleDrawer} onSearch={handleSearch} prevFilters={filters}/>
    </Container>
  );
};

export default DashboardContainer;


