// DataContext.tsx
import React, { createContext, useContext, useEffect, useCallback } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import db from '../models/ListingsDB'; // Your Dexie instance
import { Listing } from '../models/ListingsInterfaces'; // Your Listing type
import IntegracaoPortaisService from '../../../shared/services/api/integracaoPortais';

interface DataContextType {
    listings: Listing[] | undefined; // Listings are undefined while loading
    loading: boolean;
    reloadData: () => Promise<void>; // Function to manually refresh data if needed
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const listings = useLiveQuery(() => db.listings.toArray(), [], []);
    const loading = listings === undefined;

    // Function to reload data from the backend
    const reloadData = useCallback(async () => {
        try {
            const response = await IntegracaoPortaisService.getListings( {} ); 
            const data: Listing[] = response;


            //TODO: Alterar para buscar apenas registros que tenham mudado com o decorrer do tempo (quae nada) 
            await db.transaction('rw', db.listings, async () => {
                await db.listings.clear();
                await db.listings.bulkPut(data);
            });

            console.log('Data reloaded from server and stored in Dexie');
        } catch (error) {
            console.error('Failed to load data:', error);
        }
    }, []);

    // Use `setInterval` to automatically sync data every 5 minutes
    useEffect(() => {
        // Initial data load when component mounts
        reloadData();

        // Set up interval for periodic sync
        const interval = setInterval(() => {
            console.log('Syncing data...');
            reloadData();
        }, 15 * 60 * 1000); // Sync every 5 minutes

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, [reloadData]);

    return (
        <DataContext.Provider value={{ listings, loading, reloadData }}>
            {children}
        </DataContext.Provider>
    );
};

// Custom hook to use the DataContext
export const useData = (): DataContextType => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
