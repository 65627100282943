import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const IMPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.6}
                            xl={5}
                            paddingLeft={2}
                            paddingTop={2}>
                            <Card
                                image="https://soukw.com.br/static/media/dataLandLogo.db0e8837a218a2bd6211.png"
                                title="Data Land"
                                text="Precifique o seu imóvel utilizando o valor real transacionado, combinado com o modelo digital das cidades, que conta com aspectos urbanos, mercadológicos e legais."
                                acess={'https://app.dataland.ai/hub/auth/login'}
                                term={'https://drive.google.com/file/d/1SI5mJx2F54BKo0VQb8b65rvltMOKqFV-/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '20px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>

                        <div style={{
                            width: '2rem'
                        }}>
                        </div>

                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.6}
                            xl={5}
                            paddingLeft={2}
                            paddingTop={2}>
                            <Card
                                image="https://soukw.com.br/static/media/eEmovelLogo.74c2bdf4fc49f40850ac.png"
                                title="EEmovel Inteligência Imobiliária"
                                text="Auxilie a tomada de decisões e gere insights que potencializarão seu negócio. Transforme o jeito de fazer negócios por meio de tecnologia e informação."
                                acess={'https://brokers.eemovel.com.br/login'}
                                term={'https://drive.google.com/file/d/1YcoXPoD4qNFOEMa-r4YPdqOw67gRSm-l/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '4px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>

                        <div style={{
                            width: '2rem'
                        }}>
                        </div>

                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.6}
                            xl={5}
                            paddingLeft={2}
                            paddingTop={2}>
                            <Card
                                image="https://soukw.com.br/static/media/quiresLogo.04d6002ffbbb9a963dd1.png"
                                title="Quires"
                                text="Monitore o fluxo de escaneamentos e visualizações de placas dos imóveis e eleve a inteligência comercial e geográfica a partir disso, dimensionando a demanda por diferentes tipos de produtos imobiliários."
                                acess={'https://quires.com.br/login'}
                                term={'https://drive.google.com/file/d/1dq1eRS3ofLSoUDR_sWxgqVQHYzIebBQn/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '10px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default IMPage;