import { useContext, useState } from "react";
import GridProfile from "../../shared/components/Grids/GridProfile";
import { Column } from "./style";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Navbar, Sidebar } from "../../shared/components";
import AuthContext from "../../shared/context/AuthContext";

interface Profile {
    photo: string;
    fullName: string;
    email: string;
    permissionLevel: string;
    mc: number;
    kwuid: string;
    permissionLevelTreinamentos: string;
    name: string;
    team: number;

}

const MyProfilePage: React.FC = () => {
    const { user, authLoading, authError } = useContext(AuthContext)
    console.log(user)

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Column>
                    <Grid2 container justifyContent="center">
{ authLoading && authError.length === 0 ? <GridProfile
                            photo={user?.photo ?? ''}
                            email={user?.email ?? ''}
                            fullName={user?.fullName ?? ''}
                            kwuid={user?.kwuid ?? ''}
                            mc={user?.marketCenterId.toString() ?? ''}
                            name={user?.fullName ?? ''}
                            permissionLevel={user?.permissionLevel ?? ''}
                            permissionLevelTreinamentos={user?.permissionLevelTreinamentos ?? ''}
                            team={user?.teamId.toString() ?? ''}
                        /> : <p>...Loading</p>}
                    </Grid2>
                </Column>
            } />
        </>
    );
};

export default MyProfilePage;