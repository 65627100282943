import GridListings from "../../shared/components/Grids/GridListings";
import TuneIcon from '@mui/icons-material/Tune';
import { ButtonFilter } from "./style";
import { Grid } from "@mui/material";
import { PhotoListings } from "../../shared/components/Grids/style"
import Divider from '@mui/material/Divider';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../shared/components/Loading";
import maskCurrency from "../../shared/enums/MaskCurrency";
import { Navbar, Sidebar } from "../../shared/components";
import { token } from "../../shared/services/api";

interface Listing {
    _id: string;
    list_address: {
        street_name: string;
        street_number: string;
        city: string;
    };
    current_list_price: string;
    list_kw_uid: string;
    list_status: string;
    market_center: string;
    teamId: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    newListingAgentId: number;
}

interface User {
    kwuid: string;
    fullName: string;
}

interface Team {
    teamId: string;
    _id: string;
    name: string;
}

interface MC {
    kwId: string;
    name: string
}

const ListingsPage = () => {
    const [newListingCaptacaoOpen, setNewListingCaptacaoOpen] = useState(false);
    const [newListingModalOpen, setNewListingModalOpen] = useState(false);
    const [listings, setListings] = useState<Listing[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [mcs, setMcs] = useState<MC[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [filteringMode, setFilteringMode] = useState(false);
    const [enderecoFilter, setEnderecoFilter] = useState('');
    const [bairroFilter, setBairroFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [condominioFilter, setCondominioFilter] = useState('');
    const [incorporadoraFilter, setIncorporadoraFilter] = useState('');
    const [metragemFilter, setMetragemFilter] = useState([1, 1000]);
    const [valorFilter, setValorFilter] = useState([1000, 50000000]);
    const [quartosFilter, setQuartosFilter] = useState(1);
    const [banheirosFilter, setBanheirosFilter] = useState(1);
    const [vagasFilter, setVagasFilter] = useState(0);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("page: ", page)
        console.log('filters: ', filters)
        async function getData() {
            setLoading(true);
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                //changed here
                let url = `https://soukw.com.br:3000/listings/myListings?page=${page}`
                // if (filters.city)
                //     url = url + '&city=' + filters.city
                // if (filters.bairro)
                //     url = url + '&neighbourhood=' + filters.bairro
                // if (filters.address)
                //     url = url + '&address=' + filters.address
                // if (filters.condominio)
                //     url = url + '&condominio=' + filters.condominio
                // if (filters.construtora)
                //     url = url + '&construtora=' + filters.construtora
                // if (filters.valorMin && filters.valorMin !== 1000)
                //     url = url + '&valorMin=' + filters.valorMin
                // if (filters.valorMax && filters.valorMax !== 50000000)
                //     url = url + '&valorMax=' + filters.valorMax
                // if (filters.metragemMin && filters.metragemMin !== 1)
                //     url = url + '&metragemMin=' + filters.metragemMin
                // if (filters.metragemMax && filters.metragemMax !== 1000)
                //     url = url + '&metragemMax=' + filters.metragemMax
                // if (filters.quartos && parseInt(filters.quartos) !== 1)
                //     url = url + '&quartos=' + filters.quartos
                // if (filters.banheiros && parseInt(filters.banheiros) !== 1)
                //     url = url + '&banheiros=' + filters.banheiros
                // if (filters.vagas && parseInt(filters.vagas) !== 0)
                //     url = url + '&vagas=' + filters.vagas

                let results = await axios.get(
                    url,
                    config
                );

                setListings(results.data.data.reverse());
                // console.log(results.data?.data?.reverse());
                setTotal(results.data?.totalItems)
                setTotalPages(results.data?.totalPages)
                setPage(results.data?.page)
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                alert('Erro de Conexão.')

            }
        }
        getData();
        console.log(page)
    }, [filters, page]);

    useEffect(() => {
        async function getData() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                let users = await axios.get('https://soukw.com.br:3000/users', config)
                let teams = await axios.get('https://soukw.com.br:3000/teams', config)
                let mcs = await axios.get('https://soukw.com.br:3000/mcs', config)
                setUsers(users.data)
                setTeams(teams.data)
                setMcs(mcs.data)

            } catch (e) {
                console.error(e);
                alert('Erro de Conexão.');
            }
        }
        getData()
    }, []);

    console.log(teams?.find(team => String(team._id)))

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <>
                    <h1>Listings Legados</h1>
                    <ButtonFilter>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <TuneIcon /> Filtro
                        </div>
                    </ButtonFilter>
                    <GridListings
                        children={
                            loading ? <Loading /> :
                                listings.map((listing, i) => {
                                    return (
                                        <Grid

                                            item xs={12}
                                        >
                                            <Divider style={{ width: '100%' }} />
                                            <div style={{
                                                width: '100%',
                                                backgroundColor: '#ffff'
                                            }}
                                            >
                                                <Link
                                                    to={'/soukw/listings/editlistings/'}
                                                    style={{ textDecoration: 'none', color: '#373f51' }}
                                                >
                                                    <Grid container
                                                        justifyItems='flex-start'
                                                        alignItems={'center'}
                                                        style={{ cursor: 'pointer', paddingTop: '6px', paddingBottom: '6px' }}
                                                    >
                                                        <Grid item lg={3.06} xs={1.8}>
                                                            <PhotoListings>
                                                                <img
                                                                    style={{
                                                                        width: '120px',
                                                                        height: '70px',
                                                                        borderRadius: '6px',
                                                                        marginLeft: '6px'
                                                                    }}
                                                                    src="https://soukw.com.br/images/listings/16612279113861/main_16612578344618.jpg"
                                                                />
                                                                <span style={{
                                                                    marginTop: '12px',
                                                                    marginLeft: '12px'
                                                                }}>
                                                                    {listing.list_address.street_name},
                                                                    {listing.list_address.street_number}
                                                                    - {listing.list_address.city}
                                                                </span>
                                                            </PhotoListings>

                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item lg={1.2} xs={1.8} style={{ paddingLeft: '12px' }}>
                                                            {maskCurrency(listing.current_list_price)}
                                                        </Grid>
                                                        <Grid item lg={1.6} xs={2.2}>
                                                            {listing.newListingAgentId !== -1
                                                                ? users?.find(u => String(u.kwuid) === String(listing.newListingAgentId))?.fullName
                                                                : users?.find(u => String(u.kwuid) === String(listing.list_kw_uid))?.fullName
                                                            }
                                                        </Grid>
                                                        <Grid item lg={1.4} xs={1.5}>
                                                            {listing.status}
                                                        </Grid>
                                                        <Grid item lg={1.4} xs={1}>
                                                            {mcs?.find(mc => String(mc.kwId) ===
                                                                String(listing.market_center))?.name
                                                            }
                                                        </Grid>
                                                        <Grid item lg={1.2} xs={1}>
                                                            {teams?.find(team => String(team._id) ===
                                                                String(listing.teamId))?.name
                                                            }
                                                        </Grid>
                                                        <Grid item lg={1} xs={1.2}>
                                                            {
                                                                new Date(listing.createdAt)
                                                                    .toLocaleDateString('pt-br')
                                                            }
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            {
                                                                new Date(listing.updatedAt)
                                                                    .toLocaleDateString('pt-br')
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Link>
                                            </div>
                                            <Divider style={{ width: '100%' }} />
                                        </Grid >
                                    );
                                })
                        } />
                </>
            } />
        </>
    )
}

export default ListingsPage;