import { Box, Grid } from '@mui/material';
import { Card } from '../../../pages/Transmittal/style';
import { Link } from 'react-router-dom';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

interface transmittalProps {
    mcName: string,
    link: string,
    updated: string,
    created: string,
    editor: string,
    month: any,
    year: any,
    totalMc: string,
    totalAppointments: string,
    totalAppointmentsR2: string,
    mktShare: any,
    activeListingsUnited: string,
    activeListingsVgv: string,
    grossAgentsTotal: string,
    grossAgentsProdcution: string,
    totalExpansesMarketCenter: string,
    totalCompanyCurrency: string,
    otherMarketCenterIncome: string,
    totalMarketCenterRevenue: string,
}

const TableTransmittal = ({ ...props }: transmittalProps) => {
    return (
        <Card>
            <div
                style={{
                    height: '6.9rem',
                    width: '100%',
                    backgroundColor: '#d1d1d1',
                    color: '#000',
                    borderRadius: '6px 6px 0px 0px'
                }}>
                <div style={{ display: 'flex', flexDirection: "row" }}>
                    <h3 style={{ padding: '6px 0px 0px 6px', marginBottom: '-6px' }}>
                        INDICADORES {props.mcName}
                    </h3>
                    {/* <Link to={props.link}>
                        <EditIcon sx={{ margin: '18px 0px -6px 100px', color: 'black' }} />
                    </Link> */}
                </div>
                <div style={{ display: 'flex', flexDirection: "row" }}>
                    <p style={{ marginLeft: '6px', marginBottom: '-6px', marginRight: '16px', color: '#595959' }}>
                        Criação: <span style={{ marginLeft: '6px', color: '#000' }}>{props.created}</span>
                    </p>
                    <p style={{ marginLeft: '6px', marginBottom: '-6px', color: '#595959' }}>
                        Atualização: <span style={{ marginLeft: '6px', color: '#000' }}>{props.updated}</span>
                    </p>
                </div>
                <div style={{ display: 'flex', flexDirection: "row" }}>
                    <p style={{ marginLeft: '1px', marginBottom: '-6px', color: '#595959' }}>
                        Editor: <span style={{ marginLeft: '6px', color: '#000' }}>{props.editor}</span>
                    </p>
                    <p style={{ marginLeft: '28%', marginTop: "20px", color: '#6c0000', fontSize: "10px" }}>
                        Referência: <span style={{ marginLeft: '6px', color: '#ae0303' }}>{props.month} / {props.year}</span>
                    </p>
                </div>
            </div>

            <Grid container style={{}}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Total de Appointments Recruitments: <span style={{ marginLeft: '16px', color: '#000' }}>{props.totalAppointments}</span>
                </p>
            </Grid>

            <Grid container style={{ backgroundColor: '#ebebeb' }}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Total de Appointments R2: <span style={{ marginLeft: '16px', color: '#000' }}>{props.totalAppointmentsR2}</span>
                </p>
            </Grid>

            <Grid container style={{ backgroundColor: '#767676' }}>
                <p style={{ color: '#ebebeb', marginLeft: '6px' }}>
                    MKT Share R2 (%): <span style={{ marginLeft: '16px', color: '#fff' }}>{props.mktShare}</span>
                </p>
            </Grid>

            <Grid container style={{ backgroundColor: '#ebebeb' }}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Listings Cadastrados ATIVOS - (Unids): <span style={{ marginLeft: '16px', color: '#000' }}>{props.activeListingsUnited}</span>
                </p>
            </Grid>

            <Grid container style={{ marginBottom: '-6px' }}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Listings Cadastrados ATIVOS - (VGV R$): <span style={{ marginLeft: '16px', color: '#000' }}>{props.activeListingsVgv}</span>
                </p>
            </Grid>

            <Grid container style={{ backgroundColor: '#ebebeb' }}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Gross Agents (Num. de Agentes Brutos): <span style={{ marginLeft: '16px', color: '#000' }}>{props.grossAgentsTotal}</span>
                </p>
            </Grid>

            <Grid container style={{ marginBottom: '-6px' }}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Gross Agents (c/ produção nos últimos 12M): <span style={{ marginLeft: '16px', color: '#000' }}>{props.grossAgentsProdcution}</span>
                </p>
            </Grid>

            <Grid container style={{ backgroundColor: '#ebebeb' }}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Total de Despesas do Market Center (R$): <span style={{ marginLeft: '16px', color: '#000' }}>{props.totalExpansesMarketCenter}</span>
                </p>
            </Grid>

            <Grid container style={{ backgroundColor: '#767676' }}>
                <p style={{ color: '#ebebeb', marginLeft: '6px' }}>
                    Total Company Currency (R$): <span style={{ marginLeft: '16px', color: '#fff' }}>{props.totalCompanyCurrency}</span>
                </p>
            </Grid>

            <Grid container style={{ marginBottom: '-6px' }}>
                <p style={{ color: 'GrayText', marginLeft: '6px' }}>
                    Other MC Income - Se houver (R$): <span style={{ marginLeft: '16px', color: '#000' }}>{props.otherMarketCenterIncome}</span>
                </p>
            </Grid>

            <Grid container style={{ backgroundColor: '#767676' }}>
                <p style={{ color: '#ebebeb', marginLeft: '6px' }}>
                    Total MC Revenue (R$): <span style={{ marginLeft: '16px', color: '#fff' }}>{props.totalMarketCenterRevenue}</span>
                </p>
            </Grid>
        </Card>
    );
};

export default TableTransmittal;