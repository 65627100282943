import { Autocomplete, Dialog, DialogContent, Divider, MenuItem, TextField, TextareaAutosize, } from '@mui/material';
import * as z from 'zod'
import React, { useEffect, useState } from 'react';
import { CancelButton, CreateNewCollectionButton, Footer, Header } from './style';
import TrainingService from '../../services/api/trainingService';
import { useForm } from 'react-hook-form';
import { Textarea } from '@mui/joy';
import McsService from '../../services/api/mcsService';
import { useNavigate } from 'react-router-dom';
import UsersService from '../../services/api/usersService';

const newTreniamentosFormSchema = z.object({
    title: z.string(),
    subtitle: z.string(),
    description: z.string(),
})


interface NewCreateMCModalProps {
    open: boolean;
    close: () => void;
    mc?: {
        name: string;
        kwuidMC: string;
        region: string;
        admin: []
    };
}

interface User {
    fullName: string;
    kwuid: string;
    role: string;
}

export default function NewCreateMCModal({
    open,
    close,
    mc,
}: NewCreateMCModalProps) {
    const [name, setName] = useState<string>('');
    const [kwuidMc, setKwuidMc] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [admin, setAdmin] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        setName(mc ? mc.name : '');
        setKwuidMc(mc ? mc.kwuidMC : '');
        setRegion(mc ? mc.region : '');
        setAdmin(mc ? mc.admin : []);

        async function getData() {
            try {
                let resUsers = await UsersService.getUsers();
                console.log(resUsers)
                setUsers(resUsers)
            } catch (err) {
                console.error(err)
            }
        }
        getData();
    }, [mc]);

    const { handleSubmit, reset } = useForm()

    async function handleCreateNewTraining() {
        await McsService.createMC({
            name,
            kwuidMc,
            region,
            admin,
            createAt: new Date()
        })

        reset()
    };

    function handleChangeRoleUser(e: { target: { value: React.SetStateAction<string>; }; }) {
        setRegion(e.target.value)
    }


    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth="xs">
                <form onSubmit={handleSubmit(handleCreateNewTraining)}>
                    <Header>
                        <div style={{ width: '500px', paddingLeft: '12px' }}>
                            <h1>{mc ? 'Editar' : 'Cadastrar Nova'} Coleção</h1>
                        </div>
                    </Header>
                    <Divider style={{ width: '100%' }} />
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            label="Nome do MC *"
                            color="secondary"
                            required
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                        <TextField
                            variant="outlined"
                            label="Kwuid do MC *"
                            color="secondary"
                            required
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setKwuidMc(e.target.value)}
                            value={kwuidMc}
                        />
                        <TextField
                            variant="outlined"
                            label="Regioão *"
                            color="secondary"
                            select
                            fullWidth
                            style={{ marginTop: '5px', marginBottom: '5px' }}
                            onChange={(e) => setRegion(e.target.value)}
                            value={region}
                        >
                            <MenuItem value={"kwsp"}>KWSP</MenuItem>
                        </TextField>

                        <Autocomplete
                            multiple
                            options={users}
                            getOptionLabel={(option) => option.fullName}
                            filterSelectedOptions
                            noOptionsText='Nenhum Usuário'
                            value={admin}
                            onChange={(e, newValue) => setAdmin(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Administradores"
                                    placeholder="Administradores"
                                />
                            )}
                        />
                    </DialogContent>
                    <Divider style={{ width: '100%' }} />
                    <Footer>
                        <CancelButton type='button' onClick={close}>
                            Cancelar
                        </CancelButton>
                        <CreateNewCollectionButton type='submit'>
                            Cadastrar MC
                        </CreateNewCollectionButton>
                    </Footer>
                </form>

            </Dialog>
        </div>
    );
}
