import Gridv2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { CardTeams, ContentTeams, Header } from './style';
import React from 'react';
import { Grid } from '@mui/material';


interface UsersProps {
    children: React.ReactNode
}

const GridUsers:  React.FC<UsersProps> = (props) => {
    return (
        <>
            <Gridv2
                container
                xs={12}
                md={12}
                lg={12}
                xl={12}
                style={{ height: '100%', textAlign: 'center' }}
                justifyContent='center'
            >
                <CardTeams>
                    <Header>
                    <Grid container justifyItems='flex-start' alignItems={'center'} style={{
                            width: '100%',
                            height: '60px',
                            cursor: 'pointer',
                            padding: '5px',
                            borderTop: '2px  solid #d9d9d9',
                            borderRight: '1px  solid #d9d9d9',
                            borderLeft: '1px  solid #d9d9d9',
                            borderRadius: '6px 6px 0px 0px',
                            backgroundColor: '#f8f7f7',
                            color: 'black'
                        }}>
                            <Grid item lg={2.5} xs={2}>
                                Nome
                            </Grid>

                            <Grid item lg={1.5} xs={3}>
                                Status
                            </Grid>
                            <Grid item lg={1.5} xs={3}>
                                KWUID
                            </Grid>
                            <Grid item lg={1.5} xs={2}>
                                Função
                            </Grid>
                            <Grid item lg={1.5} xs={2}>
                                MC
                            </Grid>
                            <Grid item lg={1.5} xs={2}>
                                Time
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                Criado em
                            </Grid>
                        </Grid>
                    </Header>
                    <ContentTeams>
                       {props.children}
                    </ContentTeams>
                </CardTeams>
            </Gridv2>
        </>
    )
}

export default GridUsers;