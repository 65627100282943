import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const MarketingPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}
                        >
                            <Card
                                image="https://soukw.com.br/static/media/mb2bLogo.3617201b3b91d9abcf5d.png"
                                title="MB2B Assessoria"
                                text="A MB2B é uma empresa de consultoria de marketing focada no desenvolvimento de estratégias inovadoras com um impacto real no crescimento de empresas e startups."
                                acess={'https://www.mb2b.co/'}
                                term={'https://drive.google.com/file/d/1UE2y56JgNUYgo3hRl5DoT48BzLefcmmY/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '24px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://soukw.com.br/static/media/tomorrowAgencyLogo.13858285d9aee19d65cd.png"
                                title="Tomorrow Agency"
                                text="A agência Tomorrow é uma agência de redes sociais e assessoria de marketing digital especializada em performance, com objetivo na entrega de resultados, atrelada às estratégias e metas dos clientes."
                                acess={'https://www.tomorrowagency.com.br/'}
                                term={'https://drive.google.com/file/d/1mcDgk3rrQDCrX1ZJMFPcDdV1AMThyIWA/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '10px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://images-front-soukw.s3.amazonaws.com/alphacomunicacoes.png"
                                title="Alpha Comunicações"
                                text="A Alpha Comunicações é uma mini agência especialista em redes sociais indicada para gestão de perfis e/ou mentorias individuais de construção de identidade nas Redes Sociais em geral."
                                acess={'https://linktr.ee/priscila.fariasmk'}
                                term={'https://drive.google.com/file/d/1cVS-mJavxNNFYGtkqz5z9acSMeHja_zW/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '10px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://soukw.com.br/static/media/juanMikzerLogo.ce30a02e24cc00fb87e6.png"
                                title="Juan Mikzer"
                                text="Profissional homologado para criação de artes gráficas em geral, especialmente logotipos Keller Williams para Market Centers e Times Associados."
                                acess={'https://api.whatsapp.com/send?phone=55119758894&text=Ol%C3%A1,%20sou%20agente%20da%20Keller%20Williams.%20Gostaria%20de%20fazer%20um%20or%C3%A7amento!'}
                                term={'https://drive.google.com/file/d/1eQcdYXZgu5KGaYnduufkrbeVRgTwcf3k/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '38px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box >
            } />
        </>
    )
}

export default MarketingPage;