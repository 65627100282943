import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const VirtualPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.5}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://soukw.com.br/static/media/celerLogo.3f8fb398c29e718d3d7f.png"
                                title="Celer"
                                text="A Celer atua com serviços de decoração virtual para ambientes (home staging), onde a partir da decoração virtual em 3 fotos, há aumento de 50% na geração de leads."
                                acess={'https://celer.imb.br/auth/login'}
                                term={'https://drive.google.com/file/d/1mMbrte9I1oO3Cg80AVnJc-7klvfMP60b/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '26px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default VirtualPage;