import { Grid } from '@mui/material';
import {
    Button,
    Card,
    Container,
    Content,
    InputText,
    LabelText,
    Text,
    Title
} from './style';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JwtUserI, UserI, token } from '../../shared/context/authInterfaces';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AuthContext from '../../shared/context/AuthContext';

interface LoginFormI {
    email: string;
    password: string;
}

interface RootState {
    user: {
        currentUser: UserI;
    };
}

type authResponse = {
    user: UserI;
    token: string;
    jwtUser: JwtUserI;
}

const LoginPage = () => {
    const [formData, setFormData] = useState<LoginFormI>({ password: '', email: '' });
    const navigate = useNavigate();
    const [eyeIsClosed, setEyeState] = useState(false);
    const {
        user,
        jwtUser,
        jwtToken,
        signIn,
        signOut,
        authLoading,
        authError,
    } = useContext(AuthContext)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value,
        }));
    };

    const expirationDate = localStorage.getItem('expirationDate');
    const isExpired = expirationDate ? new Date(expirationDate).getTime() < Date.now() : true;

    if (!isExpired) {
        if (jwtToken !== '' && jwtUser !== null && user !== null)
            navigate('/soukw');
    }

    async function handleSubmit() {

        let data = await signIn(formData.email, formData.password);
        console.log("Resposta de login: ", data);
        // dispatch(signInSuccess(data));

        if (authError.length > 0) {
            alert(authError[0].message);
            return navigate('/');
        } else {
            // dispatch(signInFailure(data.message));
            return navigate('/soukw/home/training');
        }
    }
    return (
        <Container>
            <Grid container
                alignItems={'center'}
                justifyContent={'center'}
                style={{ height: '100vh' }}>
                <Card>
                    <Content onSubmit={() => alert()}>
                        <img src={'https://images-front-soukw.s3.amazonaws.com/LogoKw.svg'} style={{
                            width: '85px',
                            height: '72px',
                            padding: '2.6rem 0px 0px 0px'
                        }} alt='logo' />

                        <Title>
                            Bem vindo ao SouKW
                        </Title>
                        <Text>
                            Faça login com sua conta Kwsp para ter
                            <br /> acesso ao <strong>SouKW.</strong>
                        </Text>

                        <LabelText>Email</LabelText>
                        <InputText
                            type='email'
                            placeholder='email'
                            id='email'
                            onChange={handleChange}
                        />
                        <br />
                        <br />
                        <LabelText>Senha</LabelText>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        >
                            <InputText
                                type='password'
                                placeholder='password'
                                id='password'
                                onChange={handleChange}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: '1.5rem',
                            }}>
                            <Button
                                onClick={handleSubmit}
                                disabled={authLoading}
                            >
                                {authLoading ? 'Carregando...' : 'Entrar'}
                                <ArrowForwardIcon
                                    sx={{ margin: '0px 0px -6px 12px', }}
                                />
                            </Button>
                        </div>
                    </Content>
                </Card>
            </Grid>
        </Container >
    )
}

export default LoginPage;