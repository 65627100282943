import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const SignalingPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://soukw.com.br/static/media/grupoCincoLogo.1f2469ec218276b4c783.png"
                                title="Grupo Cinco"
                                text="Produção de placas imobiliárias e adesivos, com QRcode Quires ou não."
                                acess={'https://placasdeimobiliaria.com.br/'}
                                term={'https://drive.google.com/file/d/1ZFgejNuNd5e2qxON_MLI3M4t_c7YqjfK/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '52px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://soukw.com.br/static/media/partnerLogo.232ab493ab95d9c53653.png"
                                title="Letreiros"
                                text="Letreiros para fachadas e recepções."
                                acess={'https://wa.me/5511947423570?text=Ol%C3%A1%2C+sou+agente+da+Keller+Williams.+Gostaria+de+solicitar+um+or%C3%A7amento%21'}
                                term={'https://drive.google.com/file/d/1tLglUMZbmGYqMal2wibs2dB3voQ-YZXj/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '66px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://soukw.com.br/static/media/partnerLogo.232ab493ab95d9c53653.png"
                                title="Adesivos"
                                text="Produção de adesivos para ambiente, conforme manual da marca KW."
                                acess={'https://wa.me/5511992920301?text=Ol%C3%A1%2C+sou+agente+da+Keller+Williams.+Gostaria+de+solicitar+um+or%C3%A7amento%21'}
                                term={'https://drive.google.com/file/d/1G8FD7wqJv9bZxr838OiXXds_dpHVZYHT/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '52px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default SignalingPage;