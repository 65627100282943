import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Button, Card, CardHome, ContentHome, Divider, Header, Paragraph, Title } from './style';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
//import { token } from '../../services/api';
import ListingsService from '../../services/api/listingsService';



const GridHome: React.FC = () => {
    const navigate = useNavigate();
    // const {token} = AuthContext
    const [total, setTotal] = useState();
    const [xmlTotal, setXMLTotal] = useState();
    const [legacyTotal, setLegacyTotal] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        async function getData() {
            setLoading(true);
            try {
                let totalListings = await ListingsService.getTotalListings();
                let legacy = await ListingsService.getListingLegados();
                let XML = await ListingsService.getTotalXML();
                setTotal(totalListings);
                setLegacyTotal(legacy.totalItems);
                setXMLTotal(XML.xmlSize);

                console.log(XML)
            } catch (e) {
                console.error(e);
                alert('Erro de Conexão.');
            } finally {
                setLoading(false);
            }
        }
        getData();
    }, [])

    return (
        <>
            { !loading && <Grid
                container
                xs={10}
                md={8}
                lg={11}
                xl={14}
                style={{ height: '100%', textAlign: 'center'}}
                justifyContent='center'>
                <CardHome>
                    <Header>
                        <img
                            src="https://images-front-soukw.s3.amazonaws.com/iconListings.svg"
                            alt="listings"
                            style={{ marginLeft: '12px', marginRight: '12px' }}
                        />
                        <p>Listings</p>
                    </Header>
                    <ContentHome>

                        <Card>
                            <Title>{total}</Title>
                            <Paragraph>Listings Ativos</Paragraph>
                            <Button onClick={() => navigate('/soukw/listings')}>Ver Todos</Button>
                        </Card>

                        <Divider />

                        <Card>
                            <Title>{xmlTotal}</Title>
                            <Paragraph>Listings no XML</Paragraph>
                            <Button onClick={() => navigate('/soukw/portals')}>Ver Todos</Button>
                        </Card>

                        <Divider />

                        <Card>
                            <Title>{legacyTotal}</Title>
                            <Paragraph>Listings Legados</Paragraph>
                            <Button onClick={() => navigate('/soukw/offXML')}>Ver Todos</Button>
                        </Card>
                    </ContentHome>
                </CardHome>

            </Grid>}
        </>
    )
}

export {
    GridHome
}