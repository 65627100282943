import { INTEGRACAO_PORTAIS } from './Constants';
import Requester from './Requester';

interface QueryParams {
    page?: string;
    limit?: string;
    agente?: string;
    listingId?: string;
    tipoListing?: string;
    status?: string;
    address?: string;
    neighbourhood?: string;
    city?: string;
    numero?: string;
    unidade?: string;
    condominio?: string;
    construtora?: string;
    tipoImovel?: string;
    valorMin?: string;
    valorMax?: string;
    metragemMin?: string;
    metragemMax?: string;
    quartos?: string;
    banheiros?: string;
    vagas?: string;
    sortByDate?: string;
    anunciados?: string;
    naoAnunciados?: string;
    anunciadosNoArquivo?: string;
    naoAnunciadosNoArquivo?: string;
    lteUpdatedAt?: string;
    gteUpdatedAt?: string;
    lteKwUpdatedAt?: string;
    gteKwUpdatedAt?: string;
    selectedFile?: string;
    documentKey?: string;
  }

  interface IIntegracaoPortaisDocument {
    id?: string;
    title?: string;
    suites?: number;
    bairroComercial?: string;
    locationType?: string;
    planType?: string;
    Active?: boolean;
    fileName?: string;
  }
export default class IntegracaoPortaisService {

    
    static async getListings( query: QueryParams ) {
        const queryString = JSON.stringify(query).replace(':', '=').replace(/\{\}/, '');
        console.log( queryString )
        const result = await Requester.requestBuilder( `${INTEGRACAO_PORTAIS}/validListings` + queryString, 'get');
        console.log( "Fetched Result.data is: ", result.data );
        return result.data;
    }

    static async saveNaventXML( listings: string[], xmlData: IIntegracaoPortaisDocument[] ) {
        console.log( listings );
        console.log( xmlData );
        const result = await Requester.requestBuilder( `${INTEGRACAO_PORTAIS}/saveXML`, 'post', '' , {listings, xmlData})
        console.log( result )
    }

}