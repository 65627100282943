import React, { createContext, useState, useEffect } from 'react';
import { User } from '../../types/User';
import { JwtUserI, UserI, token, AuthContextProps } from './authInterfaces';
import { useApi } from '../hooks/useApi';
import AuthService from '../services/api/authService';
import AuthContext from './AuthContext';
import axios from 'axios';



interface AuthProviderProps {
    children: JSX.Element;
}

//export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<UserI | null>(null);
    const [jwtUser, setJwtUser] = useState<JwtUserI | null>(null);
    const [authLoading, setAuthLoading] = useState(false);
    const [authError, setAuthError] = useState<Array<Error>>([]);
    const [jwtToken, setJwtToken] = useState<token>('');
    const api = useApi();

    const setPrevUser = (prevUser: UserI | null) => {
        setUser(prevUser);
    };

    const setPrevJwtUser = (prevJwtUser: JwtUserI | null) => {
        setJwtUser(prevJwtUser);
    };

    const setPrevJwtToken = (prevJwtToken: token | null) => {
        if (prevJwtToken)
            setJwtToken(prevJwtToken);
    };

    const signIn = async (email: string, password: string) => {
        setAuthLoading(true);
        try {
        const data = await AuthService(email, password);
        let date = new Date( Date.now() + 1000 * 60 * 60 * 8)
        console.log("Auth Data: ", data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify( data.user ) );
        localStorage.setItem('expiration', date.getTime().toString());
        localStorage.setItem('jwtUser', JSON.stringify( data.jwtUser ) );

            if (data.user && data.token) {
                setUser(data.user);
                setJwtToken(data.token)
                setJwtUser(data.jwtUser)
            }
        return data;
        
        } catch( err ) {
            if( err instanceof Error )
                setAuthError([err]);
            else
                setAuthError( [new Error('Erro ao efetuar login')] );
        } finally {
            setAuthLoading(false);
        }
    };

    const signOut = async () => {
        //await api.logout();
        setUser(null);
        localStorage.clear();
    };

    const contextValue: AuthContextProps = {
        
        user,
        jwtUser,
        jwtToken,
        authLoading,
        authError,
        setPrevUser,
        setPrevJwtUser,
        setPrevJwtToken,
        signIn,
        signOut,
    };

    useEffect(() => {
          console.log("Effect running")
          //alert( JSON.stringify(localStorage.getItem("token")) )
          const expirationDate = new Date( parseInt(localStorage.getItem("expiration") || '0' ) );
          console.log(Date.now())
          console.log(expirationDate.getTime())
          if( expirationDate.getTime() > Date.now() ) {
            console.log("Not Expired")
            if( localStorage.getItem("user") !== null)
              setPrevUser( JSON.parse(localStorage.getItem("user") || `{}` ) );
            if( localStorage.getItem("jwtUser") !== null)
              setPrevJwtUser( JSON.parse( localStorage.getItem("jwtUser") || '' ) );
            if( localStorage.getItem("token") !== null)
              setPrevJwtToken( localStorage.getItem("token") || ''  );
      }
      }, [jwtToken])

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;