import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const LegalSupportPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={8}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://images-front-soukw.s3.amazonaws.com/legaljudge.png"
                                title="Revoluti"
                                text="A Revoluti é uma startup que está com gás total e muito comprometimento para agilizar o processo das transações, então disponibilizo abaixo todos os termos acordados da parceria!"
                                acess={'https://app.revoluti.com.br/login'}
                                term={'https://drive.google.com/file/d/1fPILyJSYRPqPxhkwkOHYYmv0Ah632y_Z/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '26px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default LegalSupportPage;