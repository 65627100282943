import { Grid } from "@mui/material"
import { AcessButton, Container, Info, Logo, TermButton, Text, Title } from "./style"
import { Col, Row } from "antd";
import { CSSProperties } from "react";

interface styleProps {
    style: CSSProperties
}

interface cardProps {
    image: string;
    title: string;
    text: string;
    term: string;
    acess: string;
}

export const Card: React.FC<cardProps & styleProps> = (props) => {
    return (
        <Container>
            <Logo
                src={props.image}
            />
            <Col>
                <Info>
                    <Title>{props.title}</Title>
                    <Text>{props.text}</Text>
                </Info>
                <div style={props.style}>
                    <a href={props.acess} target='_blank'>
                        <AcessButton>Acessar</AcessButton>
                    </a>
                    <div style={{ width: 10 }} />
                    <a href={props.term} target='_blank'>
                        <TermButton>Termo de Parceria</TermButton>
                    </a>
                </div>
            </Col>
        </Container>
    )
}