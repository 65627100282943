import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IIntegracaoPortaisDocument, Listing } from './models/ListingsInterfaces';
import db from './models/ListingsDB';
import { useLiveQuery } from "dexie-react-hooks";

interface ObterXMLModalProps {
  open: boolean;
  onClose: () => void;
  // xmlFiles: any[];
}



const ObterXMLModal: React.FC<ObterXMLModalProps> = ({
  open,
  onClose}) => {
  const [newFileName, setNewFileName] = useState('');

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Gerenciar arquivos XML</Typography>
        <Divider sx={{ my: 2 }} />
        
        <Box mb={2}>
          <TextField
            label="Seu arquivo XML é:"
            value="caminho do arquivo"
            fullWidth
            disabled
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ObterXMLModal;
