import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import CardsCollections from "../../../shared/components/Cards/CardsCollections";
import { BackButton, CreateButton, EditButton } from "../style";
import { Navbar, Sidebar } from "../../../shared/components";
import NewTreinamentoCollection from "../../../shared/components/Modals/NewSubTrilhasTraining";
import CardsCourses from "../../../shared/components/Cards/CardsCourse";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

interface CollectionsPageProps {
    // Add any prop types if needed
}

const CollectionsPage: React.FC<CollectionsPageProps> = () => {
    const [collectionEdit, setCollectionEdit] = useState<null | any>(null); // Replace 'any' with the type of your collection data
    const [newTreinamentoModalOpen, setNewTreinamentoModalOpen] = useState(false);
    const navigate = useNavigate();

    const openNewTreinamentoModal = () => {
        setCollectionEdit(null);
        setNewTreinamentoModalOpen(true);
    };

    // Replace 'type' with the actual type you are checking against
    // Assuming it's a number based on 'type === 0' check
    const type = 0;

    const closeNewTreinamentoModal = () => {
        setNewTreinamentoModalOpen(false);
        // Additional logic if needed when closing the modal
    };

    return (
        <>
            <Navbar />
            <Sidebar
                Children={
                    <Grid>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                        <BackButton style={{ margin: '0px 0px 6px -18px' }} onClick={() => navigate(1)}>
                                <ArrowBackIosIcon sx={{ margin: '0px -6px -6px 0px', }} />
                                Voltar
                            </BackButton>
                            <EditButton>Editar</EditButton>
                            <CreateButton onClick={openNewTreinamentoModal}>
                                Criar Coleção
                            </CreateButton>
                        </div>
                        <Box sx={{ width: "100%" }}>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item
                                    key={1}
                                    xs={8}
                                    sm={8.5}
                                    md={6.2}
                                    lg={4.2}
                                    xl={3.2}
                                >
                                    <CardsCourses
                                        navigate="/soukw/training/courses/kwsp17147365032818195549469280436"
                                        subtitle="Subtitulo teste"
                                        title="Titulo teste"
                                        tumbnail="https://images-front-soukw.s3.amazonaws.com/default.png"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                }
            />
            <NewTreinamentoCollection
                open={newTreinamentoModalOpen || Boolean(collectionEdit)}
                close={closeNewTreinamentoModal}
                collection={collectionEdit}
            />
        </>
    );
};

export default CollectionsPage;
