// db.ts
import Dexie, { Table } from 'dexie';
import { User, MarketCenter } from './types';
import McsService from '../../../../shared/services/api/mcsService';

class UserManagementDB extends Dexie {
    users!: Table<User>;
    marketCenter!: Table<MarketCenter>;

    constructor() {
        super('UserManagementDB');
        this.version(1).stores({
            users: '++id,_id,email,fullName,permissionLevel,kwuid',
            marketCenters: '++id,_id,kwid,name'
        });
    }

    async getMarketCenter( userId: number ) {
        const user = await this.users.get( userId );
        const marketCenter = await this.marketCenter.where( '_id' ).equals( user?.marketCenterId! );
    }
}

const db = new UserManagementDB();
export default db;
