import { Box, Grid, CircularProgress } from "@mui/material";
import { BackButton, CreateButton, CreateCourseButton, EditButton } from "../style";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CardsCourses from "../../../shared/components/Cards/CardsCourse";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar, Sidebar } from "../../../shared/components";
import { useContext, useEffect, useState } from "react";
import TrainingService from "../../../shared/services/api/trainingService";
import axios from "axios";
import AuthContext from "../../../shared/context/AuthContext";
import NewSubTrilhaTraining from "../../../shared/components/Modals/NewSubTrilhasTraining";

interface CourseProps {
    _id: string;
    title: string;
    subtitle: string;
    cover: string;
}

const CoursesPage = () => {
    const [course, setCourse] = useState<CourseProps[]>([])
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState()
    const [collectionEdit, setCollectionEdit] = useState<null | any>(null); // Replace 'any' with the type of your collection data
    const [newTreinamentoModalOpen, setNewTreinamentoModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const { jwtUser, user } = useContext(AuthContext)
    const { id } = useParams();

    const openNewTreinamentoModal = () => {
        setCollectionEdit(null);
        setNewTreinamentoModalOpen(true);
    };

    const closeNewTreinamentoModal = () => {
        setNewTreinamentoModalOpen(false);
        // Additional logic if needed when closing the modal
    };

    const navigate = useNavigate();

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                const response = await TrainingService.getCollections()
                const treinamentosLista = response.trilhas.filter((e: { _id: string | undefined; }) => {
                    console.log(e)
                    console.log(id)
                    return id !== undefined ? e._id === id : false

                })[0].treinamentos
                setCourse(treinamentosLista.length > 0 ? treinamentosLista : [])
                console.log(treinamentosLista)
            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false);
            }
        }
        getData();
    }, [])

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Grid>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <BackButton onClick={() => navigate(-1)}>
                            <ArrowBackIosIcon sx={{ margin: '0px 0px -6px 6px', }} />
                            Voltar
                        </BackButton>
                        {user?.permissionLevelTreinamentos === 'EDITOR' && (
                            <>
                                {/* <CreateCourseButton onClick={() => navigate(`/soukw/training/create-course/${id}`)}>
                                    <AddCircleOutlineIcon sx={{ margin: '0px 6px -6px 14px' }} />
                                    Novo Curso
                                </CreateCourseButton> */}
                                <EditButton>Editar</EditButton>
                                <CreateButton onClick={openNewTreinamentoModal}>
                                    Criar Coleção
                                </CreateButton>
                            </>
                        )}
                    </div>
                    <Box sx={{ width: "100%" }}>
                    { loading &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    }
                        {course.length > 0 ? (
                            <Grid
                                container
                                rowSpacing={2}
                                paddingTop={2}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                {course.map((c) => {
                                    return (
                                        <Grid
                                            item
                                            key={c._id}
                                            xs={8}
                                            sm={8.5}
                                            md={6.2}
                                            lg={4.2}
                                            xl={3.2}
                                        >
                                            <CardsCourses
                                                navigate={`/soukw/training/classes-temp/${c._id}`}
                                                subtitle={c.subtitle}
                                                title={c.title}
                                                tumbnail={c.cover?.length > 0 ? c.cover : "https://images-front-soukw.s3.amazonaws.com/coursedefalt.png"}
                                            />
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                        ) : (
                            <p>Nenhum item encontrado.</p>
                        )}
                    </Box>
                </Grid >
            } />
            <NewSubTrilhaTraining
                open={newTreinamentoModalOpen || Boolean(collectionEdit)}
                close={closeNewTreinamentoModal}
                collection={collectionEdit}
            />
        </>
    )

}

export default CoursesPage;