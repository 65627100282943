import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const FinancialPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.5}
                            xl={5}
                            paddingTop={2}>
                            <Card
                                image="https://soukw.com.br/static/media/crediHabitarLogo.3aef0dc540a3e8f386c9.png"
                                title="CrediHabitar"
                                text="Plataforma multibanco com oferta de crédito imobiliário (financiamento, portabilidade e home equity)."
                                acess={'https://painel.credihabitar.com.br/admin/authentication'}
                                term={'https://drive.google.com/file/d/1HTJ3nUU72qzq_2qnKHHWobHCM7lHNa3M/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '38px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>

                        <div style={{
                            width: '2rem'
                        }}>
                        </div>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default FinancialPage;