import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const PortalsPage = () => {

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.5}
                            xl={5.1}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://images-front-soukw.s3.amazonaws.com/aptoportal.png"
                                title="APTO"
                                text="O Apto é uma plataforma de lançamentos imobiliários. Que possibilita que as empresas anunciem imóveis de lançamento e recebam leads mais qualificados."
                                acess={'https://apto.vc/login'}
                                term={'https://drive.google.com/file/d/1Ce83-a8gkGrhZ8JeLepU1vFgHYkspO-l/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '24px',
                                    marginLeft: '10px'
                                }}

                            />
                        </Grid>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.5}
                            xl={5}
                            paddingTop={2}
                            paddingLeft={2}>
                            <Card
                                image="https://soukw.com.br/static/media/olxLogo.32e651e97d1f8c048e62.png"
                                title="OLX"
                                text="A OLX é a empresa dona dos portais ZAP Imóveis, OLX imóveis, e Vivareal, aumentando assim a visibilidade dos anúncios, e trazendo mais uma possibilidade de divulgação de imóveis."
                                acess={'https://conta.olx.com.br/acesso'}
                                term={'https://drive.google.com/file/d/10EtjWoimTRlKC2ifqpcGqCwTTfLZymaY/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '24px',
                                    marginLeft: '10px'
                                }}

                            />
                        </Grid>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default PortalsPage;