import styled from "@emotion/styled";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Header = styled.div`
    text-align: start;
    font-size: medium;
    display: flex;
    flex-direction: row;
    background-color: #FFF;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0 1px 5px 0 #d7d9dc;
    width: 100%;
`;

export const CancelButton = styled.button`
    width: 126px;
    height: 46px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: calc(40% - 26px);
    font-size: 16px;
    font-weight: bold;
    color: #1C5BBD;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
`;

export const CreateNewCollectionButton = styled.button`
    width: 126px;
    height: 46px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    font-size: 16px;
    color: #FFFFFF;
    border: transparent;
    border-radius: 6px;
    background-image: linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1)) ;
    cursor: pointer;
`;

export const Footer = styled.div`
    text-align: start;
    font-size: medium;
    display: flex;
    flex-direction: row;
    background-color: #f7f5f5;
    width: 100%;
`;

export const CancelButton2 = styled.button`
    width: 126px;
    height: 46px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: calc(40% - 26px);
    font-size: 16px;
    font-weight: bold;
    color: #1C5BBD;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
`;