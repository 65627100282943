import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Switch, Box } from '@mui/material';
import EditListingModal from './EditListingModal';
import { Listing } from './models/ListingsInterfaces';
import db from './models/ListingsDB';

interface ListingCardProps {
  listing: Listing;
  fileName: string;
}

const ListingCard =  ({ listing, fileName='' }: ListingCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInXml, setIsInXml] = useState(listing.inXML);

  const handleToggleXml = async () => {
    console.log(listing.id)
    let lst = await db.listings.where(  {_id: listing._id} ).modify( {...listing, inXML: !isInXml})
    //let result = await db.listings.update( {_id: listing._id}, {inXML: !isInXml})
    setIsInXml(!isInXml);
    // Here you could add logic to update the listing's XML status in your backend.
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card>{ 
        listing.photos && listing.photos.length > 0 && //Some listings have no pictures at all
          <CardMedia
            component="img"
            height="140"
            image={listing.photos[0].ph_url}  // Assuming listing.image contains the image URL
            alt={listing.title}
          />
        }
        <CardContent>
          <Typography variant="h6">{listing.title}</Typography>
          <Typography variant="body2">{listing.list_address.full_street_address}</Typography>
          <Typography variant="body2">Valor: R$ {listing.current_list_price}</Typography>
          <Typography variant="body2">Agente: {listing.list_kw_uid}</Typography>
          <Typography variant="body2">Número MLS: {listing.mls_number}</Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
            <Typography variant="body2">In XML:</Typography>
            <Switch checked={isInXml} onChange={handleToggleXml} />
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" fullWidth onClick={handleOpenModal}>
              Editar Anúncio
            </Button>
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" fullWidth onClick={handleOpenModal}>
              Ver Detalhes
            </Button>
          </Box>
        </CardContent>
      </Card>
      <EditListingModal
        open={isModalOpen}
        onClose={handleCloseModal}
        listing={listing}
        fileName={fileName}
      />
    </>
  );
};

export default ListingCard;
