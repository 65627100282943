import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Navbar, Sidebar } from "../../../shared/components";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Card, Column, Row, Title, TextField, SelectField, DragCard } from "../Course/components/style";
import LessonDetailsForm from "./LessonDetailsForm";
import { LearnEntityType, LessonDetailsFormI } from "./lessonInterfaces";

interface TumbFormProps {
    title: string;
    // value: any;
    // onChange: any;
};

interface CourseFromProps {
    title: string;
    value: any;
    onChange: any;
};

const CreateLessonPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const currentLocation = useLocation().pathname;
    const createLesson = () => {

    }

    let elementType: LearnEntityType = 'course';


    if( currentLocation.match(/\-course/ ) )
         elementType = 'course';
    if( currentLocation.match(/\-training/ ) )
        elementType = 'training';
    if( currentLocation.match(/\-rail/ ) )
        elementType = 'rail';

    return (
        <>
            <Navbar />
            <Sidebar Children={
                    <Row>
                        { params.id !== undefined ? 
                            <LessonDetailsForm type={elementType} id={params.id} /> :
                            <LessonDetailsForm type={elementType} />
                        }
                    </Row>
            } />
        </>
    )
}

export default CreateLessonPage;