import styled from "@emotion/styled";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
 display: flex;
 flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background: #FFF;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
  height: 12rem;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const Logo = styled.img`
  position: relative;
  bottom: 8%;
  right: 2px;
  border-radius: 6px 0px 0px 6px;
  height: 12.4rem;
  width: 13.5rem; 
`;

export const Info = styled.div`
  position: relative;
  top: 2px;
  margin-left: 10px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 12px;
`;

export const AcessButton = styled.button`
  font-size: 14px;
  color: #FFFFFF;
  border: transparent;
  border-radius: 25px;
  height: 2rem;
  width: 6rem;
  background-color: #1C5CBC;
`;

export const TermButton = styled.button`
  font-size: 14px;
  height: 2rem;
  width: 10rem;
  color: #1C5BBD;
  border: 1px solid transparent;
  border-radius: 25px;
  background-color: transparent;
  cursor: pointer;
`;

export const CardTraining = styled.div`
  border-radius: 6px;
  background: #FFF;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 20rem;
  height: 16rem;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardCourses = styled.div`
  border-radius: 6px;
  background: #FFF;
  padding-top: 0;
  padding-bottom: 15px;
  width: 20rem;
  height: 16rem;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  margin-top: 6px;
`;

export const CourseLogo = styled.img`
  height: 8.9rem;
  width: 20rem;
  border-radius: 0, 6px, 6px, 6px;
`;

export const CardsClasses = styled.div`
  border-radius: 6px;
  background: #FFF;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 500px;
  height: 760px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const CardVideo = styled.div`
  border-radius: 6px;
  background: #FFF;
  padding-top: 12px;
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`;

export const Player = styled.video`
  height: 100%;
  width: 100%;
`;

export const ContainerDash = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background: #FFF;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
  height: 8rem;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`

export const ContentDash = styled.div`
  justify-content: center;
  align-content: center;
  align-items: center ;
  text-align: center;
  align-self: center;
`;

export const TitleDash = styled.p`
  font-size: large;
`

export const ButtonDash = styled.div`
  font-size: 16px;
  color: #1C5BBD;
  border: 1px solid #1C5BBD;
  border-radius: 6px;
  height: 28px;
  width: 10rem;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 6px;
`;

export const DragCard = styled.div`
display: flex;
flex-direction: row;
box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
background: #ECECEC;
border-radius: 6px;
width: 30rem;
height: 2rem;
margin-left: 12px;
margin-top: 12px;
margin-bottom: 12px;
`;

export const LabelUpload = styled.label`
  width: 460px;
  height: auto;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  border: 2px dashed currentcolor;
  border-radius: 6px;
  cursor: pointer;
  font-family: sans-serif;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  outline: none;
  overflow: hidden;
  :hover {
    color: #777;
    background: #ccc;
  };
  :active {
    border-color: turquoise;
    color: turquoise;
    background: #eee;
  }
`;

export const LabelFile = styled.label`
  width: 460px;
  height: 100px;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  border: 2px dashed currentcolor;
  border-radius: 6px;
  cursor: pointer;
  font-family: sans-serif;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  outline: none;
  overflow: hidden;
  :hover {
    color: #777;
    background: #ccc;
  };
  :active {
    border-color: turquoise;
    color: turquoise;
    background: #eee;
  }
`;

export const PictureImage = styled.div`
  max-width: 100%;
`;

export const PictureVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
`;

export const InputUpload = styled.input`
  display: none;
`;

export const TitleClasse = styled.h1`
 margin-top: 0;
 margin-left: 18px;
`;

export const VideoCss = styled.div`
 align-items: center;
 margin-left: 12px;
 margin-right: 12px;
 margin-bottom: 12px;
`;

export const DescriptionClasse = styled.div`
 color: gray;
 margin-left: 18px;
`;

export const TextClasse = styled.p`
 margin-left: 18px;
`;

export const TitleMoreClasses = styled.h1`
 margin-left: 12px;
 font-size: 16px;
 font-weight: bold;
`;