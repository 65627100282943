import { useEffect, useState } from 'react';
import { Grid, CircularProgress, Box } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams, Link } from "react-router-dom";
import { Navbar, Sidebar } from "../../../shared/components";
import { BackButton, Row } from "../style";
import CardClasses from "../../../shared/components/Cards/CardClasses";
import MoreClasses from "../../../shared/components/Cards/MoreClasses";
import TrainingService from '../../../shared/services/api/trainingService';
import CardsCourses from "../../../shared/components/Cards/CardsCourse";
import LessonsPage from './LessonsPage';
import YouTubePlayer from "./YouTubePlayer";

//v.1.0.0
interface LessonsProps {
    _id: string;
    title: string;
    subtitle: string;
    cover: string;
    entityType?: string;
    type?: "SYSTEM" | "SOUKW" | "KWW" | "CULTURE" | "MODELS";
    role?: "AGENT" | "LEADERSHIP" | "ALL";
}

const LessonPage = () => {

    const GridCard = ( {elementType, learningEntity, mode}:{elementType: string, learningEntity: LessonsProps, mode: "EDITOR"|"STUDENT" } ) => {
        const navigate = useNavigate();
        return (

                <Grid
                item
                key={learningEntity._id}
                xs={8}
                sm={8.5}
                md={6.2}
                lg={4.2}
                xl={3.2}
                onClick={() => navigate(mode==='EDITOR' ? 
                `/soukw/training/create-${elementType}/${learningEntity._id}` :
                `/soukw/training/lesson/${learningEntity._id}`)}
            >
                <CardsCourses
                
                    navigate={mode==='EDITOR' ? 
                    `/soukw/training/create-${elementType}/${learningEntity._id}` :
                    `/soukw/training/lesson/${learningEntity._id}`
                    }
                    subtitle={learningEntity.subtitle}
                    title={learningEntity.title}
                    tumbnail={learningEntity.cover?.length > 0 ? learningEntity.cover : "https://images-front-soukw.s3.amazonaws.com/coursedefalt.png"}
                />
            </Grid>

            )
        }

    const navigate = useNavigate();
    const { id } = useParams();
    const [course, setCourse] = useState({
        _id: '',
        title: '',
        subtitle: '',
        cover: '',
        chapters: [],
        description: '',
        videoFile: '',
        extras: '',
        relations: []
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const response = await TrainingService.getLearnEntity(id)
                console.log(response)
                setCourse(response);
            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [id])

    return (
        <>
            <Navbar />

            <Sidebar Children={
                <Grid>
                    <div>
                        <BackButton style={{ margin: '0px 0px 6px -18px' }} onClick={() => navigate(-1)}>
                            <ArrowBackIosIcon sx={{ margin: '0px -6px -6px 0px', }} />
                            Voltar
                        </BackButton>
                    </div>
                    {
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {loading && <CircularProgress />}
                        </div>
                    }
                    {!loading &&
                        <div>
                        <Row>
                            <CardClasses
                                data=""
                                description={ course.description ?? '' }
                                subtitle={course.subtitle ?? ''}
                                title={course.title ?? ''}
                                url={course.videoFile ?? ''}
                                urlPdf={course.extras?.length > 0 ? course.extras : ''}
                            />
                            <div style={{ width: '1rem' }} />
                            { /*
                            <MoreClasses
                                title={`${course.title}, aulas a seguir`}
                                navigate=''
                                nextClasse=''
                            />*/}
                        </Row>
                        { (course.relations && course.relations.length > 0) ? (
                            <Box sx={{ width: "100%" }}>
                                <Grid
                                    container
                                    rowSpacing={2}
                                    paddingTop={2}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                {course.relations.map((item: any) => {
                                    const c = item.id;
                                    console.log('C is')
                                    return (
                                        <GridCard 
                                            elementType={c.type} 
                                            learningEntity={c} 
                                            mode="STUDENT"/>
                                    )})
                                }
                                {course.relations.filter( (el: any) => el.entityType === 'TRAINING').map((item: any) => {
                                    const c = item.id;
                                    return (
                                        <GridCard elementType={c.elementType} learningEntity={c} mode="STUDENT"/>
                                    )})
                                }
                                {course.relations.filter( (el: any) => el.entityType === 'COURSE').map((c: any) => {
                                    return (
                                        <GridCard elementType={c.elementType} learningEntity={c} mode="STUDENT"/>
                                    )})
                                }
                            </Grid>
                            </Box>
                            ) : (
                            <p>Nenhum item encontrado.</p>
                            )
                        }
                    </div>
                    }    
                </Grid>

            } />
        </>
    )

}

export default LessonPage;