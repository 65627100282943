import { Box, Grid, LinearProgress, LinearProgressProps, Stack, Typography } from "@mui/material";
import { CardCourses, Content, CourseLogo, Header, Row } from "./style";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from "react-router-dom";
import React, { useState } from "react";

interface CardCourseProps {
    title: string;
    subtitle: string;
    // classes: any;
    tumbnail: string;
    navigate: string;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                {/* <LinearProgress variant="determinate" {...props} /> */}
            </Box>
            <Box sx={{ minWidth: 35 }}>
                {/* <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography> */}
            </Box>
        </Box>
    );
}

const CardsCourses: React.FC<CardCourseProps> = (props) => {
    const [progress, setProgress] = React.useState(10);



    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item
                        xs={5}
                        sm={5}
                        md={4}
                        lg={4}
                        xl={3.5}
                    >
                        {/* <Row style={{
                            position: 'absolute',
                            top: '35%',
                            marginLeft: '17%'
                        }}>
                            <BookmarkBorderIcon />
                            <LinkIcon />
                        </Row> */}
                        <Link to={props.navigate} >
                            <CardCourses>
                                <Header style={{ justifyContent: 'center' }}>
                                    <CourseLogo
                                        src={props.tumbnail}
                                        alt="Sistemas"
                                    />
                                </Header>
                                <Content>
                                    <p
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                            textTransform: 'capitalize', margin: 0
                                        }}
                                    >
                                        {props.title}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: '10px',
                                            fontWeight: 'lighter',
                                            color: '#000',
                                            textTransform: 'capitalize',
                                            margin: 0
                                        }}
                                    >
                                        {props.subtitle}
                                    </p>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                                        {/* <img
                                            style={{ width: '24px', height: '24px' }}
                                            src="https://images-front-soukw.s3.amazonaws.com/countcourse.svg"
                                        /> */}
                                        <p
                                            style={{
                                                fontSize: '10px',
                                                fontWeight: 'lighter',
                                                color: '#000',
                                                textTransform: 'capitalize',
                                                margin: 6
                                            }}
                                        >
                                            {/* {props.classes} aulas */}
                                        </p>
                                    </div>
                                    <Box>
                                        <LinearProgressWithLabel value={progress} color="info" />
                                    </Box>
                                </Content>
                            </CardCourses>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CardsCourses;