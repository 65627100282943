import axios, { AxiosResponse } from "axios";
import { COURSE_ENDPOINT, TRAINING_ENDPOINT, LESSON_ENDPOINT, LEARN_ENTITY_ENDPOINT } from "./Constants";
import { axiosConfig } from "./helper";
//import { token } from ".";
//const token = localStorage.getItem("token");



export default class TrainingService {

    private static async requestBuilder( 
        endPoint: string,
        method: 'get' | 'post' | 'patch' | 'put' | 'delete',
        id='',
        body = {}) {
        let response: AxiosResponse;
        console.log( "Request Data is: ", id, body, method, endPoint  );
        if( id.length > 0){
            if( Object.keys(body).length > 0 )
                response = await axios[method](`${endPoint}/${id}`, body, axiosConfig( ));
            else
                response = await axios[method](`${endPoint}/${id}`, axiosConfig( ));}
        else
            if( Object.keys(body).length > 0 )
                response = await axios[method](endPoint,  body, axiosConfig( ));
            else
                response = await axios[method](endPoint,  axiosConfig( ));
        return response.data;
    }

    static async getCollections(  ) {
        return await TrainingService.requestBuilder(TRAINING_ENDPOINT, 'get');
    }

    static async getCollection(id: string) {
        return await TrainingService.requestBuilder(TRAINING_ENDPOINT, 'get', id);
    }

    // TRILHAS SISTEMAS //
    static async getCollectionsSystem() {
        return await TrainingService.requestBuilder(TRAINING_ENDPOINT, 'get')
    }

    // TRILHAS SOUKW //
    static async getCollectionsSoukw() {
        return await TrainingService.requestBuilder(TRAINING_ENDPOINT, 'get')
    }


    //////////////////////

    static async createCollection(training: any ) {
        return await TrainingService.requestBuilder(TRAINING_ENDPOINT, 'post', '', training)

    }

    static async updateCollection(id: string, training: any) {
        return await TrainingService.requestBuilder(TRAINING_ENDPOINT, 'patch', id, training)
    }

    static async deleteCollection(id: string) {
        return await TrainingService.requestBuilder(TRAINING_ENDPOINT, 'delete', id)
    }

    // Sub-Trilhas - Analisar para remover, não faz sentido esse método
    static async createCollectionTrilha(id: string, training: any) {
        return await TrainingService.requestBuilder(`${TRAINING_ENDPOINT}`, 'put', training)
    }

    // TREINAMENTOS back ok//
    static async getTreinamentos(id = '') {
        return await TrainingService.requestBuilder(COURSE_ENDPOINT, 'get', id)
    }

    static async getTotalTreinamentos() {
        return await TrainingService.requestBuilder(COURSE_ENDPOINT, 'get')
    }

    static async createTreinamento(training: any) {
        return await TrainingService.requestBuilder(COURSE_ENDPOINT, 'post', '', training)
    }

    static async updateTreinamento(id: string, training: any) {
        console.log("requesting training: ", training._id);
        return await TrainingService.requestBuilder(COURSE_ENDPOINT, 'patch', id, training)
    }

    static async createLesson( lesson: any ) {
        return await TrainingService.requestBuilder(LESSON_ENDPOINT, 'post', '', lesson)
    }

    static async updateLesson( lesson: any ) {
        return await TrainingService.requestBuilder(LEARN_ENTITY_ENDPOINT, 'patch', lesson._id, lesson)
    }

    static async getLesson(id: string = '') {
        return await this.requestBuilder(LESSON_ENDPOINT, 'get', id)
    }

    static async getLearnEntity(id: string = '') {
        return await this.requestBuilder(LEARN_ENTITY_ENDPOINT, 'get', id)
    }

    static async deleteLearnEntity(id: string = '') {
        return await this.requestBuilder(LEARN_ENTITY_ENDPOINT, 'delete', id)
    }



}
