import React from "react";
import { useState } from "react";
import { Card } from "../Course/components/style";
import { getUploadUrl, handleUpload, objectUrl } from "../../../shared/services/api/S3Service";
import { LabelUpload, PictureImage, VideoCss } from "../../../shared/components/Cards/style";
import { Row } from "../style";
import { Column } from "../../Profile/style";
import { Button } from "@mui/material";
import YouTubePlayer from "./YouTubePlayer";

/**
 * @author: Thiago Glauco Sanchez
 * 
 * The component renders a Card component with a header, a paragraph, an input element for selecting a file,
 * and a button for uploading the file. The progress of the upload is displayed using a progress bar and a text element.
 * 
 * Props:
 * type: The type of file to be uploaded (video, image, or document)
 * title: The title of the component
 * meaning: The purpose of the component (cover, content video, content image, or content document)
 * S3Directory: The S3 directory where the file will be uploaded
 * errorHandler: A function that receives an array of error messages
 * setUploadFileUrl: A function that receives the URL of the uploaded file
 * 
 * TODOS:
 * 1. Object Preview if preexists
 * 2. Delete button - to remove the file from the S3 directory
 * 3. type protection to avoid uploading the same file twice or uploading files fo other types.
 * 4. Error handling
 */

interface contentUploaderProps {
    type: 'video' | 'youtube' | 'image' | 'document';
    S3Directory: string;
    title: string;
    meaning: 'cover' | 'contentVideo' | 'contentImage' | 'contentDocument';
    errorHandler: (errors: string) => void;
    setUploadFileUrl: (url: string) => void;
    previousContentUrl?: string;
    currentUrl?: string;
}
const ContentUploader = (props: contentUploaderProps) => {
    const {
        type,
        title,
        meaning,
        S3Directory,
        errorHandler,
        setUploadFileUrl,
        currentUrl } = props;
    const [uploadingFeedback, setUploadingFeedback] = useState(0);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [youtubeUrl, setYoutubeUrl] = useState<string>(currentUrl ?? '' );


    const handleFileChange = (event: any) => {
        setSelectedFile(event.target!.files[0]);
    }

    const getUploadPercentage = (percentage: number) => {
        console.log(percentage);
        setUploadingFeedback(percentage);
    }

    const uploadFile = async (e: any) => {
        //e.preventDefault()
        let coverUrl: string;
        if (S3Directory !== undefined && selectedFile) {
            try {
                let response = await getUploadUrl(S3Directory, selectedFile.name, selectedFile.type)
                console.log('response url is: ', response.url)
                const uploadingResult = await handleUpload(selectedFile, response.url, getUploadPercentage)
                const getUrlResponse = await objectUrl('eadsoukw', `${S3Directory}/${selectedFile.name}`)
                setUploadFileUrl(getUrlResponse.url);
            } catch (error: any) {
                console.error(error);
                errorHandler(error.message);
                //alert(error.message);
            }
        }
    }

    return (
        <Card>
            <Row style={{ margin: '6px 0px 0px 6px' }}>
                <h3>{title}</h3>
                <div style={{ width: '24px' }} />
                {type !== 'youtube' &&   <button
                        style={{
                            width: '10rem',
                            height: '2.5rem',
                            marginTop: '6px',
                            color: '#ffff',
                            border: '0.1px solid #1C5BBD',
                            borderRadius: '6px',
                            backgroundImage: 'linear-gradient(to right, rgba(24,133,157,0.9), rgba(24,98,188,1))',
                            cursor: 'pointer',
                            textAlign: 'center'
                            
                        }}
                        onClick={(e) => { uploadFile(e) }}
                    >
                        Upload {type}
                    </button>}
            </Row>
<Column style={{
                alignItems: 'center',
                marginTop: '1.5rem',
                marginBottom: '1.5rem'
            }}>
                {type !== 'youtube' ? <LabelUpload>
                    <div style={{ textAlign: 'start' }}>
                        <Row>
                            <input
                                // style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => (handleFileChange(e))}
                            />
                            <div style={{ marginLeft: '1.5rem' }}>
                                <progress value={uploadingFeedback} max="100"></progress>
                                <div>Subindo {type}:{uploadingFeedback}%</div>
                            </div>
                        </Row>

                        {currentUrl && type === 'image' &&
                            <img src={currentUrl} alt={meaning} width='100%'></img>
                        }
                        {currentUrl && type === 'document' &&
                            <object
                                data={currentUrl}
                                type="application/pdf" width="100%" height="450px">
                                <p>Unable to display PDF file. </p>
                            </object>
                        }
                        {currentUrl && type === "video" &&
                            <VideoCss>

                                <video width="440" controls>
                                    <source src={currentUrl} type="video/mp4" />
                                    <source src="movie.ogg" type="video/ogg" />
                                </video>

                            </VideoCss>
                        }
                    </div>
                </LabelUpload> : <div>
                    <Row>
                            <input
                                // style={{ display: 'none' }}
                                value={ youtubeUrl }

                                type="text"
                                onChange={( e ) => {
                                    setYoutubeUrl( e.target.value );
                                }}
                            />
                            <Button 
                                onClick={() => {
                                    if( youtubeUrl.length > 0 )
                                        setUploadFileUrl( youtubeUrl.trim() );}
                                    }
                             >OK</Button>

                        </Row>
                        <YouTubePlayer url={currentUrl!}/>

                        {/* <VideoCss>

                        <video width="440" controls>
                            <source src={currentUrl} type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                        </video>
                        </VideoCss> */}

                    </div>}
            </Column>
        </Card >
    )
}

export default ContentUploader;