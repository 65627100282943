import React, {useState, useEffect} from 'react';
import { Card, CardContent, Button, CardActions, Box, Switch } from '@mui/material';
import ManageXMLFilesModal from './ManageXMLFilesModal';
import ObterXMLModal from './ObterXMLModal';
import SaveXMLModal from './SaveXMLModal';
import EditListingModal from './EditListingModal';
import {Collection} from 'dexie';
import db from './models/ListingsDB';

interface BulkActionsProps {
  selectionQuery: Collection;
  paginatedQuery: Collection;
  refresher: ( callback: (prev: boolean ) => boolean) => void;
  page: number;
  setFile: (file: string)=>void;
}
const BulkActions = ( {selectionQuery, paginatedQuery, refresher, page, setFile}: BulkActionsProps ) => {

    const [isXmlModalOpen, setIsXmlModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isObterXMLModalOpen, setIsObterXMLModalOpen] = useState(false);
    const [isSaveXMLModalOpen, setIsSaveXMLModalOpen] = useState(false);
    const [isPageChecked, setPageChecked] = useState( false );
    const [isFilterChecked, setFilterChecked ] = useState( false );
    const [selectionQueryState, setSelectionQueryState] = useState( selectionQuery )
    const [ pageStatus, setPageStatus] = useState( page )
    const [selectedFile, setSelectedFile] = useState( '' );

  // Mock XML files data
  const mockXmlFiles = [
    { id: 1, name: 'Listings-August.xml' },
    { id: 2, name: 'Listings-September.xml' },
    // Add more mock XML files as needed
  ];

  const handlePageSelection = async ( ) => {
      
    
    if( isPageChecked ){
      const countResult = await paginatedQuery.modify( {inXML: false} );
      console.log( "modified: ", countResult);
      
    }else{
      const countResult = await paginatedQuery.modify( {inXML: true} );
      console.log( "modified: ", countResult);
      
    }
    setPageChecked( prev => !prev );
    refresher( prev => !prev )
  }

  const handleFilterSelection = async ( ) => {
    if( isFilterChecked ){
      const countResult = await selectionQuery.modify( {inXML: false} );
      console.log( "modified: ", countResult);
      
    }else{
      const countResult = await selectionQuery.modify( {inXML: true} );
      console.log( "modified: ", countResult);
      
    }
    setFilterChecked( prev => !prev );
    refresher( prev => !prev )
  }

  const handleOpenXmlModal = () => {
    setIsXmlModalOpen(true);
  };

  const handleCloseXmlModal = () => {
    setIsXmlModalOpen(false);
  };

  const handleCreateXmlFile = async (fileName: string) => {
    console.log(`Creating XML file: ${fileName}`);
    setSelectedFile( fileName );
    setFile( fileName );
    // Logic to create XML file goes here
  };

  const handleDeleteXmlFile = (fileName: string) => {
    console.log(`Deleting XML file with ID: ${fileName}`);
    // Logic to delete XML file goes here
  };

  const handleSelectXmlFile = (fileName: string) => {
    console.log(`Selected XML file with ID: ${fileName}`);
    setSelectedFile( fileName );
    // Logic to select and edit XML file goes here
  };

  const handleOpenEditModal = ( ) => {
    setIsEditModalOpen( true )
  }

  const handleCloseEditModal = ( ) => {
    setIsEditModalOpen( false );
  }

  useEffect( ( ) => {
    setPageChecked( false );
  }, [page])

  useEffect( ( ) => {
    setFilterChecked( false );
  }, [selectionQueryState])

  return (
    <>
        <Button variant="contained" color="success" onClick={handleOpenXmlModal}>1. Selecione seu XML</Button>
        <Button variant="contained" color="primary">2. Filtrar Listings</Button>
        <Button variant="contained" color="secondary" onClick={handleOpenEditModal}>3. Editar Selecionados</Button>
        <Button variant="contained" color="warning" onClick={( ) => setIsSaveXMLModalOpen( true )}>4. Gerar XML</Button>
        <Button variant="contained" color="success" onClick={() => setIsObterXMLModalOpen(true)}>5. Obter Link XML</Button>
        <Box>
            Selecionar visíveis<Switch onChange={handlePageSelection} checked={isPageChecked}/>
        </Box>
        <Box>
            Selecionar pelo filtro<Switch onChange={handleFilterSelection} checked={isFilterChecked}/>
        </Box>
        <Box>
            Arquivo: {selectedFile}
        </Box>
      <ManageXMLFilesModal
        open={isXmlModalOpen}
        onClose={handleCloseXmlModal}
        // xmlFiles={mockXmlFiles}
        onCreate={handleCreateXmlFile}
        onDelete={handleDeleteXmlFile}
        onSelect={handleSelectXmlFile}
      />
      {selectedFile.length > 0 && <>
      <EditListingModal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        listing={null}
        fileName={selectedFile}
        />
        <ObterXMLModal
          open={isObterXMLModalOpen}
          onClose={() => setIsObterXMLModalOpen( prev => !prev )}
        />
        <SaveXMLModal
          open={isSaveXMLModalOpen}
          fileName={selectedFile}
          onClose={() => setIsSaveXMLModalOpen( prev => !prev )}
        />
        </>}
    </>

  );
};

export default BulkActions;
