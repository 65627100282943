import { useState, useContext, useEffect } from "react"
import { Navbar, Sidebar } from "../../shared/components";
import { AddButton2, RegisterButton, Card, Card2, Column, Container, BackButton, Row } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, MenuItem, Button, Switch } from '@mui/material';
import { axiosConfig } from "../../shared/services/api/helper";
import axios from "axios";
import TransmittalService from "../../shared/services/api/transmittalService";
import AuthContext from "../../shared/context/AuthContext"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import maskCurrency from "../../shared/enums/MaskCurrency";

interface CreateTransmittal {
    _id: string;
    month: string;
    year: string;
    editor: string;
    marketCenter: string;
    teams: string;
    totalAppointments: string;
    totalAppointmentsR2: string;
    listingsAtivosUnids: string;
    listingsAtivosVgv: string;
    grossAgentsBruto: string;
    grossAgentsProducao: string;
    totalDespesasMc: string;
    totalCompanyCurrency: string;
    otherMcIncome: string;
    totalMcRevenue: string;
}

const EditTransmittalPage = () => {
    const [totalAppointments, setTotalAppointments] = useState<string | undefined>();
    const [totalAppointmentsR2, setTotalAppointmentsR2] = useState<string | undefined>();
    const [listingsAtivosUnids, setListingsAtivosUnids] = useState<string | undefined>();
    const [listingsAtivosVgv, setListingsAtivosVgv] = useState<string | undefined>();
    const [grossAgentsBruto, setGrossAgentsBruto] = useState<string | undefined>();
    const [grossAgentsProducao, setGrossAgentsProducao] = useState<string | undefined>();
    const [totalDespesasMc, setTotalDespesasMc] = useState<string | undefined>();
    const [totalCompanyCurrency, setTotalCompanyCurrency] = useState<string | undefined>();
    const [otherMcIncome, setOtherMcIncome] = useState<string | undefined>();
    const [totalMcRevenue, setTotalMcRevenue] = useState<string | undefined>();
    const [editor, setEditor] = useState<string | undefined>();
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const { user, jwtUser } = useContext(AuthContext)
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        async function getData() {
            if (id) {
                try {
                    const response = await TransmittalService.getTransmittal(id);

                    console.log(response);
                    setEditor(user?.fullName)
                    setMonth(response.month);
                    setYear(response.year);
                    setTotalAppointments(response.totalAppointments);
                    setTotalAppointmentsR2(response.totalAppointmentsR2);
                    setListingsAtivosUnids(response.listingsAtivosUnids);
                    setListingsAtivosVgv(response.listingsAtivosVgv);
                    setGrossAgentsBruto(response.grossAgentsBruto);
                    setGrossAgentsProducao(response.grossAgentsProducao);
                    setTotalDespesasMc(response.totalDespesasMc);
                    setTotalCompanyCurrency(response.totalCompanyCurrency);
                    setOtherMcIncome(response.otherMcIncome);
                    setTotalMcRevenue(response.totalMcRevenue);
                } catch (error) {
                    console.error('Error fetching transmittal:', error);
                }
            } else {
                console.error('id is undefined');
            }
        }

        getData();
    }, [id]); // Include id in the dependency array to call getData when id changes


    async function handleUpdateTransmittal() {
        if (!totalAppointments ||
            !totalAppointmentsR2 ||
            !listingsAtivosUnids ||
            !listingsAtivosVgv ||
            !grossAgentsBruto ||
            !grossAgentsProducao ||
            !totalDespesasMc ||
            !totalCompanyCurrency ||
            !totalMcRevenue
        ) {
            alert('Preencha todos os campos.');
            return;
        };

        let json = {
            editor,
            totalAppointments,
            totalAppointmentsR2,
            listingsAtivosUnids,
            listingsAtivosVgv,
            grossAgentsBruto,
            grossAgentsProducao,
            totalDespesasMc,
            totalCompanyCurrency,
            otherMcIncome,
            totalMcRevenue,
        };
        if (id) {
            try {
                const updateTransmittal = await TransmittalService.updateTransmittal(id, json);
                alert("Transmittal atualizado com sucesso.")
            } catch (error: any) {
                console.log(error.response.message)
                alert(error.message);
            }
        }
    }

    return (
        <>
            <Navbar />
            <Sidebar Children={
                <form>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <BackButton onClick={() => navigate('/soukw/transmittal')}>
                            <ArrowBackIosIcon sx={{ margin: '6px 6px -6px 0px' }} />
                            Voltar
                        </BackButton>
                        <Container>
                            <Row>
                                <Column>
                                    <TextField
                                        variant='outlined'
                                        select
                                        label='Mês'
                                        color='secondary'
                                        disabled
                                        required
                                        style={{
                                            width: '10rem',
                                            marginTop: '6px',
                                            marginLeft: '6px',
                                            marginRight: '6px',
                                            marginBottom: '6px',
                                        }}
                                        onChange={(e) => setMonth(e.target.value)}
                                        value={month}
                                    >
                                        <MenuItem value={1}>Janeiro</MenuItem>
                                        <MenuItem value={2}>Fevereiro</MenuItem>
                                        <MenuItem value={3}>Março</MenuItem>
                                        <MenuItem value={4}>Abril</MenuItem>
                                        <MenuItem value={5}>Maio</MenuItem>
                                        <MenuItem value={6}>Junho</MenuItem>
                                        <MenuItem value={7}>Julho</MenuItem>
                                        <MenuItem value={8}>Agosto</MenuItem>
                                        <MenuItem value={9}>Setembro</MenuItem>
                                        <MenuItem value={10}>Outubro</MenuItem>
                                        <MenuItem value={11}>Novembro</MenuItem>
                                        <MenuItem value={12}>Dezembro</MenuItem>
                                    </TextField>
                                </Column>
                                <Column>
                                    <TextField
                                        variant='outlined'
                                        select
                                        label='Ano'
                                        color='secondary'
                                        disabled
                                        required
                                        style={{
                                            width: '10rem',
                                            marginTop: '6px',
                                            marginLeft: '6px',
                                            marginRight: '6px',
                                            marginBottom: '6px',
                                        }}
                                        onChange={(e) => setYear(e.target.value)}
                                        value={year}
                                    >
                                        <MenuItem value={24}>2024</MenuItem>
                                        <MenuItem value={23}>2023</MenuItem>
                                        <MenuItem value={22}>2022</MenuItem>
                                    </TextField>
                                </Column>
                            </Row>
                        </Container>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Column>
                            <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'column' }}>
                                <label>Appointments Recruitments **</label>
                                <TextField
                                    placeholder='Total Appointments Recruitments **'
                                    variant="outlined"
                                    color="secondary"
                                    required
                                    fullWidth
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setTotalAppointments(e.target.value)}
                                    value={totalAppointments}
                                />
                            </div>

                            <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'column' }}>
                                <label>Appointments R2 **</label>
                                <TextField
                                    placeholder='Total Appointments R2 **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setTotalAppointmentsR2(e.target.value)}
                                    value={totalAppointmentsR2}
                                />
                            </div>

                            <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'column' }}>
                                <label>Listings cadastrados ATIVOS - (Unids)**</label>
                                <TextField
                                    placeholder='Listings cadastrados ATIVOS - (Unids) **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setListingsAtivosUnids(e.target.value)}
                                    value={listingsAtivosUnids}
                                />
                            </div>

                            <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'column' }}>
                                <label>Gross Agents (Num. de Agentes Bruto)**</label>
                                <TextField
                                    placeholder='Gross Agents (Num. de Agentes Bruto) **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setGrossAgentsBruto(e.target.value)}
                                    value={grossAgentsBruto}
                                />
                            </div>

                            <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'column' }}>
                                <label>Gross Agents (c/ Produção nos Últimos 12M) **</label>
                                <TextField
                                    placeholder='Gross Agents (c/ Produção nos Últimos 12M) **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setGrossAgentsProducao(e.target.value)}
                                    value={grossAgentsProducao}
                                />
                            </div>
                        </Column>
                        <Column>
                            <div style={{ marginRight: '6px' }}>
                                <label>Listings cadastrados ATIVOS - (VGV R$)**</label>
                                <TextField
                                    placeholder='Listings cadastrados ATIVOS - (VGV R$) **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setListingsAtivosVgv(maskCurrency(e.target.value))}
                                    value={listingsAtivosVgv}
                                />
                            </div>

                            <div style={{ marginRight: '6px' }}>
                                <label> Despesas do Market Center (R$)**</label>
                                <TextField
                                    placeholder='Total de Despesas do Market Center (R$) **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setTotalDespesasMc(maskCurrency(e.target.value))}
                                    value={totalDespesasMc}
                                />
                            </div>

                            <div style={{ marginRight: '6px' }}>
                                <label>Company Currency (R$)**</label>
                                <TextField
                                    placeholder='Total Company Currency (R$) **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setTotalCompanyCurrency(maskCurrency(e.target.value))}
                                    value={totalCompanyCurrency}
                                />
                            </div>

                            <div style={{ marginRight: '6px' }}>
                                <label>Other MC Income - Se houver (R$)</label>
                                <TextField
                                    placeholder='Other MC Income - Se houver (R$)'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setOtherMcIncome(maskCurrency(e.target.value))}
                                    value={otherMcIncome}
                                />
                            </div>
                            <div style={{ marginRight: '6px' }}>
                                <label>Total MC Revenue (R$)**</label>
                                <TextField
                                    placeholder='Total MC Revenue (R$) **'
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    required
                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                    onChange={(e) => setTotalMcRevenue(maskCurrency(e.target.value))}
                                    value={totalMcRevenue}
                                />
                            </div>
                        </Column>
                    </div>
                    <AddButton2 type="submit" onClick={handleUpdateTransmittal}>
                        Atualizar Transmittal
                    </AddButton2>
                </form>
            } />
        </>
    )

}

export default EditTransmittalPage;