import { useState } from "react";
import { Box, Divider, Grid, Switch } from "@mui/material";
import { CardVideo, CardsClasses, Column, DescriptionClasse, Row, TextClasse, TitleClasse, VideoCss } from "./style";
import { Link } from "react-router-dom";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import YouTubePlayer from "../../../pages/EAD/Lesson/YouTubePlayer";

interface ClassesProps {
    url: string;
    title: string;
    subtitle: string;
    description: string;
    urlPdf: string;
    data: string;
}

function isYoutubeUrl(url: string): boolean { 
    if( url.match(/youtube/i) ) {
        return true;
    }
    if( url.match(/youtu\.be/) ) {
        return true;
    }
    return false;
}

const CardClasses: React.FC<ClassesProps> = (props) => {
    const [viewPdf, setViewPdf] = useState(false);
    return (
        <>
            <Grid container >
                <Grid item
                    xs={5}
                    sm={6}
                    md={12}
                    lg={10}
                    xl={12}
                >
                    <Row>
                        <CardVideo>
                            { (props.url && !isYoutubeUrl(props.url)) && <VideoCss>
                                <video width="100%" controls
                                    style={{
                                        aspectRatio: '16/9',
                                        borderRadius: '8px'
                                    }}
                                    autoPlay={false}
                                    >
                                    <source src={props.url} type="video/mp4"/>
                                    <source src="movie.ogg" type="video/ogg"/>
                                </video>
                            </VideoCss>}
                            { (props.url && isYoutubeUrl(props.url)) &&
                            <YouTubePlayer 
                            width='90%'
                            height="90%"
                            url={props.url}/> }
                            <Column>
                                <Column>
                                    <Row>
                                        <TitleClasse>
                                            {/* Gestão de Usuários */}
                                            {props.title}
                                        </TitleClasse>
                                        {/* <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '12px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }} about="buttonMarkAsDone">
                                                <a>
                                                    <img src="https://images-front-soukw.s3.amazonaws.com/star.svg" />
                                                </a>
                                                <a>
                                                    <img src="https://images-front-soukw.s3.amazonaws.com/star.svg" />
                                                </a>
                                                <a>
                                                    <img src="https://images-front-soukw.s3.amazonaws.com/star.svg" />
                                                </a>
                                                <a>
                                                    <img src="https://images-front-soukw.s3.amazonaws.com/star.svg" />
                                                </a>
                                                <a>
                                                    <img src="https://images-front-soukw.s3.amazonaws.com/star.svg" />
                                                </a>
                                            </div>
                                            <div style={{ width: '4rem' }} />
                                            <div about="buttonMarkAsDone">
                                                <EditButton2>
                                                    Aula Concluída
                                                </EditButton2>
                                            </div>
                                        </div> */}
                                    </Row>
                                    <Row>
                                        <TextClasse>
                                            {/* 20 de setembro de 2022 */}
                                            {props.data}
                                        </TextClasse>
                                        {/* <Divider sx={{ width: '12px' }} orientation="vertical" variant="middle" flexItem />
                                        <p style={{ marginLeft: '18px' }}>
                                            31 min
                                        </p>
                                        <Divider sx={{ width: '12px' }} orientation="vertical" variant="middle" flexItem />
                                        <p style={{ marginLeft: '18px' }}>
                                            215 views
                                        </p> */}
                                    </Row>
                                </Column>
                                <Divider sx={{ height: '8px', marginBottom: '8px' }} orientation="horizontal" variant="middle" flexItem />
                                <Column>
                                    <DescriptionClasse>
                                        DESCRIÇÃO
                                    </DescriptionClasse>
                                    <TextClasse>
                                        {props.description ? props.description : ""}
                                    </TextClasse>
                                    {
                                        props.urlPdf?.length > 0 &&
                                        <div>
                                            <p>
                                                <a
                                                    href={props.urlPdf}
                                                    target="_blank"
                                                    style={{ marginLeft: '18px' }}
                                                >
                                                    Baixar Material Complementar
                                                </a>
                                            </p>
                                            <p>
                                            <Switch
                                                onChange={( ) => {
                                                    setViewPdf( (prev) => !prev)
                                                }}
                                                /> Ler material online?
                                            </p>
                                            { viewPdf &&
                                            <object 
                                                data={props.urlPdf} 
                                                type="application/pdf" width="100%" height={1000}>
                                                <p>Não foi possível renderizar. </p>
                                            </object>}
                                        </div>
                                    }

                                </Column>
                            </Column>
                        </CardVideo>
                    </Row>
                </Grid>
            </Grid>
        </>
    )
}

export default CardClasses;