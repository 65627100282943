import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Card } from "../../../shared/components/Cards/Cards";
import { Navbar, Sidebar } from '../../../shared/components';

const CoursePage = () => {
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px
    return (
        <>
            <Navbar />
            <Sidebar Children={
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                        <Grid container
                            xs={10}
                            md={10}
                            lg={6.5}
                            xl={5}
                            paddingTop={2}>
                            <Card
                                image="https://images-front-soukw.s3.amazonaws.com/monitor.png"
                                title="Instituto Monitor"
                                text="O Instituto Monitor é a escola pioneira no Brasil a desenvolver a Educação a Distância (EAD) como modalidade de estudo."
                                acess={'https://portal.institutomonitor.com.br/alunos/'}
                                term={'https://drive.google.com/file/d/1cWl4vzlLvmpx6iAu3Kqvy8vVIhuFAJVb/view?usp=sharing'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '28px',
                                    marginLeft: '10px'
                                }}
                            />
                        </Grid>

                        <div style={{
                            width: '2rem'
                        }}>
                        </div>
                    </Grid>
                </Box>
            } />
        </>
    )
}

export default CoursePage;