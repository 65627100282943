import React from 'react';
import { Drawer, Box, Divider } from '@mui/material';
//import ListingsOverview from './ListingsOverview';
import ListingsStatus from './ListingsStatus';
//import Notifications from './Notifications';
import SearchForm from './SearchForm';
import { FiltersInterface } from './interfaces';

interface SideDrawerProps {
  open: boolean;
  onClose: () => void;
  onSearch: (filters: any) => void;
  prevFilters: FiltersInterface | undefined;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ open, onClose, onSearch, prevFilters }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 300, padding: 2 }}>
        <ListingsStatus />
        <Divider sx={{ my: 2 }} />
        <SearchForm onSearch={onSearch} onClose={onClose} prevFilters={prevFilters}  />
      </Box>
    </Drawer>
  );
};

export default SideDrawer;

